<dx-drop-down-box [(value)]="publicBookmarksIds"
                  valueExpr="id"
                  displayExpr="name"
                  placeholder=""
                  [hoverStateEnabled]="false"
                  [showClearButton]="false"
                  [dataSource]="publicBookmarks"
                  >
  <div *dxTemplate="let contentData of 'content'">
    <dx-list  keyExpr="id"
              [dataSource]="publicBookmarks"                           
              [itemDeleteMode]="toggle"
              selectionMode="single"
              (onSelectionChanged)="onSelectionChanged($event)"
              [(selectedItemKeys)]="publicBookmarksIds">
      <div *dxTemplate="let data of 'item'">
        {{data.name}}
      </div>
    </dx-list>
  </div>
</dx-drop-down-box>

<div id="searchId"></div>

<div class="search">
  <dx-text-box [(value)]="searchText"
               [showClearButton]="true"
               width="100%"
               name="search"
               class="text-box-primary text-box-search"
               placeholder="Пошук"
               valueChangeEvent="keyup"
               (onValueChanged)="onTextChanged($event)"
               (onFocusIn)="onFocusTextBox($event)"
               (onFocusOut)="onFocusOutTextBox($event)">
  </dx-text-box>
  <dx-button id="btnLayers" 
             icon="bulletlist"
             class="btn-layers"
             [type]="showSearchLayer ? 'default' : 'normal'"
             (onClick)="showLayerMenu(!showSearchLayer)"
             [hint]="showSearchLayer ? 'Сховати шари' : 'Вибрати шари'">
  </dx-button>
  <dx-button id="btnSearch" 
             icon="search"
             class="btn-search"
             [type]="showSearchResult ? 'default' : 'normal'"
             (onClick)="(showSearchResult = !showSearchResult) && (showSearchLayer = false)"
             [hint]="showSearchResult ? 'Сховати результат пошуку' : 'Результат пошуку'">
  </dx-button>
</div>

<div *ngIf="showSearchLayer" id="divSearchLayerID" class="search-content flex-col content-padding">
  <div [ngClass]="{'progress': isSearching}"></div>
  <div *ngFor="let data of arrSearchLayer" class="flex-row align-items-center">
    <dx-switch (onValueChanged)="switchValueChanged($event, data)"
                [value]="data.isSelected">
    </dx-switch>
    <p class="switch-label">{{data.name}}</p>
  </div>
</div>

  <div *ngIf="showSearchResult" class="search-result search-content flex-col content-padding">
    <dx-scroll-view direction="vertical">
      <p *ngIf="isNotFound" class="content-padding">
        Нема результатів пошуку  
      </p>
      <ul>
        <li *ngFor="let data of searchResult" [attr.data-sourceindex]="data.sourceIndex">
          <span>{{data.name}}</span>
          <ul>
            <li *ngFor="let item of data.results">
              <a (click)="selectSearchItem(item)">
                <span *ngIf="item.isSelected" class="result-active"></span>
                <span [innerHTML] ="item.text">
                  
                </span>
              </a>
            </li>
          </ul>
          <div class="search-result-buttons">
            <a *ngIf="data.results.length>5" (click)="setCountByIndex(data.sourceIndex, 5)" title="Показати 5">
              <span>5</span>
            </a>
            <a *ngIf="data.results.length>15" (click)="setCountByIndex(data.sourceIndex, 10)" title="Показати 10">
              <span>10</span>
            </a>
            <a *ngIf="data.results.length>25" (click)="setCountByIndex(data.sourceIndex, 20)" title="Показати 20">
              <span>20</span>
            </a>
            <a *ngIf="!showOnlyLayer && searchResult.length>1" (click)="showByLayer(data.sourceIndex)" title="«Перейти до шару»">
              <span>«Перейти до шару»</span>
            </a>
            <a *ngIf="showOnlyLayer" class="a-primary" (click)="toAllLayers()" title="Показати всі шари">
              <span>«Показати всі шари»</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 10)" title="Показати більше +10">
              <span>+10</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 20)" title="Показати більше +20">
              <span>+20</span>
            </a>
            <a *ngIf="data.results.length % 5 == 0 && data.maxSuggestions == data.results.length" (click)="showMoreByIndex(data.sourceIndex, 100)" title="Показати більше +100">
              <span>+100</span>
            </a>
          </div>
        </li>
      </ul>
    </dx-scroll-view>
    <div [ngClass]="{'progress': isSearching}"></div>
  </div>


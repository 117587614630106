import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class LoadingMapService {
  constructor(
    private esriService: EsriService,
    private sharedService: SharedService,
    private configService: ConfigService
  ) {
    


  }

  private maxID: number = 0;
  //private watchLoad: any[] = [];
  private _loading: boolean = false;
  private _updated: boolean = false;
  private ItemsWatch: ItemWatch[] = [];


  private _isLoading = new BehaviorSubject<boolean>(false);

  getIsLoading(): Observable<boolean> {
    return this._isLoading.asObservable()
  }

  setIsLoading(value: boolean) {
    this._isLoading.next(value);
  }

  AddToWatch(layerView: __esri.LayerView) {
    this.setIsLoading(true);
    let id = this.maxID++;
    
    var itemWatch: ItemWatch = new ItemWatch();
    itemWatch.layerId = id;
    layerView.set<number>("WatchID", id);
    this.ItemsWatch.push(itemWatch);
    
    var self = this;
    var _watchHandle = layerView.watch("rendering", function (newValue, oldValue, propertyName) {
     // console.log('rendering layer :' + newValue + '; _updated:' + itemWatch._updated + ';_loading:' + itemWatch._loading + ';IsLoading:' + itemWatch.IsLoading);
      if (newValue) {

        if (!itemWatch._updated) {

          itemWatch._loading = true;
          itemWatch.IsLoading = true;
          //self.setIsLoading(true);
          self.checkLoadStatus();
        }

      } else {
        if (!itemWatch._loading && itemWatch._updated) {
          itemWatch._updated = false;
          itemWatch.IsLoading = false;
          //self.setIsLoading(false);
          self.checkLoadStatus();
        }
      }

    });

    var _watchHandleUpd = layerView.watch("updating", function (newValue, oldValue, propertyName) {

      //if (!val) {  // wait for the layer view to finish updating     
      //console.log('updating layer :' + newValue + '; _updated:' + itemWatch._updated + ';_loading:' + itemWatch._loading + ';IsLoading:' + itemWatch.IsLoading);
      if (newValue) {
        itemWatch._loading = true;
        itemWatch.IsLoading = true;
        self.setIsLoading(true);
      } else {
        if (itemWatch._loading) {
          itemWatch._loading = false;
          itemWatch._updated = true;
          itemWatch.IsLoading = false;
          self.checkLoadStatus();
        }
      }
    });
    var _watchHandleError = layerView.layer.watch('loadError', function (value) {
      itemWatch._loading = false;
      itemWatch._updated = false;
      itemWatch.IsLoading = false;
      //self.setIsLoading(false);
      self.checkLoadStatus();
      console.log('error on load cadastre');
    });
    //self.watchLoad.push(_watchHandle);
    //self.watchLoad.push(_watchHandleUpd);
    layerView.layer.on("layerview-destroy", function (event) {
      // The LayerView for the layer that emitted this event
      let _item = self.ItemsWatch.find(x => x.layerId == event.layerView.get<number>("WatchID"));
      if (_item) {
        //console.log('destroyed. item:' + event.layerView.WatchID);
       // _item.renderHandler.remove();
        _item.updateHandler.remove();
        _item.errorHandler.remove();
        self.ItemsWatch.splice(self.ItemsWatch.indexOf(_item), 1);
        self.checkLoadStatus();
      } else {
       // console.log('destroyed. not found item:' + event.layerView.WatchID);
      }
      
      //let _layerView = event.layerView.WatchID;
    });
    itemWatch.renderHandler = _watchHandle;
    itemWatch.updateHandler = _watchHandleUpd;
    itemWatch.errorHandler = _watchHandleError;

  }

  InitLoad(layerView: __esri.LayerView) {
    let item = this.ItemsWatch.find(x => x.layerId == layerView.get<number>("WatchID"));
    if (item) {
      item.IsLoading = true;
      item._loading = true;
    }
  }

  startLoad() {
    this.setIsLoading(true);
  }

  stopLoad(layerId) {
    let item = this.ItemsWatch.find(x => x.layerId == layerId);
    if (item) {
     // console.log("stop load");
      item._loading = false;
      item._updated = false;
      item.IsLoading = false;
      this.checkLoadStatus();
    }
  }

  checkLoadStatus() {
    //console.log('Watch items count: ' + this.ItemsWatch.length);
    if (this.ItemsWatch.find(x => x.IsLoading)) {
      this.setIsLoading(true);
    } else {
      this.setIsLoading(false);
    }
  }
}

export class ItemWatch {

  constructor() {
    this.IsLoading = true;
    this._loading = true;
  }
  layerView: any;
  layerId: any;
  renderHandler: any;
  updateHandler: any;
  errorHandler: any;
  _loading: boolean;
  _updated: boolean;
  IsLoading: boolean;
}

import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { MapLayersEndpoint } from '../map/endpoint-map.layers.service'
import { Layer, SelectChildrenType } from "../../models/layers/layer.model";
import { EsriService } from "../esri/js-esri.service";
import { LayerDataAttribute } from "../../models/layers/layer-data-attribute.models";
import { LoadingMapService } from "../loading/loading.service";
import { BookmarksService } from '../bookmarks/bookmarks.service';
import { FilterAttributeService } from '../filterattribute/filter-attribute.service';
import { isFlashType, LegendInfo, LegendItem } from '../../models/layers/legend.model';
import { FlashLegendsService } from '../legends/flash-legends.service';

@Injectable()
export class MapLayersService {
  Color: any;
  string: any;
  constructor(public  esriService: EsriService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private mapLayersEndpoint: MapLayersEndpoint,
    private filterAttributeService: FilterAttributeService,
    private loadingMapService: LoadingMapService,
    private bookmarksService: BookmarksService,
    private flashLegendsService: FlashLegendsService
  ) {
    
    
  }
  CurrentExtent: any;


  RemoveAllLayers() {
    let map: __esri.Map = this.sharedService.map;
    let layers = map?.layers.filter(x => x.id != 'customLayerID');
    
    if (layers?.length > 0) {
      map.removeMany(layers.toArray());
    }
    //this.sharedService.map.removeAll();

  }

  private getLayerChildrent(layer: Layer): Layer[] {
    let result = new Array<Layer>();
    layer.layerChildren.forEach(children => {
     
        result.push(children);
        let temp = this.getLayerChildrent(children);
        if (temp.length > 0) {
          result = result.concat(temp);
        }
            
    })
    return result;
  }

 getLayerSelectedChildrent(layer: Layer): Layer[] {
    let result = new Array<Layer>();
    layer.layerChildren.forEach(children => {
      if (children.isSelected) {
        children.hasFlash = true;
        result.push(children);
        let temp = this.getLayerSelectedChildrent(children);
        if (temp.length > 0) {
          result = result.concat(temp);
        }
      }
    })
    return result;
  }

  private getLayerParent(layer: Layer, layerId: string): boolean {
    let result = false;
    
    for (var children of layer.layerChildren){
      if (children.id == layerId)
      {
        layer.isSelected = true;
        result = true;
        break;       
      } else {
        if (this.getLayerParent(children, layerId)) {
          layer.isSelected = true;
          result = true;
          break;
        }
      }      
      
    }
    return result;
  }
 prepareLayerList(): Layer[] {
    let layerList = this.sharedService.getLayerList().getValue();    

    let result: Layer[] = new Array<Layer>();
       
    layerList.forEach(layer => {
      result.push(layer);
      let childrent = this.getLayerChildrent(layer);
      if (childrent.length > 0) {
        result= result.concat(childrent);
      }
    });
    return result;
  }

 parentLayerID(layerId: string): string {
    let layerList = this.sharedService.getLayerList().getValue();
    let result: string = layerId;

    for (var layer of layerList){
      if (layer.id == layerId)
      {
        result = layer.id;
        break;
      }
      
      if (this.getLayerParent(layer, layerId))
      {
        result = layer.id;
        //this.sharedService.clearLayers();
        //let tmp: Layer[] = new Array<Layer>();
        //Object.assign(tmp, layerList);
        //this.sharedService.setLayers(tmp);
        this.sharedService.setLayers(layerList);
        break;        
      }
    };
  
      
    
    return result;
  }

  private CollapseChildren(layer: Layer) {
    layer.isExpanded = false;
    layer.layerChildren.forEach(_layer => {
      _layer.isExpanded = false;
      this.CollapseChildren(_layer);
    });        
  }

  isChildrenSelected(layer: Layer): boolean {
    let returnResult: boolean = false;
    let result = layer.isSelected;
    if (result) {
      returnResult = true;
    }

    for (var item of layer.layerChildren) {
      if (item.isSelected) {
        result = this.isChildrenSelected(item);
        if (!result) {
          returnResult = false;
        }
      } else {
        returnResult = false;
      }

    }
    if (returnResult) {
      layer.selectChildrenType = SelectChildrenType.all;
    } else {
      layer.selectChildrenType = SelectChildrenType.none;
    }

    return returnResult;
  }
  //  public GetLegends(layer: __esri.Layer) {
  //  let options: __esri.RequestOptions =
  //    {
  //      responseType: 'json'
  //    };
  //  let url = layer.get<any>("url") + "/legend?f=pjson&token=" + this.configService.getGisToken();
  //  let jLegend: any;
  //  var self = this;
  //  return this.esriService.Request(url, options).then(function (result) {
  //    jLegend = result.data;  //JSON.parse(result.data);
  //    layer.set<any>("legendInfo", jLegend);
  //    return layer;
  //  }).catch(function (error) {      
  //    console.log("informative error message: ", error.message);
  //  });
  //}  

  //public GetDynamicLegends_(layerUrl: string, layers: any[]) {    
  //  let options: __esri.RequestOptions =
  //  {
  //    responseType: 'json'
  //  };
    
  //  let url = layerUrl + "/legend?dynamicLayers=" + encodeURIComponent("[" + layers.toString() + "]")+  "&f=pjson&token=" + this.configService.getGisToken();
  //  let jLegend: any;
  //  var self = this;

  //  return this.esriService.Request(url, options).then(function (result) {
  //    jLegend = result.data;  //JSON.parse(result.data);      
  //    return jLegend;
  //  }).catch(function (error) {
  //    console.log("informative error message: ", error.message);
  //  });
  //}


  //async getPopupAttachedFiles(feature, azureStorageService) {
  //  let result = "<ul>";
  //  let graphic = feature.graphic;// as __esri.Graphic;
  //  let containerName = (graphic.sourceLayer as __esri.FeatureLayer).get<string>("LayerDataGUID") + "-" + graphic.attributes["ObjecID"];
  //  let blobs = await azureStorageService.listBlobsByContainer(containerName);
  //  blobs.forEach(b => {
  //    result += `<li><span class=esri-popup__text-secondary>${b}</span> : <span class="esri-popup__text-primary">Name</span></li>`;
  //  })
  //  result += "</ul>";
  //  //let ttt = self;
  //  //let textElement = new this.esriService.TextContent();
  //  //textElement.text = result;
  //  return result;
  //}

    HideLayerDataByLayerIDEx(layerId: string) {
      let iDs: string[] = new Array<string>();
      iDs.push(layerId);
      let layerList = this.prepareLayerList();
      let layer = layerList.find(x => x.id == layerId);
      let layerChildren = this.getLayerChildrent(layer);
      layerChildren.forEach(n => {
        n.isShow = false;
        iDs.push(n.id);
      });
      this.sharedService.setLegends(null);
      if (layer) {
        layer.isShow = false;
        (this.sharedService.map as __esri.Map).layers.filter(f => !f.get<boolean>("isFlashing")).forEach(x => {
          if (x.type == "map-image" && (x as __esri.MapImageLayer).sublayers) {
            (x as __esri.MapImageLayer).sublayers.filter(l => iDs.includes(l.get<string>("LayerGuid"))).forEach(y => {
              //y.visible = false;
              (x as __esri.MapImageLayer).sublayers.remove(y);


              if ((y as __esri.Sublayer).get<any>("IsFlash") && (y as __esri.Sublayer).get<any>("IsFlash") != isFlashType.none) {

                //if (subType) {
                //  FlashLayerID = FlashLayerID + subType;
                //}

                //this.legendsService.removeFlashLayer(y.id, (y as __esri.Sublayer).get<string>("LayerDataGUID"), )
                

                let flashWatch = this.sharedService.WatchFlashList.find(x => x.flashId == (y as __esri.Sublayer).get<string>("LayerDataGUID"));
                
                if (flashWatch) {
                  let watchHandle = flashWatch?.handle;
                  watchHandle?.remove();
                  let index = this.sharedService.WatchFlashList.indexOf(flashWatch);

                  this.sharedService.WatchFlashList.splice(index, 1);
                }                

                let flashTypes = y.get<any[]>("flashTypes");
                if (flashTypes) {
                  flashTypes.forEach(f => {
                    let flashLayerId = y.get<string>("LayerDataGUID") + f.subTypeID;
                    let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == flashLayerId);
                    (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                    this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                    let legend = this.sharedService.publicLegends.find(x => x.layerGuid == y.get<string>("LayerDataGUID") && x.id == (y as __esri.Sublayer).id.toString() && x.defaultValues.find(v => v == f.subTypeID))
                    //: this.legends.find(x => x.layerGuid == layerGuid && x.id == id);
                    if (legend) {
                      legend.isFlash = "none";
                    }
                  })
                } else {
                  let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == (y as __esri.Sublayer).get<string>("LayerDataGUID"));
                  (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                  this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                  let legend = this.sharedService.publicLegends.find(x => x.layerGuid == y.get<string>("LayerDataGUID") && x.id == (y as __esri.Sublayer).id.toString())
                  if (legend) {
                    legend.isFlash = "none";
                  }
                }


              }
              let currentLegend: LegendItem[] = this.sharedService.publicLegends.filter(l => l.layerGuid == y.get<string>("LayerDataGUID"));
              currentLegend.forEach(l => {
                l.layerID = null;
              })
            });
            if ((x as __esri.MapImageLayer).sublayers.length == 0) {
              (this.sharedService.map as __esri.Map).layers.remove(x);
            }
          } else if (x.type == "feature") {
            if (iDs.includes(x.get<string>("LayerGuid"))) {
              (this.sharedService.map as __esri.Map).layers.remove(x);

              if (x.get<any>("IsFlash") && x.get<any>("IsFlash") != isFlashType.none) {
                let flashWatch = this.sharedService.WatchFlashList.find(f => f.flashId == x.get<string>("LayerDataGUID"));
                

                if (flashWatch) {
                  let watchHandle = flashWatch?.handle;
                  watchHandle?.remove();
                  let index = this.sharedService.WatchFlashList.indexOf(flashWatch);

                  this.sharedService.WatchFlashList.splice(index, 1);
                } 

                let flashTypes = x.get<any[]>("flashTypes");
                if (flashTypes) {
                  flashTypes.forEach(f => {
                    let flashLayerId = x.get<string>("LayerDataGUID") + f.subTypeID;
                    let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == flashLayerId);
                    (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                    this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                    let legend = this.sharedService.publicLegends.find(l => l.layerGuid == x.get<string>("LayerDataGUID") && l.defaultValues.find(v => v == f.subTypeID) == f.subTypeID)
                    if (legend) {
                      legend.isFlash = "none";
                    }

                  })
                } else {
                  let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == x.get<string>("LayerDataGUID"));
                  (this.sharedService.map as __esri.Map).layers.remove(flashLayer);
                  this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer?.id);
                  let legend = this.sharedService.publicLegends.find(l => l.layerGuid == x.get<string>("LayerDataGUID") && l.layerID == (x as __esri.FeatureLayer).id.toString())
                  if (legend) {
                    legend.isFlash = "none";
                  }
                }

              }

            }            
          }          
        })
        
      }
    }
      

  //public getAttributes(attributes: LayerDataAttribute[]): any[]  {

  //  let result = [];
  //  attributes.forEach(x => {
  //    result.push(x.name);
  //  })
  //  return result;
  //}
  
  selectChildrenLayers(layer) {
    let layerList = this.sharedService.getLayerList().getValue();
    let currentLayer: Layer;
    for (var children of layerList) {
      if (children.id == layer.id) {
        currentLayer = children;
        break;
      } else {
        let item = this.getLayerIfParent(children, layer.id);
        if (item) {
          currentLayer = item;
          break;
        }
      }
    };

    if (currentLayer && !!!currentLayer.isEmpty) {
      currentLayer.isSelected = true;

      let children = this.getLayerChildrent(currentLayer);
      children.filter(f => !!!f.isEmpty).forEach(c => {
        c.isSelected = true;
        c.selectChildrenType = SelectChildrenType.all;
      })
    }


    this.sharedService.setLayers([]);
    this.sharedService.setLayers(layerList);
    //this.showMapServerEx(layer.id);

  }

  unselectChildrenLayers(layer) {
    let layerList = this.sharedService.getLayerList().getValue();
    let currentLayer: Layer = layer;
    //for (var children of layerList) {
    //  if (children.id == layer.id) {
    //    currentLayer = children;
    //    break;
    //  } else {
    //    let item = this.getLayerParent(children, layer.id);
    //    if (item) {
    //      currentLayer = item;
    //      break;
    //    }
    //  }
    //};

    if (currentLayer) {
      currentLayer.isSelected = false;
      currentLayer.selectChildrenType = SelectChildrenType.none;
      let children = this.getLayerChildrent(currentLayer);
      children.forEach(c => {
        c.selectChildrenType = SelectChildrenType.none;
        c.isSelected = false;
      })
    }

    layerList.forEach(item => {
      if (this.isChildrenSelected(item)) {
        item.selectChildrenType = SelectChildrenType.all;
      }
      //this.sharedService.setLayers(layerList);
    })

    this.sharedService.setLayers([]);
    this.sharedService.setLayers(layerList);
    this.HideLayerDataByLayerIDEx(layer.id);

  }

  private getLayerIfParent(layer: Layer, layerId: string): Layer {
    let result: Layer;
    for (var children of layer.layerChildren) {
      if (children.id == layerId) {
        children.isSelected = true;
        result = children;
        break;
      } else {
        let item = this.getLayerIfParent(children, layerId);
        if (item) {
          item.isSelected = true;
          result = item;
          break;
        }
      }
    }
    return result;
  }

  getViewExtent() {

  }
}




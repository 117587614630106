
export class Layer {
  constructor(
    id?: string,
    parentId?: string,
    regionID?: string,
    name?: string,
    type?: string,
    status?: string,
    isEdited?: boolean,
    isDeletedData?: boolean,
    inMenu?: boolean,
    hasItems?: boolean,
    isSelected?: boolean,
    isShow?: boolean,
    isExpanded?: boolean,    
    layerChildren?: Layer[]) {

    this.id = id;
    this.parentId = parentId;
    this.regionID = regionID;
    this.name = name;
    this.type = type;
    this.status = status;
    this.isEdited = isEdited;
    this.isDeletedData = isDeletedData;
    this.inMenu = inMenu;
    this.hasItems = hasItems;
    this.isSelected = isSelected;
    this.isShow = isShow;
    this.isExpanded = isExpanded;     
    this.layerChildren = layerChildren;
  }

  public id: string;
  public parentId: string;
  public regionID: string;
  public name: string;
  public type: string;
  public status: string;
  public isEdited: boolean;
  public isDeletedData: boolean;
  public inMenu: boolean;
  public hasItems: boolean;
  public isSelected: boolean;
  public isShow: boolean;
  public isExpanded: boolean;
  public layerChildren: Layer[];
  public hasFlash: boolean;
  public isPinned: boolean;
  public isEmpty: boolean;
  public inBookmark: boolean;
  public inFilter: boolean;
  public selectChildrenType: SelectChildrenType = SelectChildrenType.none;
}

const SelectChildrenType = {
  none: 'none' as 'none',  
  all: 'all' as 'all',
  any: 'any' as 'any'
}
type SelectChildrenType = (typeof SelectChildrenType)[keyof typeof SelectChildrenType];
export { SelectChildrenType };

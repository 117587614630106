import { Component, OnInit } from '@angular/core';
import { LocatebtnService } from "../../services/locatebtn/locatebtn.service";

@Component({
  selector: 'app-locatebtn',
  templateUrl: './locatebtn.component.html',
  styleUrls: ['./locatebtn.component.scss']
})
export class LocatebtnComponent implements OnInit {

  constructor(private locatebtnService: LocatebtnService) { }

  ngOnInit() {
    this.locatebtnService.getLocateBtn();
  }

}

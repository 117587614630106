export const MapConfig = { 
    
  spatialReferenceWGS : {
      wkid: 102100, latestWkid: 3857
  },
  Ukraine_2000_GK_Zone_4SR: {
    wkid: 5562,
    /*latestWkid: 5562,*/
    wkt: 'PROJCS["Ukraine_2000_GK_Zone_4",GEOGCS["GCS_Ukraine_2000", DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]], PRIMEM["Greenwich", 0.0], UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",4500000.0], PARAMETER["False_Northing", 0.0], PARAMETER["Central_Meridian", 21.0], PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]',

  },
  Ukraine_2000_GK_Zone_5SR: {
    wkid: 5563,
    //latestWkid: 5563
    wkt: 'PROJCS["Ukraine_2000_GK_Zone_5",GEOGCS["GCS_Ukraine_2000", DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]], PRIMEM["Greenwich", 0.0], UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",5500000.0], PARAMETER["False_Northing", 0.0], PARAMETER["Central_Meridian", 27.0], PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]',

  },
  Ukraine_2000_GK_Zone_6SR: {
    wkid: 5564,
    //latestWkid: 5564
    wkt: 'PROJCS["Ukraine_2000_GK_Zone_6",GEOGCS["GCS_Ukraine_2000", DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]], PRIMEM["Greenwich", 0.0], UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",6500000.0], PARAMETER["False_Northing", 0.0], PARAMETER["Central_Meridian", 33.0], PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]',

  },
  Ukraine_2000_GK_Zone_7SR: {
    wkid: 5565,
    //latestWkid: 5565
    wkt: 'PROJCS["Ukraine_2000_GK_Zone_7",GEOGCS["GCS_Ukraine_2000", DATUM["D_Ukraine_2000",SPHEROID["Krasovsky_1940",6378245.0,298.3]], PRIMEM["Greenwich", 0.0], UNIT["Degree",0.0174532925199433]],PROJECTION["Gauss_Kruger"],PARAMETER["False_Easting",7500000.0], PARAMETER["False_Northing", 0.0], PARAMETER["Central_Meridian", 39.0], PARAMETER["Scale_Factor",1.0],PARAMETER["Latitude_Of_Origin",0.0],UNIT["Meter",1.0]]',

  },
  popupActions : [
      {
          id: "edit-geodata",
          className: "esri-icon-edit",
          title: "Редагувати"
      },
      {
          id: "view-geodata",
          className: "esri-icon-description",
          title: "Інфо"
      },
      {
          id: "has-files",
          className: "esri-icon-attachment",
          title: "Переглянути файли"
      },
      {
          id: "paint-geodata",
          className: "esri-icon-pan",
          title: "Змінити"
      },
      {
        id: "copy-geodata",
        className: "esri-icon-duplicate",
        title: "Копіювати"
      },
      {
          id: "delete-geodata",
          className: "esri-icon-trash",
          title: "Вилучити"
      }
  ],
  drawPointSymbol: {
    type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    style: "circle",
    color: [20, 146, 220, 1],
    size: "7px",
    outline: { // autocasts as new SimpleLineSymbol()
      color: [20, 146, 220, 1],
      width: 2
    }
  },
  drawPolylineSymbol: {
    type: "simple-line", // autocasts as new SimpleLineSymbol()
    color: [20, 146, 220],
    width: "2",
    cap: "round",
    join: "round"
  },
  drawPolygonSymbol: {
    type: "simple-fill", // autocasts as new SimpleFillSymbol()
    color: [20, 146, 220, 0.26],
    style: "solid",
    outline: {
      color: [20, 146, 220],
      width: 2
    }
  },
  popupPointSymbol: {
    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
    style: "circle",
    color: [255, 0, 0, 0.5],
    size: "10px",  // pixels
    outline: {  // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0, 1],
      style: "solid",
      width: 0.5  // points
    }
  },
  popupMultiPointSymbol: {
    type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
    style: "circle",
    color: [255, 0, 0, 0.5],
    size: "10px",  // pixels
    outline: {  // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0, 1],
      style: "solid",
      width: 0.5  // points
    }
  },
  popupPolylineSymbol: {
    type: "simple-line",  // autocasts as new SimpleLineSymbol()
    color: [255, 0, 0, 0.5],
    width: "3px",
    style: "solid"
  },
  popupPolygonSymbol: {
    type: "simple-fill",  // autocasts as new SimpleFillSymbol()
    color: [255, 0, 0, 0.25],
    style: "solid",
    outline: {  // autocasts as new SimpleLineSymbol()
      color: [255, 0, 0],
      style: "solid",
      width: 2
    }
  }

}

export class CoordinateItem {
  index: any;
  X: number;
  Y: number;
}

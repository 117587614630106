import { Injectable } from "@angular/core";
import { MapLayersEndpoint } from "../map/endpoint-map.layers.service";
import { LayerDataWithAttr } from "../../models/layers/layer.data.model";
import { LayerServerService } from "../layers/layer-server.service";
import { ConfigService } from "../shared/utils/config.service";

@Injectable()
export class CopyGeodataService {
  constructor(private mapLayersEndpoint: MapLayersEndpoint, private layerServerService: LayerServerService,
    private configService: ConfigService) {

  }

  getLayers(regionID, geometryType) {
    let layers: LayerDataWithAttr[] = [];
    return this.mapLayersEndpoint.getLayerDataByRegionEndpoint(regionID).then(async result => {
      let resultLayers = <LayerDataWithAttr[]>result;
      for (const layer of resultLayers.filter(f => f.canAddObject)) {
        let layerUrl = this.configService._baseUrlRegionServices + `${layer.serviceName}` + `/` + `${layer.layerName}`;
        let layerInfo = await this.layerServerService.getLayerInfo(layerUrl);
        if (layerInfo?.geometryType && this.layerServerService.serverTypeToEsriType(layerInfo?.geometryType) == geometryType) {         
          layers.push(layer);
        }        
      }
      return layers;

    })
  }
}

<div class="document-view">
  <div class="panel-header">
    <span>{{documentName}}</span>
    <a (click)="goBack()" class="btn-icon">
      <i class="icon-close"></i>
    </a>
  </div>
  <ngx-extended-pdf-viewer [src]="currentDoc"
                           [height]="'88vh'"
                           >
  </ngx-extended-pdf-viewer>
</div>

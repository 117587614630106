export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(
    id?: string,
    userName?: string,
    lastName?: string,
    firstName?: string,
    middleName?: string,
    email?: string,
    role?: string,
    isSuperAdmin?: boolean,
    isEdit?: boolean,
    isPublish?: boolean,
    isTool?: boolean
    ) {

    this.id = id;
    this.userName = userName;
    this.lastName = lastName;
    this.firstName = firstName;
    this.middleName = middleName;
    this.email = email;
    this.role = role;
    this.isSuperAdmin = isSuperAdmin;
    this.isEdit = isEdit;
    this.isPublish = isPublish;
    this.isTool = isTool;
  }

  get shortName(): string {
    let firstName = this.firstName && this.firstName.length > 0 ? this.firstName.charAt(0).toUpperCase() : '';
    let lastName = this.firstName && this.lastName.length > 0 ? this.lastName.charAt(0).toUpperCase()  : '';
    let name = firstName + lastName;
    if (name == '') {
      name = 'XX';
    }
    return name;
  }

  public id: string;
  public userName: string;
  public lastName: string;
  public firstName: string;
  public middleName: string;
  public email: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public role: string;
  public isSuperAdmin: boolean;
  public isEdit: boolean;
  public isPublish: boolean;
  public isTool: boolean;
}

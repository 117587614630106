<dx-popup
  title="Копіювання"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="false"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  visible="true">
  <div *dxTemplate="let data of 'content'" #copygeodataWidgetID class="flex-col">
    
    <div class="dx-text-field">
      <dx-select-box [dataSource]="layersDataSource" width="100%" height="48"
                      displayExpr="name"
                      valueExpr="id"
                      placeholder=""
                      [(value)]="currentLayer"
                      (onValueChanged)="onValueChangedLayer($event)"
                      noDataText="Немає даних">
        <dx-validator>
          <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
      <label>Копіювати в шар</label>
    </div>

    <div *ngIf="currentLayer" class="flex-col">
      
      <div *ngFor="let attr of currentAttributes | visibleFilter:true" class="dx-text-field">

        <dx-number-box *ngIf="!attr.filterType && attr.fieldType=='number'" [(value)]="attr.value" width="100%" height="48">
        </dx-number-box>

        <dx-text-box *ngIf="!attr.filterType && attr.fieldType=='string'" [(value)]="attr.value" width="100%" height="48">
        </dx-text-box>

        <dx-select-box *ngIf="attr.filterType" [dataSource]="attr.source" width="100%" height="48"
                        displayExpr="name"
                        valueExpr="value"
                        [(value)]="attr.value"
                        placeholder="Виберіть..."
                        noDataText="Немає даних">
          <dx-validator>
            <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
          </dx-validator>
        </dx-select-box>

        <label>{{ attr.alias}}</label>
    
      </div>

    </div>
     
  </div>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [disabled]="!currentLayer"
      [options]="saveButtonOptions">
  </dxi-toolbar-item>
</dx-popup>
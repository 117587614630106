
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EndpointFactory } from '../shared/endpoint-factory.service';
import { Observable } from 'rxjs';

@Injectable()
export class LocationsEndpoint extends EndpointFactory {

  private readonly _userRegionUrl: string = "/api/userregion/userregion";

  get userRegionUrl() { return this.configService.getApiURI() + this._userRegionUrl; }

  //get user regions
  getUserRegionsEndpoint(userId?: string): Observable<Response> {
   let endpointUrl = `${this.userRegionUrl}/${userId}`;
  
    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getUserRegionsEndpoint(userId));
      }),);
  }

}

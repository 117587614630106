
<dx-popup
  title="Редагування закладки"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="false"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  visible="true">
  <div class="flex-col">

    <dx-validation-group #validationFilterData>
      <div class="dx-text-field">
      <dx-text-box [(value)]="bookmarkEdit.name">
        <dx-validator>
          <dxi-validation-rule type="required" message="Назва закладки обов'язкова"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <label>Назва звкладки</label>
      </div>
    </dx-validation-group>

    <dx-html-editor [(value)]="bookmarkEdit.htmlText" height="420" placeholder="Опис">
      <dxo-toolbar [multiline]="true">
        <dxi-item name="undo"></dxi-item>
        <dxi-item name="redo"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="bold"></dxi-item>
        <dxi-item name="italic"></dxi-item>
        <dxi-item name="strike"></dxi-item>
        <dxi-item name="underline"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="alignLeft"></dxi-item>
        <dxi-item name="alignCenter"></dxi-item>
        <dxi-item name="alignRight"></dxi-item>
        <dxi-item name="alignJustify"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="orderedList"></dxi-item>
        <dxi-item name="bulletList"></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item
          name="header"
          [acceptedValues]="[false, 1, 2, 3, 4, 5]"
          [options]="{ inputAttr: { 'aria-label': 'Header' } }"
        ></dxi-item>
        <dxi-item name="separator"></dxi-item>
        <dxi-item name="color"></dxi-item>
        <dxi-item name="background"></dxi-item>
        <dxi-item name="separator"></dxi-item>
      </dxo-toolbar>
    </dx-html-editor>
        
    <section class="flex-col gutter">
      <hr>
      <p class="heading-xs">
        Документи
      </p>
      <app-document-upload (uploadEvent)="refreshFiles($event)" [allowedTypes]="['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/svg+xml', 'image/webp']" #documentUpload></app-document-upload>
      <ul class="flex-row wrap list">
        <li *ngFor="let doc of listDocuments" class="chip">
          <a (click)="downloadDoc(doc)" title="{{doc}}"> {{doc}}</a>
          <dx-button icon="trash" hint="Видалити" stylingMode="text" (click)="deleteDoc(doc)"></dx-button>
        </li>
      </ul>
    </section>
        
  </div>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="saveButtonOptions">
  </dxi-toolbar-item>
</dx-popup>

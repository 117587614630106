
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { UserLayer } from '../../models/userlayer/user-layer.model';
import { CustomLayerEndpoint } from "./custom.layer-endpoint.service";


@Injectable()
export class CustomLayerService {

  constructor(private sharedService: SharedService, private customLayerEndpoint: CustomLayerEndpoint) {
    
  }


  getCustomLayerData(regionId): Observable<UserLayer[]> {
    return this.customLayerEndpoint.getCustomLayerDataEndpoint(regionId).pipe(map((result: any) => <UserLayer[]>result));
  }

  getSaveLayerData(UserLayer): Observable<UserLayer> {

    return this.customLayerEndpoint.getSaveDataServiceEndpoint(UserLayer).pipe(
      map((result: any) =>
        <UserLayer>result
      ));
  }  

}





import { Injectable } from '@angular/core';
import { HttpResponse, HttpHeaders } from '@angular/common/http'; // URLSearchParams,
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';



@Injectable()
export class AzureStorageEndpointService extends EndpointFactory {

  private readonly _getSASTokenUrl: string = "/api/authorize/sastoken";


  get getSASTokenUrl() { return this.configService.getApiURI() + this._getSASTokenUrl; }


  getSASToken(): Promise<any> {
    let endpointUrl = `${this.getSASTokenUrl}`;
    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'text' as const
    }    
    let str = JSON.stringify("test");
    return this.http.post(endpointUrl, str, httpOptions)
      .toPromise().then((response: any) => {
        return response;
      }).catch(error => {
        return this.handlePromiseError(error, () => this.getSASToken())        
      });
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { SharedService } from "../../../services/shared/shared.service";
import { Location } from "@angular/common";
import { LayersService } from "../../../services/layers/layers.service";
import { Router, ActivatedRoute } from '@angular/router';
import { LayerDataWithAttr } from '../../../models/layers/layer.data.model';
import { LayerDataAttribute } from "../../../models/layers/layer-data-attribute.models";
import { UserService } from "../../../services/user/user.service";
import { BaseComponent } from "../../basecomponent/base.component";
import { MapLayersService } from '../../../services/map/map.layers.service';
import { AzureStorageService } from '../../../services/azurestorage/azure-storage.service';

@Component({
  selector: 'app-view-geodata',
  templateUrl: './view-geodata.component.html',
  styleUrls: ['./view-geodata.component.scss']
})
export class ViewGeodataComponent extends BaseComponent implements OnInit, OnDestroy {

  attributes: any[];
  layerAttribute: LayerDataAttribute[];
  featureAttr: any;
  list: any[];
  featureLayer: any;
  layerName: any;
  subscription: Subscription;
  popupVisible: boolean = false;
  closeEvent: any;
  routerEvent: any;
  backUrl: any;
  isEditable: boolean = false;
  private selectedFeature: any;// __esri.Graphic;
  listDocuments: any[];
  documentContainerName: string = "test";
  currentDoc: Blob;
  fileOnly: boolean = false;

  constructor(
    private sharedService: SharedService,
    private location: Location,
    private layersService: LayersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private mapLayersService: MapLayersService,
    private azureStorageService: AzureStorageService
  ) {
    super();
  }

  ngOnInit() {
    this.routerEvent = this.activatedRoute.queryParams.subscribe(params => {
      this.backUrl = params.backUrl || '/';
      this.fileOnly = params.viewFile || false;
    })
    //this.backUrl = this.activatedRoute.snapshot.params['backUrl'] || '/';
    //this.fileOnly = this.activatedRoute.snapshot.params['viewFile'];
    this.selectedFeature = this.sharedService.SelectedFeatureValue();
    if (this.selectedFeature) {
      this.popupVisible = true;
    } else {
      this.goBack();
     // return;
    }

    this.closeEvent = this.sharedService.getSelectedFeature().subscribe(result => {
      this.selectedFeature = result;
      this.setGeoObject();
      //if (this.fileOnly) {
      //  this.setFiles();
      //} else {
      //  this.setGeoObject();
      //}      
    })

    this.sharedService.setHiddenSearching(true);
  }
  setFiles() {
    if (this.selectedFeature.sourceLayer) {
      let layerDataID = (this.selectedFeature.sourceLayer as __esri.FeatureLayer).get<string>('LayerDataGUID');
      this.featureLayer = this.selectedFeature.sourceLayer;
      this.layerName = this.featureLayer.name;
      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        //this.documentContainerName = this.documentContainerName.replace("/", "");
        //this.documentUpload.containerName = this.documentContainerName;
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
        })
      });
    } else if (this.selectedFeature.layer && this.selectedFeature.layer.id == 'navigationLayerID') {
      let layerDataID = (this.selectedFeature.layer as __esri.FeatureLayer).get<string>('LayerDataGUID');
      this.featureLayer = this.selectedFeature.layer;
      this.layerName = this.featureLayer.name;
      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        //this.documentContainerName = this.documentContainerName.replace("/", "");
        //this.documentUpload.containerName = this.documentContainerName;
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
        })
      });
    }
  }

  setGeoObject() {
    this.isEditable = false;
    if (this.selectedFeature.sourceLayer) {
      let layerDataID = (this.selectedFeature.sourceLayer as __esri.FeatureLayer).get<string>('LayerDataGUID');
      this.featureLayer = this.selectedFeature.sourceLayer;
      this.layerName = this.featureLayer.name;
      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        //this.documentContainerName = this.documentContainerName.replace("/", "");
        //this.documentUpload.containerName = this.documentContainerName;
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
        })

        this.layerAttribute = result.layerAttributes;
        this.featureAttr = this.selectedFeature.attributes;
        let attributes: any[] = Object.keys(this.selectedFeature.attributes);
        this.attributes = new Array<any>();
        attributes.
          sort((a: any, b: any) => {
            let aIndex = this.layerAttribute.findIndex(y => y.name == a );
            let bIndex = this.layerAttribute.findIndex(y => y.name == b );
            let sortResult = aIndex < bIndex ? -1 : 1;
            return sortResult;
          }).
          forEach(x => {
            let item = this.layerAttribute.find(y => y.name == x && y.showed);
            if (item) {
              //let sublayer = (this.featureLayer as __esri.MapImageLayer).sublayers.getItemAt(0)
              let fields: [__esri.Field] = (this.featureLayer as __esri.Sublayer).get<any>("fields");
              let field = fields.find(f => f.name == x)
              let _value: any;
              if (field.domain) {
                let values: any[] = field.domain.get('codedValues');
                let domainValue = values.map(val => {
                  return { id: val.code, name: val.name };
                }).find(f => f.id == this.featureAttr[x]);
                _value = domainValue?.name;
              } else if (((this.selectedFeature.sourceLayer as __esri.FeatureLayer).get<any>("type") == "feature"
                && (this.selectedFeature.sourceLayer as __esri.FeatureLayer).typeIdField && x == (this.selectedFeature.sourceLayer as __esri.FeatureLayer).typeIdField)
                  || ((this.selectedFeature.sourceLayer as __esri.FeatureLayer).get<any>("subTypeField") == x)) {
                  let subtypes = this.layersService.getSubTypeInfo(this.selectedFeature.sourceLayer);
                  let typeValue = subtypes.find(t => t.id == this.featureAttr[x]);
                _value = typeValue?.name;
              } else {
                _value = this.featureAttr[x];
              }
              let attribute = { 'name': x, 'value': _value, 'editable': item.editable, 'alias': field.alias, 'isSubType': false, subTypes: [] };
              this.attributes.push(attribute);
            }
          });
        let editableItem = this.layerAttribute.find(x => x.editable);
        if (editableItem)
          this.isEditable = this.userService.User.isEdit;        
      })
    } else if (this.selectedFeature.layer && this.selectedFeature.layer.id == 'customLayerID') {

      this.featureAttr = this.selectedFeature.attributes;
      //let attributes: any[] = Object.keys(this.selectedFeature.attributes);
      this.attributes = new Array<any>();
      this.attributes.push({ 'name': 'name', 'value': this.featureAttr['Name'], 'editable': true, 'alias': 'Назва' });
      this.attributes.push({ 'name': 'description', 'value': this.featureAttr['Description'], 'editable': true, 'alias': 'Опис' });
      switch (this.featureAttr['Type']) {
        case 'point':
          this.attributes.push({ 'name': 'longitude', 'value': this.featureAttr['Longitude'], 'editable': false, 'alias': 'Довгота' });
          this.attributes.push({ 'name': 'latitude', 'value': this.featureAttr['Latitude'], 'editable': false, 'alias': 'Широта' });
          break;
        case 'polygon':
          this.attributes.push({ 'name': 'square', 'value': this.featureAttr['Square'], 'editable': false, 'alias': 'Площа' });
          this.attributes.push({ 'name': 'length', 'value': this.featureAttr['Length'], 'editable': false, 'alias': 'Довжина' });
          this.attributes.push({ 'name': 'longitude', 'value': this.featureAttr['Longitude'], 'editable': false, 'alias': 'Довгота' });
          this.attributes.push({ 'name': 'latitude', 'value': this.featureAttr['Latitude'], 'editable': false, 'alias': 'Широта' });
          break;
        case 'polyline':
          this.attributes.push({ 'name': 'length', 'value': this.featureAttr['Length'], 'editable': false, 'alias': 'Довжина' });
          break;
        default: break;
      }
      this.isEditable = this.userService.User.isEdit;
      //attributes.
      //  forEach(x => {
      //    //let item = this.layerAttribute.find(y => y.name == x && y.showed);
      //    //if (item) {
      //      //let sublayer = (this.featureLayer as __esri.MapImageLayer).sublayers.getItemAt(0)
      //      //let fields: [__esri.Field] = (this.featureLayer as __esri.Sublayer).get<any>("fields");
      //    //let field = fields.find(f => f.name == x)

      //    this.attributes.push({ 'name': x, 'value': this.featureAttr[x], 'editable': true, 'alias': 'field.alias' });
      //    //}
      //  });
    } else if (this.selectedFeature.layer && this.selectedFeature.layer.id == 'navigationLayerID') {
      let layerDataID = (this.selectedFeature.layer as __esri.FeatureLayer).get<string>('LayerDataGUID');
      this.featureLayer = this.selectedFeature.layer;
      this.layerName = this.featureLayer.name;
      this.layersService.getLayerData(layerDataID).subscribe((result) => {
        this.documentContainerName = result.id + '-' + this.selectedFeature.attributes["OBJECTID"];
        //this.documentContainerName = this.documentContainerName.replace("/", "");
        //this.documentUpload.containerName = this.documentContainerName;
        this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
          this.listDocuments = result;
        })

        this.layerAttribute = result.layerAttributes;
        this.featureAttr = this.selectedFeature.attributes;
        let attributes: any[] = Object.keys(this.selectedFeature.attributes);
        this.attributes = new Array<any>();
        attributes.
          sort((a: any, b: any) => {
            let aIndex = this.layerAttribute.findIndex(y => y.name == a );
            let bIndex = this.layerAttribute.findIndex(y => y.name == b );
            let sortResult = aIndex < bIndex ? -1 : 1;
            return sortResult;
          }).
          forEach(x => {
            let item = this.layerAttribute.find(y => y.name == x && y.showed);
            if (item) {
              //let sublayer = (this.featureLayer as __esri.MapImageLayer).sublayers.getItemAt(0)
              let fields: [__esri.Field] = (this.featureLayer as __esri.Sublayer).get<any>("fields");
              let field = fields.find(f => f.name == x);
              let _value: any;
              if (field.domain) {
                let values: any[] = field.domain.get('codedValues');
                let domainValue = values.map(val => {
                  return { id: val.code, name: val.name };
                }).find(f => f.id == this.featureAttr[x]);
                _value = domainValue?.name;
              } else if ((((this.featureLayer as __esri.FeatureLayer).get<any>("type") == "feature" || (this.featureLayer as __esri.FeatureLayer).get<any>("type") == "graphics" )
                && (this.featureLayer as __esri.FeatureLayer).typeIdField && x == (this.featureLayer as __esri.FeatureLayer).typeIdField)
                || ((this.featureLayer as __esri.FeatureLayer).get<any>("subTypeField") == x)) {
                  let subtypes = this.layersService.getSubTypeInfo(this.featureLayer);
                  let typeValue = subtypes.find(t => t.id == this.featureAttr[x]);
                _value = typeValue?.name;
              } else {
                _value = this.featureAttr[x];
              }
              let attribute = { 'name': x, 'value': _value, 'editable': item.editable, 'alias': field.alias, 'isSubType': false, subTypes: [] };
              this.attributes.push(attribute);
            }
          });
        let editableItem = this.layerAttribute.find(x => x.editable);
        if (editableItem) {
          this.isEditable = this.userService.User.isEdit;
        }
      })
    }
    
  }

  goBack() {
    let url = this.backUrl;

    this.router.navigateByUrl(url);
  }

  editGeodata() {
    this.router.navigate(['./', { outlets: { 'modalrouter': null } }], { skipLocationChange: true }).then(val => {
      this.router.navigate(['./', { outlets: { modalrouter: ['modal'] } }], { skipLocationChange: true });
    });
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.closeEvent.unsubscribe();
    this.routerEvent?.unsubscribe();
  }

  downloadDoc(doc) {
    let ext = doc.substring(doc.lastIndexOf('.') + 1);
    this.sharedService.currentDocumentContainer = this.documentContainerName;
    this.sharedService.currentDocumentName = doc;
    switch (ext.toLowerCase()) {
      case "pdf":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewdoc'] } }], { skipLocationChange: true });
        })        
        break;
      case "tif":
      case "tiff":
      case "png":
      case "jpg":
      case "jpeg":
        this.router.navigate(['./', { outlets: { viewrouter: null } }], { skipLocationChange: true }).then(val => {
          this.router.navigate(['./', { outlets: { viewrouter: ['viewimage'] } }], { skipLocationChange: true });
        })        
        break;
      default:
        this.azureStorageService.getBlob(this.documentContainerName, doc).then(() => {
          this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
            this.listDocuments = result;
          })
        });
        break;
    }
  }

}

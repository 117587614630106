import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../user/user.service";

import { SketchViewService } from "../customlayer/sketch-view.service";
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { SquareUnits } from '../../models/draw/square-units.model';
import { AzureStorageService } from '../azurestorage/azure-storage.service';
import { SearchService } from '../search/search.service';
import { CoordwidgetComponent } from '../../components/coordinates/coordinates.component';
import { LegendItem } from '../../models/layers/legend.model';
import { LoadingMapService } from '../loading/loading.service';
import { LegendQuerySevice } from '../legends/legend-query.service';
import { MapConfig } from '../../models/map/map-config';
import { CopygeodataComponent } from '../../components/copygeodata/copygeodata.component';


@Injectable()
export class MapService {
  constructor(      
    private esriService: EsriService,
    private sharedService: SharedService,        
    private configService: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private sketchViewService: SketchViewService,
    private azureStorageService: AzureStorageService,
    private searchService: SearchService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private loadingMapService: LoadingMapService,
    private legendQueryServce: LegendQuerySevice
  ) {
    
  }
  private _map: __esri.Map;

  private _firstCall = true;
  isEditing: boolean = false;
  viewContainerRef: ViewContainerRef
  copygeodataComponentRef: ComponentRef<CopygeodataComponent>;

  _createMap(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    var token = {
          'server': this.configService._baseUrlRegionServices,
          'token': this.configService.getGisToken()
        };

    this.esriService.EsriConfig.request.trustedServers.push(this.configService.CadastreMapCorsServer);
    this.esriService.EsriConfig.request.trustedServers.push(this.configService.ProxyCorsServer);
   // this.esriService.EsriConfig.request.corsEnabledServers.push(this.configService.CadastreMapCorsServer);    
   //this.esriService.EsriConfig.request.corsEnabledServers.push(this.configService.ProxyCorsServer);
    (this.esriService.EsriConfig as __esri.config).request.interceptors.push({
      // Use POST for all requests
      before: function (params) {
        if (params.url.includes("/Utilities/PrintingTools/GPServer/Export%20Web%20Map%20Task/submitJob")) {
          params.requestOptions.method = "post";
        }
      }
    });
    (this.esriService.EsriConfig as __esri.config).fontsUrl = "https://static.arcgis.com/fonts";
    let proxyRule: __esri.configRequestProxyRules = {
      urlPrefix: this.configService.CadastreMapCorsServer,
      proxyUrl: this.configService.ProxyPageUrl
   };

   (this.esriService.EsriConfig as __esri.config).request.proxyRules.push(proxyRule);
    (this.esriService.EsriConfig as __esri.config).request.maxUrlLength = 10000;
    this.esriService.IdentityManager.registerToken(token); 
    var self = this;
    this._map =
      new this.esriService.Map(
      {        
          basemap: "osm" //"topo-vector",          
        });

    var mapView: __esri.MapView = new this.esriService.MapView({
    //var mapView = new this.esriService.SceneView({
      container: Constants.mapNodeId,  
      map: this._map,               
      //center: [31.4900927, 48.5501735],
      center: [this.configService.UkraineCenterLongitude, this.configService.UkraineCenterLatitude],
      zoom: 7, 
      highlightOptions: {
        color: [255, 0, 0, 1], // [58, 255, 255, 0.26],
        //color: 'red',
        fillOpacity: 0.2
      }
      ,
      popup: {
        autoOpenEnabled: false,
        spinnerEnabled: true        
      }
    });
    

    this.sharedService.map = this._map;
    this.sharedService.mapView = mapView;
    this.sketchViewService.getSketchViewModel();
    var self = this;   

    mapView.when(function ()
    {
      let lods = mapView.constraints.effectiveLODs;
      let lod10000 = new self.esriService.LOD();
      lod10000.level = 15;
      lod10000.scale = 10000;
      lods.push(lod10000);

      let lod5000 = new self.esriService.LOD();
      lod5000.level = 18;
      lod5000.scale = 5000;
      lods.push(lod5000);

      let lod2000 = new self.esriService.LOD();
      lod2000.level = 19;
      lod2000.scale = 2000;      
      lods.push(lod2000);

      let lod1000 = new self.esriService.LOD();
      lod1000.level = 20;
      lod1000.scale = 1000;
      lods.push(lod1000);

      let lod500 = new self.esriService.LOD();
      lod500.level = 21;
      lod500.scale = 500;
      lods.push(lod500);

      let lod250 = new self.esriService.LOD();
      lod250.level = 22;
      lod250.scale = 250;
      lods.push(lod250);

      mapView.constraints.lods = lods;
      self.sharedService.polygonDraw = new self.esriService.Draw({
        view: mapView
      });
      
      //var zoom = new this.esriService.Zoom({
      //  view: mapView
      //});
      //mapView.ui.move(["zoom"], "bottom-right");
      mapView.popup.dockEnabled = true;
      mapView.popup.dockOptions.position = 'bottom-center';
      mapView.popup.highlightEnabled = false;
      mapView.popup.maxInlineActions = 10;
      ////////// sketchViewModel  ///////////
      self.sketchViewService.sketchViewModel = new self.esriService.SketchViewModel({
        view: mapView,
        updateOnGraphicClick: false,
        layer: self.sketchViewService.graphicsLayer,
        pointSymbol: MapConfig.drawPointSymbol,
        polylineSymbol: MapConfig.drawPolylineSymbol,
        polygonSymbol: MapConfig.drawPolygonSymbol
      });


      let popopCustomContent = '<ul class="esri-popup__list">' +
        `<li><span class=esri-popup__text-secondary>Назва</span> : <span class="esri-popup__text-primary">{Name}</span></li>` +
        `<li><span class=esri-popup__text-secondary>Опис</span> : <span class="esri-popup__text-primary">{Description}</span></li>` +
        `<li><span class=esri-popup__text-secondary>Площа  м</span><sup>2</sup> : <span class="esri-popup__text-primary">{Square}</span></li>` +
        `<li><span class=esri-popup__text-secondary>Довжина  м</span> : <span class="esri-popup__text-primary">{Length}</span></li>` +
        `<li><span class=esri-popup__text-secondary>Довгота</span> : <span class="esri-popup__text-primary">{Longitude}</span></li>` +
        `<li><span class=esri-popup__text-secondary>Широта</span> : <span class="esri-popup__text-primary">{Latitude}</span></li>` +
        '</ul>';
      //self.sketchViewService.sketchViewModel.on("create-complete", self.sketchViewService.addGraphic);
      self.sketchViewService.sketchViewModel.on("create", function (event) {

        if (event.state != "complete") {
          return;
          }
        if (!event.graphic) return;
        let objectId= self.sketchViewService.getNewID();
        let distance = self.esriService.GeometryEngine.geodesicLength(event.graphic.geometry, "meters");        
        let square = self.esriService.GeometryEngine.geodesicArea(event.graphic.geometry, SquareUnits.meter);
        let longitude = 0 ;
        let latitude = 0;

        switch (event.graphic.geometry.type) {
          case 'point':
            longitude = event.graphic.geometry.longitude;
            latitude = event.graphic.geometry.latitude;
            break;
          case 'polygon':
            longitude = event.graphic.geometry.centroid.longitude;
            latitude = event.graphic.geometry.centroid.latitude;
            break;
          default: break;
        }

        let attr = {
          OBJECTID: objectId,
          Name: "Об'єкт " + objectId,
          Description: "",
          Longitude: longitude,
          Latitude: latitude,
          Length: distance.toFixed(2),
          Square: square.toFixed(2),
          Type: event.graphic.geometry.type,
        };

          let popupTemplate= {
            title: "Власні об'єкти", content: popopCustomContent,
            actions: [{
              id: "edit-geodata",
              className: "esri-icon-edit",
              title: "Редагувати"
            },
            {
              id: "view-geodata",
              className: "esri-icon-description",
              title: "Інфо"
              },
              {
                id: "paint-geodata",
                className: "esri-icon-pan",
                title: "Змінити"
              },
              {
                id: "delete-geodata",
                className: "esri-icon-trash",
                title: "Вилучити"
              }
            ]
        }
        event.graphic.attributes = attr;
        //event.graphic.popupTemplate = popupTemplate;     TODO need fix 
      });

      // Listen the sketchViewModel's update-complete and update-cancel events
      self.sketchViewService.sketchViewModel.on("update", function (event) {
        if (event.state != "complete") {
          return;
        }
        let graphic = event.graphics[0];
        let distance = self.esriService.GeometryEngine.geodesicLength(graphic.geometry, "meters");
        let square = self.esriService.GeometryEngine.geodesicArea(graphic.geometry, SquareUnits.meter);
        let longitude = 0;
        let latitude = 0;

        switch (graphic.geometry.type) {
          case 'point':
            longitude = graphic.geometry.longitude;
            latitude = graphic.geometry.latitude;
            break;
          case 'polygon':
            longitude = graphic.geometry.centroid.longitude;
            latitude = graphic.geometry.centroid.latitude;
            break;
          default: break;
        }

        graphic.attributes.Longitude = longitude;
        graphic.attributes.Latitude = latitude;
        graphic.attributes.Length = distance.toFixed(2);
        graphic.attributes.Square = square.toFixed(2);
        graphic.attributes.Type = graphic.geometry.type;
        
        // set the editGraphic to null update is complete or cancelled.
        self.sketchViewService.editGraphic = null;
      });

    })


    
    mapView.on("click", function (event) {
      
     
      if (self.sharedService.IsShowedCadastre) {
        if (self.sharedService.mapView.popup.visible) {
          event.stopPropagation();
          self.sharedService.mapView.popup.close();
        } else {

          let graphicLayer = (self.sharedService.map as __esri.Map).layers.find(x => x.get<boolean>('cadastreGraphicLayer'));
          if (graphicLayer) {

            (graphicLayer as __esri.FeatureLayer).set<any>('cadastreMapPoint', event.mapPoint);

          }
        }

      } else {
        if (self.sharedService.mapView.popup.visible) {
          event.stopPropagation();
          self.sharedService.mapView.popup.close();
        }
        //else {
        if (!self.sharedService.polylineDrawingValue() && !self.sharedService.polygonDrawingValue()) {
          self.loadingMapService.startLoad();
          self.sharedService.mapView.popup.fetchFeatures(event.screenPoint).then(async function (response) {
            response.allGraphicsPromise.then(async function (graphics) {
              self.loadingMapService.checkLoadStatus();
              if (graphics.length > 0) {
                let features = await self.getGemotryFrom(graphics);
                features = self.OrderByGeometry(features);
                self.sharedService.mapView.popup.open({
                  features: features
                });
              }
            });
          }).catch(ex => {
            self.loadingMapService.checkLoadStatus();
          }
          );
        }        
        //}
      }
      ////////// sketchViewModel  ///////////
      if (self.sketchViewService.isEdit) {
        //event.stopPropagation();
        self.sharedService.mapView.popup.close();
        mapView.hitTest(event).then(function (response) {
          var results = response.results;
          if (results.length > 0) {
            event.stopPropagation();
            self.sharedService.mapView.popup.close();
          }
        });
      }

      
    });

   mapView.when(function () {
     let popup = mapView.popup;

     popup.watch("selectedFeature", async function (graphic) {
       if (graphic) {
         let features = popup.features;
         if (!(graphic as __esri.Graphic).get<boolean>("selectedFeature"))
           self.selectFeature(graphic);
         else {
           popup.features.splice(0, 1);
         }
         self.sharedService.setSelectedFeature(graphic);
         let _popupTemplate = graphic.getEffectivePopupTemplate();
         if (_popupTemplate.actions && _popupTemplate.actions.length > 0) {
           let graphicLayer = graphic.sourceLayer ?? graphic.layer;
           let editAction = _popupTemplate.actions.items.find(x => x.id == 'edit-geodata' || x.id == 'edit-feature');
           if (editAction) {
             editAction.visible = !self.sharedService.ShowBookmarkValue() && self.userService.User && self.userService.User.isEdit &&
               ((graphic.layer && graphic.layer.id == 'customLayerID') ||
                 ((graphicLayer as __esri.Layer)?.get<boolean>("editable") ? true : false) ||
                 ((graphicLayer as __esri.Layer)?.get<boolean>("canAddObject") ? true : false));
           }
           let viewAction = _popupTemplate.actions.items.find(x => x.id == 'view-geodata' || x.id == 'view-feature');
           if (viewAction) {
             viewAction.visible = !self.sharedService.ShowBookmarkValue() && self.userService.User &&
               ((graphic.layer && graphic.layer.id == 'customLayerID') || 
               ((graphicLayer as __esri.FeatureLayer).get<boolean>("showed") ? true : false));
           }

           let delAction = _popupTemplate.actions.items.find(x => x.id == 'delete-geodata' || x.id == 'delete-feature');
           if (delAction) {
             delAction.visible = !self.sharedService.ShowBookmarkValue() && self.userService.User && self.userService.User.isEdit
               && (((graphicLayer as __esri.Layer)?.get<boolean>("canAddObject") ? true : false)
                 || (graphic.layer && graphic.layer.id == 'customLayerID'));
           }

           let paintAction = _popupTemplate.actions.items.find(x => x.id == 'paint-geodata' || x.id == 'paint-feature');
           if (paintAction) {
             paintAction.visible = !self.sharedService.ShowBookmarkValue() && self.userService.User && self.userService.User.isEdit
               && (((graphicLayer as __esri.Layer)?.get<boolean>("canAddObject") ? true : false)
                 || (graphic.layer && graphic.layer.id == 'customLayerID'));
           }

           let hasFileAction = _popupTemplate.actions.items.find(x => x.id == 'has-files');
           if (hasFileAction) {
             let containerName = graphic.sourceLayer ?
               (graphic.sourceLayer as __esri.FeatureLayer).get<string>('LayerDataGUID') + '-' + graphic.attributes["OBJECTID"]
               : (graphic.layer as __esri.FeatureLayer).get<string>('LayerDataGUID') + '-' + graphic.attributes["OBJECTID"]
               //: (graphic.layer && graphic.layer.id == 'navigationLayerID' ? true : false)
             hasFileAction.visible = await self.azureStorageService.ExistAnyBlob(containerName);
           }

           let copyObjectAction = _popupTemplate.actions.items.find(x => x.id == 'copy-geodata' || x.id == 'copy-feature');
           if (copyObjectAction) {
             copyObjectAction.visible = !self.sharedService.ShowBookmarkValue() && self.userService.User && self.userService.User.isEdit
               && (((graphicLayer as __esri.Layer)?.get<boolean>("canAddObject") ? true : false)
                 || (graphic.layer && graphic.layer.id == 'customLayerID'));
           }
         }
       }
     });

     popup.watch("visible", function (value) {
       if (!value) {
         let existsFeature = self.sharedService.mapView.graphics.find(x => x.get<boolean>("selectedFeature"));
         if (existsFeature) {

           self.sharedService.mapView.graphics.remove(existsFeature);
           self.sharedService.mapView.popup.clear();
           if (existsFeature.get<any>("sourceLayer")) {
             let sourceLayer = existsFeature.get<any>("sourceLayer");
             if (sourceLayer) {
               (sourceLayer as __esri.Layer).get<any>("layerView")?.highlightHandler?.remove();
             }
           }
         }
       }
       if (self.isEditing) {
         self.sharedService.mapView.popup.close();
       }
     })

     popup.viewModel.on("trigger-action", function (event) {
       if (event.action.id === "edit-geodata" || event.action.id === "edit-feature") {
         self.sharedService.setShowPanel(true);
         self.sharedService.mapView.popup.close();
         self.router.navigate(['./', { outlets: { 'modalrouter': null } }], { skipLocationChange: true }).then(val => {
           self.router.navigate(['./', { outlets: { modalrouter: ['modal'] } }], { skipLocationChange: true });
         });

       } else if (event.action.id === "view-geodata" || event.action.id === "view-feature") {
         self.sharedService.setShowPanel(true);
         if (self.activatedRoute.snapshot.queryParams.backUrl) {
           self.router.navigate(['./attributes'], { queryParams: { backUrl: self.activatedRoute.snapshot.queryParams.backUrl }, skipLocationChange: false });
         } else {
           self.router.navigate(['./attributes'], { queryParams: { backUrl: self.router.routerState.snapshot.url }, skipLocationChange: false });
         }
       } else if (event.action.id === "has-files") {
         self.sharedService.setShowPanel(true);
         if (self.activatedRoute.snapshot.queryParams.backUrl) {
           self.router.navigate(['./attributes'], { queryParams: { backUrl: self.activatedRoute.snapshot.queryParams.backUrl, viewFile: true }, /*queryParamsHandling: 'merge',*/ skipLocationChange: false });
         } else {
           self.router.navigate(['./attributes'], { queryParams: { backUrl: self.router.routerState.snapshot.url, viewFile: true }, skipLocationChange: false });
         }

       }
       else if (event.action.id === "delete-geodata" || event.action.id === "delete-feature") {

         var confirmation = confirm("Ви впевнені, що хочете видалити об'єкт?", "Видалити об'єкт");
         confirmation.then(dialogResult => {
           if (dialogResult) {
             let existsFeature = self.sharedService.mapView.graphics.find(x => x.get<boolean>("selectedFeature"));
             if (existsFeature) {


               self.sharedService.mapView.graphics.remove(existsFeature);
             }
             let navigationLayerView = self.sharedService.mapView.layerViews.find(x => x.layer.id == 'navigationLayerID');//.layer;
             let resultFeature: __esri.Graphic = null;
             if (navigationLayerView) {
               let _graphics = (navigationLayerView.layer as __esri.GraphicsLayer).graphics;
               resultFeature = _graphics.find(g => g.attributes["OBJECTID"] == existsFeature.attributes['OBJECTID']);
             }

             if (resultFeature) {
               self.sharedService.mapView.graphics.remove(resultFeature);
             }

             if (existsFeature.get<any>("sourceLayer")) {
               let sourceLayer = existsFeature.get<any>("sourceLayer");

               let _url = sourceLayer.url.replace('MapServer', 'FeatureServer');
               if (sourceLayer.isSearchLayer || sourceLayer.type == 'feature') {
                 _url += `/${sourceLayer.layerId}`;
               }

               let tempLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
                 url: _url
               });
               tempLayer.applyEdits({
                 deleteFeatures: [existsFeature]
               }).then(val => {


                 let documentContainerName = (sourceLayer as __esri.Sublayer).get<string>('LayerDataGUID') + '-' + existsFeature.attributes['OBJECTID']
                 self.azureStorageService.deleteContainer(documentContainerName);

                 if (sourceLayer.isSearchLayer) {
                   self.searchService.OnDeleteItem();
                 }
                 let layerType: 'feature' | 'map-image';
                 if (sourceLayer.get("url").toUpperCase().includes("FEATURESERVER")) {
                   layerType = 'feature';
                 } else {
                   layerType = 'map-image';
                 }
                 let sourceLayerUrl = layerType == 'feature' ? sourceLayer.get("url") : sourceLayer.parent.get("url");
                 let _layerId = layerType == 'feature' ? sourceLayer.get("layerId") : sourceLayer.get("id");
                 self.refreshLayers(sourceLayerUrl, _layerId);

                 /////////////////////////////////////////////////////////
                 let layerDataGuid = (sourceLayer as __esri.Layer).get<string>('LayerDataGUID');
                 let _id = sourceLayer.type == 'feature' ? (sourceLayer as __esri.FeatureLayer).layerId : (sourceLayer as __esri.Sublayer).id.toString();
                 let _subTypeID = null;
                 if (tempLayer.typeIdField) {
                   let stID = existsFeature.attributes[tempLayer.typeIdField];
                   if (stID) {
                     _subTypeID = stID;
                   } else {

                   }
                 }

                 let legend: LegendItem;

                 if (_subTypeID) {
                   legend = self.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
                     x.id == _id &&
                     x.defaultValues.find(f => f == _subTypeID));
                 } else {
                   legend = self.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
                     x.id == _id);
                 }
                 if (legend) {
                   let subQuery = self.legendQueryServce.getSubQuery(legend);
                   self.getRecordCountByUrl(_url, subQuery).then(result => {
                     if (legend) {
                       legend.totalCount = result;
                     }
                   })
                 }

               }).catch(ex => {
                 console.log("error occured", ex);
                 notify(`Помилка при видаленні даних.\r\n  ${ex._body}`, "error", 3500);
               });


             } else if (resultFeature?.layer && resultFeature?.layer.get<any>("isNavigationLayer")) {

               let sourceLayer = resultFeature.layer; //existsFeature.get<any>("layer");

               let _url = sourceLayer.get<any>("subLayerUrl").replace('MapServer', 'FeatureServer');
               if (sourceLayer.get<any>("isNavigationLayer")) {
                 _url += `/${sourceLayer.get<any>("layerId")}`;
               }

               let tempLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
                 url: _url
               });
               tempLayer.applyEdits({
                 deleteFeatures: [existsFeature]
               }).then(val => {

                 let documentContainerName = sourceLayer.get<any>("LayerDataGUID") + '-' + resultFeature.attributes['OBJECTID']

                 self.azureStorageService.deleteContainer(documentContainerName);

                 self.refreshLayers(sourceLayer.get<any>("subLayerUrl"), sourceLayer.get<any>("layerId"));
                 self.sharedService.setRefreshNavigationList(true);
               }).catch(ex => {
                 notify(`Помилка при видаленні даних.\r\n  ${ex._body}`, "error", 3500);
               });
             }
             else {
               let _graphicLayer = self.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
               let delGraphic = (_graphicLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes['OBJECTID'] == existsFeature.attributes['OBJECTID']);
               if (delGraphic) {
                 (_graphicLayer as __esri.GraphicsLayer).graphics.remove(delGraphic);
                 self.sketchViewService.collectGraphics();
               }
             }

             self.sharedService.mapView.popup.close();
           }
         }, (error) => {
           console.log("error occured", error);
           notify(`Під час видалення сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
         });

       } else if (event.action.id === "paint-geodata" || event.action.id === "paint-feature") {

         let existsFeature = self.sharedService.mapView.graphics.find(x => x.get<boolean>("selectedFeature"));
         let navigationLayerView = self.sharedService.mapView.layerViews.find(x => x.layer.id == 'navigationLayerID');
         let resultFeature: __esri.Graphic = null;
         if (navigationLayerView) {
           let _graphics = (navigationLayerView.layer as __esri.GraphicsLayer).graphics;
           resultFeature = _graphics.find(g => g.attributes["OBJECTID"] == existsFeature.attributes['OBJECTID']);
         }
         if (existsFeature.get<any>("sourceLayer")) {
           self.EditObject(existsFeature);
         } else if (resultFeature?.layer && resultFeature.layer.get<boolean>("isNavigationLayer")) {
           self.EditNavObject(resultFeature);
         }

         else {
           let _graphicLayer = self.sharedService.mapView.layerViews.find(x => x.layer.id == 'customLayerID').layer;
           let editGraphic = (_graphicLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes['OBJECTID'] == existsFeature.attributes['OBJECTID']);
           self.sketchViewService.sketchViewModel.updateOnGraphicClick = true;
           self.sharedService.setIsEditCustomLayer(true);
           self.sharedService.mapView.popup.close();
           (self.sketchViewService.sketchViewModel as __esri.SketchViewModel).update([editGraphic], { tool: "reshape" }); // 
           notify(`Режим редагування увімкнено`, "success", 3000);
         }
       } else if (event.action.id === "upload-file") {

       } else if (event.action.id === "copy-geodata") {
         let existsFeature = self.sharedService.mapView.graphics.find(x => x.get<boolean>("selectedFeature"));
         self.sharedService.mapView.popup.close();
         self.showCopygeodata(existsFeature);
       }
      });
    });    
    
  }


  private async EditObject(graphic) {
    let graphicsLayer: __esri.GraphicsLayer = new this.esriService.GraphicsLayer({
      id: "tempLayerID"
    });
    this.sharedService.map.layers.add(graphicsLayer);   
    let editGraphic: __esri.Graphic = graphic.clone(); 
    let _url = (graphic as __esri.Graphic).get<any>("sourceLayer").url;
    this.sharedService.mapView.popup.close();
    graphicsLayer.add(editGraphic);
    let sketchViewModel: __esri.SketchViewModel = new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: false,
      layer: graphicsLayer,
      pointSymbol: MapConfig.drawPointSymbol,
      polylineSymbol: MapConfig.drawPolylineSymbol,
      polygonSymbol: MapConfig.drawPolygonSymbol
    });
    let coordWidget = this.InitCoordWidget();  

      let geometryType = editGraphic.geometry?.type ?? editGraphic.get<any>("sourceLayer")?.geometryType;
    switch (geometryType) {  // editGraphic.geometry.type
      case "point":
        editGraphic.symbol = sketchViewModel.pointSymbol;
        break;
      case "polyline":
        editGraphic.symbol = sketchViewModel.polylineSymbol;
        coordWidget.isPolyline = true;
        break;
      case "polygon":
        editGraphic.symbol = sketchViewModel.polygonSymbol;
        coordWidget.isPolygon = true;
        break;
      }
      
    if (!!!editGraphic.geometry) {
        let sourcelayer = editGraphic.get<any>("sourceLayer") as __esri.FeatureLayer;
        const queryParams = {
            outFields: "OBJECTID",
            returnGeometry: true,
            where : "OBJECTID = " + editGraphic.attributes["OBJECTID"],
            f: "json",
        }
        var queryUrl = `${sourcelayer.url}/${sourcelayer.layerId}/query`;
        let features: any[] =  await this.esriService.Request(queryUrl, {
            query: queryParams,
            responseType: "json"
        }).then((response) => {
            let results = response.data;
            console.log("return request feature", results);
            let feature = results.features?.length > 0 ? results.features : [];

            return feature;
        })
        if (features && features.length > 0) {
            let feature = features[0];
            feature.geometry.type = geometryType;
            feature.geometry = feature.geometry;
            feature.geometry = this.esriService.webMercatorUtils.webMercatorToGeographic(feature.geometry);
            editGraphic.geometry = feature.geometry;
            editGraphic.geometry = this.esriService.Projection.project(editGraphic.geometry, MapConfig.spatialReferenceWGS);
      }
    }
    
    sketchViewModel.update([editGraphic]);
    var self = this;
    sketchViewModel.on("update", function (event) {
      if (event.state != "complete") {
        if (coordWidget.isPolyline) {
          var distance = self.esriService.GeometryEngine.geodesicLength(event.graphics[0].geometry, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = self.esriService.GeometryEngine.simplify(event.graphics[0].geometry);
            if (simplifiedPolygon) {
              distance = self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }

          coordWidget.lengthLine = distance.toFixed(3);
        }

        if (coordWidget.isPolygon) {
          let path: any[] = (event.graphics[0].geometry as __esri.Polygon).rings[0];
          let allPath = Object.assign([], path);
          //allPath.splice((path.length - 1), 1);
          let _polyline = new self.esriService.Polyline({
            spatialReference: self.sharedService.mapView.spatialReference,
            hasZ: false,
            paths: allPath
          });

          var distance = self.esriService.GeometryEngine.geodesicLength(_polyline, "meters");
          if (distance < 0) {
            // simplify the polygon if needed and calculate the area again
            var simplifiedPolygon = self.esriService.GeometryEngine.simplify(_polyline);
            if (simplifiedPolygon) {
              distance = self.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
            }
          }
          coordWidget.lengthLine = distance.toFixed(3);

          var area = self.esriService.GeometryEngine.geodesicArea(event.graphics[0].geometry, SquareUnits.meter);
          var areaHa = self.esriService.GeometryEngine.geodesicArea(event.graphics[0].geometry, SquareUnits.hectares);
          if (area < 0 || areaHa < 0) {
            // simplify the polygon if needed and calculate the area again
            let simplifiedPolygon = self.esriService.GeometryEngine.simplify(event.graphics[0].geometry);
            if (simplifiedPolygon) {
              area = self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);
              areaHa = self.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.hectares);
            }
          }

          coordWidget.areaPolygon = area.toFixed(3);
        }
        return;
        }
       
      self.viewContainerRef.clear();
      _url = _url.replace('MapServer', 'FeatureServer');
      let sourceLayer = (graphic as __esri.Graphic).get<any>("sourceLayer");
      let isSearchLayer = (graphic as __esri.Graphic).get<any>("sourceLayer").isSearchLayer;
      if (sourceLayer.type == "feature" ||  isSearchLayer ) {
        _url += `/${sourceLayer.layerId}`;
      }
      let tempLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
        url: _url
      });
      tempLayer.applyEdits({
        updateFeatures: event.graphics
      }).then(val => {
          let _map = self.sharedService.map as __esri.Map;
          if (isSearchLayer) {
              
          let tmp = self.searchService.search.resultGraphic;
          tmp.geometry = event.graphics[0].geometry;
          }        
          let  layerType: 'feature' | 'map-image';
          if (sourceLayer.get("url").toUpperCase().includes("FEATURESERVER")) {
              layerType = 'feature';
          } else {
              layerType = 'map-image';
          }
          let sourceLayerUrl = layerType == 'feature' ? sourceLayer.get("url") : sourceLayer.parent.get("url");
          let _layerId = layerType == 'feature' ? sourceLayer.get("layerId") : sourceLayer.get("id");
          self.refreshLayers(sourceLayerUrl, _layerId);

        graphicsLayer.removeMany(event.graphics);
        _map.layers.remove(graphicsLayer);        

      }).catch(ex => {
          console.log("error occured ", ex);
        notify(`Помилка при збережені даних.\r\n  ${ex._body}`, "error", 3500);
      });
      //notify("completed");
    })
    
  }

  private EditNavObject(graphic) {
    let graphicsLayer: __esri.GraphicsLayer = new this.esriService.GraphicsLayer({
      id: "tempLayerID"
    });
    this.sharedService.map.layers.add(graphicsLayer);   
    let editGraphic: __esri.Graphic = graphic.clone();
    let _url = (graphic as __esri.Graphic).get<any>("layer").subLayerUrl;
    this.sharedService.mapView.popup.close();
    graphicsLayer.add(editGraphic);    

    let sketchViewModel: __esri.SketchViewModel = new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: false,
      layer: graphicsLayer,
      pointSymbol: MapConfig.drawPointSymbol,
      polylineSymbol: MapConfig.drawPolylineSymbol,
      polygonSymbol: MapConfig.drawPolygonSymbol
    });
    switch (editGraphic.geometry.type) {
      case "point":
        editGraphic.symbol = sketchViewModel.pointSymbol;
        break;
      case "polyline":
        editGraphic.symbol = sketchViewModel.polylineSymbol;
        break;
      case "polygon":
        editGraphic.symbol = sketchViewModel.polygonSymbol;
        break;
    }
    (sketchViewModel as __esri.SketchViewModel).update([editGraphic]).then(val => { });
    var self = this;
    sketchViewModel.on("update", function (event) {
      if (event.state != "complete") {
        return;
      }

      _url = _url.replace('MapServer', 'FeatureServer');
      let sourceLayer = graphic.layer;

      let tempLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({
        url: _url + `/${sourceLayer.layerId}`
      });
      tempLayer.applyEdits({
        updateFeatures: event.graphics
      }).then(val => {
        let _map = self.sharedService.map as __esri.Map;
        let _navigateLayer = _map.layers.find(layer => layer.id == "navigationLayerID");
        if (_navigateLayer) {
          let tmp = (_navigateLayer as __esri.GraphicsLayer).graphics.find(x => x.attributes["OBJECTID"] == event.graphics[0].attributes["OBJECTID"]) ; //  self.searchService.search.resultGraphic;
          tmp.geometry = event.graphics[0].geometry;
          (_navigateLayer as __esri.GraphicsLayer).graphics.remove(tmp);
          (_navigateLayer as __esri.GraphicsLayer).graphics.add(tmp);         
        }          
        self.refreshLayers(sourceLayer.subLayerUrl, sourceLayer.layerId);         
        let _layer = _map.layers.find(layer => layer.id == "tempLayerID");
        if (_layer) {
          _map.layers.remove(_layer);
        }

      }).catch(ex => {
        console.log("error occured", ex);
        notify(`Помилка при збережені даних.\r\n  ${ex._body}`, "error", 3500);
      });
      //notify("completed");
    })

  }

    public refreshLayers(sourceLayerUrl: string, layerId: any) {
      let _map = this.sharedService.map as __esri.Map;
      let allLayers = _map.layers.toArray();

      let featureUrl = sourceLayerUrl.replace('MapServer', 'FeatureServer');        
      for (const _repaintLayer of allLayers.filter(l => l.get<any>("url") == featureUrl && l.get<any>("layerId") == layerId)) {
          (_repaintLayer as __esri.FeatureLayer).refresh();
      }

      let mapUrl = sourceLayerUrl.replace('FeatureServer', 'MapServer');        
      for (const _repaintLayer of allLayers.filter(f => mapUrl.includes(f.get<any>("url")))) {
          let foundSublayer = (_repaintLayer as __esri.MapImageLayer).sublayers.find(sl => sl.id == layerId);
          if (foundSublayer) {
              (_repaintLayer as __esri.MapImageLayer).refresh();
          }
      }
    }

  public InitCoordWidget(): CoordwidgetComponent {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(CoordwidgetComponent);
    let containerRef = this.viewContainerRef;
    containerRef.clear();
    const coordComponent = <CoordwidgetComponent>containerRef.createComponent(componentFactory).instance;
    return coordComponent;
  }

  private selectFeature(graphic: __esri.Graphic) {

    let featureLayer =(graphic.layer as __esri.FeatureLayer);
    let existsFeature = this.sharedService.mapView.graphics.find(x => x.get<boolean>("selectedFeature"));
    if (existsFeature) {
      this.sharedService.mapView.graphics.remove(existsFeature);
      if (existsFeature.get<any>("sourceLayer")) {
        let sourceLayer = existsFeature.get<any>("sourceLayer");
        if (sourceLayer) {
          (sourceLayer as __esri.Layer).get<any>("layerView")?.highlightHandler?.remove();
        }
      }
    }

    let feature: __esri.Graphic;
    if ((graphic as __esri.Graphic).layer && (graphic as __esri.Graphic).layer.get<boolean>('cadastreGraphicLayer')) {
      let _geometry = (graphic as __esri.Graphic).layer.get<any>('cadastreMapPoint');
      feature = new this.esriService.Graphic({
        geometry: _geometry
      })
    } else {
      //return;
      feature = graphic.clone();
      feature.popupTemplate = null;
    }

    let geometryType: any;

    if (feature.layer && feature.layer.get<any>('layerView')) {
      let layerView = feature.layer.get<any>('layerView');
      layerView.highlightHandler = layerView.highlight(feature.attributes["OBJECTID"]);
    }
    if (featureLayer && featureLayer.get<any>("geometryType"))  {
      geometryType = featureLayer.get<any>("geometryType");
    } else {
      geometryType = feature.geometry.type;
    }
    
   var _symbol = new this.esriService.SimpleFillSymbol();
    feature.set<boolean>("selectedFeature", true);

    switch (geometryType) { //switch (feature.layer.get<any>("geometryType") ){
      case "point": //case "esriGeometryMultipoint":
        _symbol = MapConfig.popupPointSymbol;
        break;
      case "multipoint":
        _symbol = MapConfig.popupMultiPointSymbol;
        break;
      case "polyline":
        _symbol = MapConfig.popupPolylineSymbol;
        break;
      case "polygon": case "extent": default:
        _symbol = MapConfig.popupPolygonSymbol;
        break;
    }

    feature.symbol = _symbol;
   this.sharedService.mapView.graphics.add(feature);
  }

  getRecordCount(layer: __esri.Sublayer, subQuery?): Promise<number> {

    let queryTask: __esri.query= this.esriService.QueryTask;
    let url = layer.url + "?token=" + this.configService.getGisToken();
    let queryWhere = (subQuery ? subQuery : "1=1");
    var query: __esri.Query = new this.esriService.Query();
    query.where = queryWhere;

    return queryTask.executeForCount(url, query
    );
  }

  getRecordCountByUrl(url: string, subQuery?): Promise<number> {

    let queryTask: __esri.query = this.esriService.QueryTask;
    url = url + "?token=" + this.configService.getGisToken();
    let queryWhere = (subQuery ? subQuery : "1=1");
    var query: __esri.Query = new this.esriService.Query();
    query.where = queryWhere;
    return queryTask.executeForCount(url, query
    );
  }


  //getCadastreContent(mapPoint) {
  //  let query = 'x=' + mapPoint.y + '&y=' + mapPoint.x + '&actLayers%5B%5D=kadastr&zoom=15';
  //  let options: __esri.RequestOptions =
  //    {
  //      method: 'post',
  //      body: query // JSON.stringify(obj) // formdata //  'x=6336072.9080529&y=3368634.835302&zoom=15&actLayers%5B%5D=kadastr' 
  //    };
  //  var self = this;
  //  this.esriService.Request('http://map.land.gov.ua/kadastrova-karta/getobjectinfo', options).then(function (resultService) {
  //    if (resultService) {
  //      // Promise.resolve(resultService.data.dilanka);
  //      let options: __esri.PopupOpenOptions = {
  //        title: 'Публічний кадастр',
  //        location: mapPoint,
  //        content: resultService.data.dilanka
  //      }
  //      self.sharedService.mapView.popup.open(options);
  //      return resultService.data.dilanka
  //    } else {
  //      return '';
  //    }
  //  })
  //}
   getCadastreObject(mapPoint): IPromise<any> {  //: Promise<string>
    let result = null;
    //var obj = {
    //  x: event.mapPoint.x,
    //  y: event.mapPoint.y,
    //  actLayers: 'kadastr',
    //  zoom : 15
    //};

    //let formdata = new FormData();
    //formdata.append('x', event.mapPoint.x);
    //formdata.append('y', event.mapPoint.y);
    //formdata.append('actLayers[]', 'kadastr');
    //formdata.append('zoom', '15');

    let query = 'x=' + mapPoint.y  + '&y=' + mapPoint.x  + '&actLayers%5B%5D=kadastr&zoom=15';
    //let query = 'x=' + projectPoint.x + '&y=' + projectPoint.y + '&actLayers%5B%5D=kadastr&oom=15';
    let options: __esri.RequestOptions =
      {
        //query: 'x=' + event.mapPoint.x + '&y=' + event.mapPoint.y + '&actLayers%5B%5D=kadastr&oom=15',
        method: 'post',
        body: query // JSON.stringify(obj) // formdata //  'x=6336072.9080529&y=3368634.835302&zoom=15&actLayers%5B%5D=kadastr' 
      };
    var self = this;
    // options.method = 'post';
    result =  new Promise<any>((resolve) => {
      this.esriService.Request('https://map.land.gov.ua/mapi/get-object-info', options).then(function (resultService) {
        if (resultService) {          
          let markerSymbol = {
            type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
            color: [0, 0, 0],
            outline: { // autocasts as new SimpleLineSymbol()
              color: [0, 0, 0],
              width: 2
            }
          };
           resultService.data.dilanka
          let item: __esri.Graphic = new self.esriService.Graphic({
            geometry: mapPoint,
            //symbol: markerSymbol,
            popupTemplate: { title: 'cadastre', content: 'Номер : {Num} Площа : {Square}' }
            //popupTemplate: { title: 'cadastre', content: 'Номер : Площа ' }
           });
          
          //item.set<boolean>('IsCadastreItem', true);
          ////self.sharedService.mapView.graphics.add(item);
          ////item.attributes = {
          ////  Num: '123345',
          ////  Square: '22'
          ////};
          item.setAttribute('ObjectID', '123345');
          item.setAttribute('Num', '123345');
          item.setAttribute('Square', '333');

          //let graphicLayer = (self.sharedService.map as __esri.Map).layers.find(x => x.get<boolean>('cadastreLayer'));
          //(graphicLayer as __esri.FeatureLayer).source.add(item);
          let _features = self.sharedService.mapView.popup.features;
          _features.push(item);
          //self.sharedService.mapView.popup.close();
          
          //self.sharedService.mapView.popup.open({
          //  features: _features,
          //  //featureMenuOpen: true,
          //});
         // //features.push(item);
         // //self.sharedService.mapView.popup.clear();
         // self.sharedService.mapView.popup.close();
         // self.sharedService.mapView.popup.features = features;
         //// self.sharedService.mapView.popup.
         // //self.sharedService.mapView.popup.visible = false;
         // self.sharedService.mapView.popup.location = mapPoint;
         // self.sharedService.mapView.popup.visible = true;
          //resolve(resultService.data.dilanka); // Promise.resolve(resultService.data.dilanka);
          let items = [];
          items.push(item);
          resolve(items); 
          // Displays the popup
          

          //let options: __esri.PopupOpenOptions = {
          //  title: 'Публічний кадастр',
          //  location: mapPoint,
          //  featureMenuOpen: true
          //}

          ////options.updateLocationEnabled = true;
          //self.sharedService.mapView.popup.open(options);
          //self.sharedService.mapView.popup.open();
          //let fields: [__esri.Field] = resultService.data.fields;
          //layer.set<any>("fields", fields);
          //if (attributes) {

          //  result = '<ul>';
          //  result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
          //  attributes.forEach(x => {
          //    if (x.inMini) {
          //      let field = fields.find(f => f.name == x.name)
          //      result += `<li>${field.alias} : {${x.name}}`;
          //    }

          //  });
          //  result += '</ul>';
          //  layer.popupTemplate.content = result;
          //}
        } else {
          resolve([]);//Promise.resolve('');
        }
      })
     
    });
    

    return result;
  }

  

  private OrderByGeometry(graphics: __esri.Graphic[]): __esri.Graphic[] {    

    let orderGraphics: __esri.Graphic[] = graphics.sort((a, b) => {            

      if (a?.geometry?.type == "polygon" && (b?.geometry?.type != "polygon")) {
        return 1;
      } else if (a?.geometry?.type == "polygon" && (b?.geometry?.type == "polygon")) {
        var areaA = this.esriService.GeometryEngine.geodesicArea(a.geometry, SquareUnits.meter);
        var areaB = this.esriService.GeometryEngine.geodesicArea(b.geometry, SquareUnits.meter);
        if (areaA < 0 ) {
          // simplify the polygon if needed and calculate the area again
          let simplifiedPolygon = this.esriService.GeometryEngine.simplify(a.geometry);
          if (simplifiedPolygon) {
            areaA = this.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);            
          }
        }

        if (areaB < 0) {
          // simplify the polygon if needed and calculate the area again
          let simplifiedPolygon = this.esriService.GeometryEngine.simplify(b.geometry);
          if (simplifiedPolygon) {
            areaB = this.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, SquareUnits.meter);
          }
        }

        if (areaA > areaB) {
          return 1;
        } if (areaA < areaB) {
          return -1
        } else {
          return 0;
        }

      }
      else if (a?.geometry?.type == "polyline" && b?.geometry?.type == "point") {
        return 1;
      } else if (a?.geometry?.type == "polyline" && b?.geometry?.type == "polygon") {
        return -1;
      }
      else if (a?.geometry?.type == "point" && b?.geometry?.type != "point") {
        return -1;
      } else if (a?.geometry == null) {
        return 1;
      }
      else {
        return -1;
      }      
      
    }) 
    

    return orderGraphics;
  }


  private  getObjectByFeatureLayer(items: __esri.Graphic[], layer: __esri.FeatureLayer) {
    let ids = items.map(m => {
      return m.attributes["OBJECTID"];
    });
    const queryParams = {
        outFields: "OBJECTID",
        returnGeometry: true,
        objectIds: ids.toString(),
        f: "json",
    }    
    var queryUrl = `${layer.url}/${layer.layerId}/query`;
    return  this.esriService.Request(queryUrl, {
      query: queryParams,
      responseType: "json"
    }).then(function (response) {
        let results = response.data;
        return results.features;
    })
      .catch(ex => {
      return [];
    })
  }



  private async getGemotryFrom(graphics: __esri.Graphic[]) {
    try {
      let arrayPromisse: Promise<any>[] = [];
      var arr = graphics.filter(f => f.layer && (f.layer as __esri.FeatureLayer).type == "feature"
        && (f.layer as __esri.FeatureLayer).geometryType != "point").
        reduce((objectsByKeyValue, obj) => {
          let layer = obj.layer ? (obj.layer as __esri.FeatureLayer).id : "";  // obj.get<string>("sourceLayer?.layer?.id")
          const value = layer;
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {} as {}); // { name: string, graphics: __esri.Graphic[] }

      for (var group in arr) {
        let items: __esri.Graphic[] = [];
        let fLayer = arr[group]?.length > 0 ? arr[group][0].layer as __esri.FeatureLayer : null;
        for (var i = 0; i < arr[group].length; i++) {
          let _id = arr[group][i];
          items.push(_id);
        }
        if (fLayer) {
          let _promisse: Promise<any> = this.getObjectByFeatureLayer(items, fLayer);
          arrayPromisse.push(_promisse);
        }
        }
      let temp = await Promise.all(arrayPromisse).then(result => {
        result?.forEach(r => {
          r.forEach(f => {
            let item = graphics.find(i => i.attributes["OBJECTID"] == f.attributes["OBJECTID"]);
              if (item) {
                  try {
                    f.geometry.type = item.layer?.get("geometryType") ?? item.geometry.type;
                    item.geometry = this.esriService.webMercatorUtils.webMercatorToGeographic(f.geometry);
                    item.geometry = this.esriService.Projection.project(item.geometry, MapConfig.spatialReferenceWGS);
                  } catch (error) {
                      console.log("Error occured", error);
                  }
            }
          })
        })

      }).catch(ex => {
        return graphics;
      });
    } catch (e) {
      console.log(e);
      return graphics;
    }


    return graphics;
  }

  private showCopygeodata(feature: __esri.Graphic) {
    this.viewContainerRef.clear();
    this.copygeodataComponentRef = this.viewContainerRef.createComponent(CopygeodataComponent);
    this.copygeodataComponentRef.instance.CopyFeature = feature;
    this.copygeodataComponentRef.instance.close.subscribe(() => {
      this.closeCopygeodata();
    })
  }

  private closeCopygeodata(): void {
    if (this.copygeodataComponentRef) {
      this.copygeodataComponentRef.destroy();
    }
  }

  clearViewContainerRef() {
    this.viewContainerRef.clear();
  }
}



import { Injectable } from "@angular/core";
import { FilterLayer } from "../../models/filters/filter.layer.model";
import { LayerDataWithAttr } from "../../models/layers/layer.data.model";
import { FilterAttributeService } from "../filterattribute/filter-attribute.service";
import { LayerServerService } from "./layer-server.service";
import { LayerDataAttribute } from "../../models/layers/layer-data-attribute.models";

@Injectable()
export class LayerAttributeHeplerService {

  constructor(private filterAttributeService: FilterAttributeService, private layerInfoService: LayerServerService) {

  }

  public async filterLayerByAttribute(d: LayerDataWithAttr | FilterLayer, _layer: __esri.FeatureLayer | __esri.Sublayer, layerUrl: string = null): Promise<string> {
    let subTypeField;
    let filter = '';
    if (d.layerAttributes.find(a => a.hasFilter)) {
      let groupTypeArray: any[] = [];

      for (const attr of d.layerAttributes.filter(f => f.hasFilter) as LayerDataAttribute[]) {
        if (attr.filterType == this.filterAttributeService.TypeSubType && attr.subTypeFilters) {
          let visibledSubtypes = [];
          attr.subTypeFilters.forEach(st => {
            visibledSubtypes.push(st.subTypeID);
          });
          (_layer as __esri.Layer).set<number[]>("visibledSubtypes", visibledSubtypes);
          let strFilter = "";
          attr.subTypeFilters.forEach(s => {
            if (strFilter.length > 0) {
              strFilter = strFilter + ','
            }
            strFilter = strFilter + s.subTypeID;
          })
          if (filter.length > 0) {
            filter = filter + ' and ';
          }
          filter += attr.name + " in ( " + strFilter + ")";
          subTypeField = attr.name;
        } else if (attr.filterType == this.filterAttributeService.TypeKOATUU) {
          let filterKOATUU = attr.name + " = '" + this.filterAttributeService.getFilterValue(attr.filterType) + "'";
          if (filter.length > 0) {
            filter = filter + ' and ';
          }
          filter += filterKOATUU;
          (_layer as __esri.Layer).set<string>("filterKOATUU", filterKOATUU);
        } else if (attr.filterType == this.filterAttributeService.TypeGroup) {

          let layerInfo = await this.layerInfoService.getLayerInfo(layerUrl ?? _layer.url);
          let fields: any[] = layerInfo.fields;
          let groupFilter = "";
          attr.groupValueFilters.forEach(g => {
            if (groupFilter.length > 0) {
              groupFilter += ',';
            }
            let field = fields?.find(f => f.name == attr.name);
            g.esriFieldType = field?.type;
            if (g.esriFieldType != 'esriFieldTypeString') {
              groupFilter += `${g.value}`;
            } else {//if (g.esriFieldType == "esriFieldTypeInteger")
              groupFilter += `N'${g.value}'`;
            }
          })

          if (groupFilter.length > 0) {
            if (filter.length > 0) {
              filter = filter + ' and ';
            }
            filter += attr.name + " in ( " + groupFilter + ")";
          }
          let n = {
            name: attr.name,
            group: attr.groupValueFilters
          }
          groupTypeArray.push(n);

        }
      }
      if (groupTypeArray && groupTypeArray.length > 0) {
        (_layer as __esri.Layer).set<any>("filterGroupType", groupTypeArray);
      }
    }

    return filter;
  }

}

import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CopyGeodataService } from '../../services/copygeodata/copy-geodata.service';
import { SharedService } from '../../services/shared/shared.service';
import { AttributeSourceItem, LayerDataItem, LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { LayerServerService } from '../../services/layers/layer-server.service';
import { FilterAttributeService } from '../../services/filterattribute/filter-attribute.service';
import { ConfigService } from '../../services/shared/utils/config.service';
import { EsriService } from '../../services/esri/js-esri.service';
import { LegendItem } from '../../models/layers/legend.model';
import { LegendQuerySevice } from '../../services/legends/legend-query.service';
import { MapService } from '../../services/map/map.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-copygeodata',
  templateUrl: './copygeodata.component.html',
  styleUrls: ['./copygeodata.component.css']
})
export class CopygeodataComponent implements OnInit {
  layersDataSource: LayerDataWithAttr[];
  currentAttributes: LayerDataItem[] = [];
  currentLayer: string;
  public CopyFeature: __esri.Graphic;
  @Output() close = new EventEmitter<void>();
  @ViewChild('copygeodataWidgetID', { static: false }) coordsWidgetID: ElementRef<HTMLDivElement>;

  closeButtonOptions: Record<string, unknown>;
  saveButtonOptions: Record<string, unknown>;

  constructor(private copyGeodataService: CopyGeodataService, private sharedService: SharedService,
    private layerServerService: LayerServerService, private configService: ConfigService,
    private filterAttributeService: FilterAttributeService, private esriService: EsriService,
    private legendQueryServce: LegendQuerySevice, private mapService: MapService) {

    this.closeButtonOptions = {
      text: 'Скасувати',
      stylingMode: 'text',
      type: 'normal',
      height: '48',
      onClick: this.closePopup.bind(this),
    };

    this.saveButtonOptions = {
      text: 'Зберегти',
      stylingMode: 'text',
      type: 'default',
      height: '48',
      onClick: this.addLayer.bind(this),
    };

  }

  async ngOnInit() {
    
    this.layersDataSource = await this.copyGeodataService.getLayers(this.sharedService.getRegionIDValue(), this.CopyFeature.geometry.type);

  }
  addLayer = async () => {
    const featureAttributes = this.currentAttributes.reduce((acc, curr) => {
      acc[curr.name] = curr.value;
      return acc;
    }, {});
    this.CopyFeature.attributes = featureAttributes;
    let layer = this.layersDataSource.find(d => d.id == this.currentLayer);
    let layerUrl = this.configService._baseUrlRegionServices + `${layer.serviceName}` + `/` + `${layer.layerName}`;
    layerUrl = layerUrl.replace('MapServer', 'FeatureServer');
    let featureLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer({
      url: layerUrl
    });

    featureLayer.applyEdits({
      addFeatures: [this.CopyFeature]
    }).then(async result => {
      let refreshLayerUrl = `${this.configService._baseUrlRegionServices}${layer.serviceName}`;
      this.mapService.refreshLayers(refreshLayerUrl, layer.layerName);

      const layerInfo = await this.layerServerService.getLayerInfo(layerUrl);
      const subTypeInfo = this.layerServerService.subTypeInfo(layerInfo);
      let legend: LegendItem;
      let layerDataGuid = layer.id;

      if (subTypeInfo?.subtypeFieldName) {
        let subTypeID = featureAttributes[subTypeInfo.subtypeFieldName];
        legend = this.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
          x.id == layer.layerName &&
          x.defaultValues.find(f => f == subTypeID));
      } else {
        legend = this.sharedService.publicLegends.find(x => x.layerGuid == layerDataGuid &&
          x.id == layer.layerName);
      }
      if (legend) {
        let subQuery = this.legendQueryServce.getSubQuery(legend);
        this.mapService.getRecordCountByUrl(layerUrl, subQuery).then(result => {
          if (legend) {
            legend.totalCount = result;
          }
        })
      }
      notify(`Данні успішно скопіювано`, "info", 3500);
      this.closePopup();
    }).catch(ex => {
      console.log("error occured", ex);
      notify(`Помилка копіювання даних.\r\n  ${ex._body}`, "error", 3500);
    });
  }

  onValueChangedLayer = async (e) => {
    this.currentAttributes = [];
    
    let layer = this.layersDataSource.find(d => d.id == this.currentLayer);
    let layerUrl = this.configService._baseUrlRegionServices + `${layer.serviceName}` + `/` + `${layer.layerName}`;
    let layerInfo = await this.layerServerService.getLayerInfo(layerUrl);
    let layerFields: any[] = layerInfo?.fields ?? [];
    let subTypeInfo = this.layerServerService.subTypeInfo(layerInfo);
    if (subTypeInfo.subtypeFieldName && subTypeInfo.subtypes?.length > 0) {
      let subtypes = subTypeInfo.subtypes.map(s => {
        return { value: s.id, name: s.name }
      })
      let field = layerFields ? layerFields.find(f => f.name == subTypeInfo.subtypeFieldName) : null;
      let alias = `${(field ? field.alias : subTypeInfo.subtypeFieldName)}`;
      let subTypeFieldAttr =  layer.layerAttributes.find(x => x.name == subTypeInfo.subtypeFieldName)
      let copyValue = subTypeFieldAttr?.isCopy ? this.CopyFeature.attributes[subTypeFieldAttr.name] : undefined;
      let item: LayerDataItem =
      {
        name: subTypeInfo.subtypeFieldName,
        alias: alias,
        filterType: this.filterAttributeService.TypeSubType,
        fieldType: this.layerServerService.serverFieldTypeToEsri(field.type),
        visible: true,
        source: subtypes,
        value: copyValue?? subtypes[0].value,
      }
      this.currentAttributes.push(item);
    }
    for (const attr of layer.layerAttributes.filter(f => f.hasFilter || f.editable)) {
      
      let attrSource: { value: any; name: any }[] = [];
      if (attr.hasFilter) {
        switch (attr.filterType) {
          case this.filterAttributeService.TypeKOATUU:
            let sourceItem: AttributeSourceItem = { value: this.sharedService.CurrentRegionCode, name: this.sharedService.CurrentRegionCode };
            attrSource.push(sourceItem);
            break;
          case this.filterAttributeService.TypeSubType:            
            let subtypeItems: AttributeSourceItem[] = attr.subTypeFilters.map(st => {
              return { value: st.subTypeID, name: st.subTypeValue }
            });
            let copyValue = attr.isCopy ? this.CopyFeature.attributes[attr.name] : undefined;
            let layerSubTypeItem = this.currentAttributes.find(f => f.filterType == this.filterAttributeService.TypeSubType);
            layerSubTypeItem.source = subtypeItems;
            layerSubTypeItem.value = copyValue?? (subtypeItems?.length > 0 ? subtypeItems[0].value : undefined);
            break;
          case this.filterAttributeService.TypeGroup:
            let groupItems: AttributeSourceItem[] = attr.groupValueFilters.map(g => {
              return { value: g.value, name: g.value }
            })
            attrSource.push(...groupItems);
            break;
        }
      }
      if (attr.name != subTypeInfo.subtypeFieldName) {
        let field = layerFields ? layerFields.find(f => f.name == attr.name) : null;
        let fieldName = `${(field ? field.alias : attr.name)}`;
        let copyValue = attr.isCopy ? this.CopyFeature.attributes[attr.name] : undefined;
        let item: LayerDataItem = {
          name: attr.name,
          alias: fieldName,
          fieldType: this.layerServerService.serverFieldTypeToEsri(field.type),
          filterType: attr.hasFilter ? attr.filterType : undefined,
          visible: attr.filterType == this.filterAttributeService.TypeKOATUU ? attr.editable : true,
          source: attrSource,
          value: copyValue ?? (attrSource?.length ? attrSource[0].value : undefined),
        }
        this.currentAttributes.push(item);
      }           

    }
  }

  closePopup() {
    this.close.emit();
  }
}

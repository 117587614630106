import { Component, OnDestroy, OnInit } from '@angular/core';
import { GalleryMapService } from "../../services/gallerymap/gallerymap.service";

import { SharedService } from "../../services/shared/shared.service";
import { Subscription } from "rxjs";
import { UserService } from "../../services/user/user.service";

@Component({
  selector: 'app-gallerymap',
  templateUrl: './gallerymap.component.html',
  styleUrls: ['./gallerymap.component.scss']
})

export class GallerymapComponent implements OnInit, OnDestroy {
  thumbnailUrl: string;
  isShowed: boolean = false;
  subscription: Subscription;

  constructor(private galleryMapService: GalleryMapService,
    private sharedService: SharedService, private userService: UserService) { }

  ngOnInit() {   
    this.galleryMapService.getGalleryMap();
    this.sharedService.getRegionID().subscribe(u => {
      this.galleryMapService.clearBasemaps();
      if (this.userService.isLoggedIn()) {
        this.galleryMapService.addUsersMaps(u);
        
      }
        
    })
    this.subscription = this.sharedService.getThumbnailUrl().subscribe(url => {
      this.thumbnailUrl = url;
      this.isShowed = false;
    });
  }

  swithGalleryMap() {
    this.isShowed = !this.isShowed;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
}

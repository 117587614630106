import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-document-view',
  templateUrl: './document.view.component.html',
  styleUrls: ['./document.view.component.scss']
})
export class DocumentViewComponent  {
  currentDoc: Blob;
  documentName: any;
  constructor(
    private azureStorageService: AzureStorageService,
    private sharedService: SharedService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.documentName = this.sharedService.currentDocumentName;
    this.azureStorageService.getBlobOnly(this.sharedService.currentDocumentContainer, this.sharedService.currentDocumentName).then(result => {      
      this.currentDoc = result;
      
    });
  }

  goBack() {
    this.router.navigate(['./', { outlets: { 'viewrouter': null } }], { skipLocationChange: true });
  }

}

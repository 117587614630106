
export class FilterLayer {
  constructor(
    id?: string,
    filterID?: string,
    regionID?: string,
    layerDataID?: string,
    filterGroupID?: string,
    name?: string
    ) {

    this.id = id;
    this.regionID = regionID;
    this.layerDataID = layerDataID;
    this.filterID = filterID;
    this.filterGroupID = filterGroupID;
    this.name = name;
  }

  public id: string;
  public regionID: string;
  public layerID: string;
  public layerDataID: string;
  public filterID: string;
  public filterGroupID: string;
  public name: string;
  public fields: any[] = [];
  public textFilter: string;
  public textFilterData: any;
  public serviceName: string;
  public layerName: string;
  public layerFilter: string;
  public transparency: string;
  public canAddObject: boolean;
  public layerAttributes: any[] = [];
  public subtypesInfo: any[] = [];
  public subtypeFiledNameInfo: any;
  public subtypesFilterSelected: any[] = [];
}

import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AuthGuard } from './auth.guard';
import { HomeComponent } from './components/home/home.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LegendsComponent } from './components/legends/legends.component';
import { LayersComponent } from './components/layers/layers.component';
import { FiltersComponent } from './components/filters/filters.component';
import { SearchComponent } from './components/search/search.component';
import { MapComponent } from './components/map/map.component';
import { AppComponent } from './app.component';
import { AppResolveService } from './services/shared/loader/app-resolve.service';
import { BookmarksAnonymousComponent } from './components/bookmarksanonymous/bookmarks.anonymous.component';
import { AttributesComponent } from "./components/layers/attributes/attributes.component";
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { EditGeodataComponent } from './components/editgeodata/edit-geodata.component';
import { ViewGeodataComponent } from './components/editgeodata/viewgeodata/view-geodata.component';
import { BookmarkRegionComponent } from './components/region/bookmark.region.component';
import { EditFilterComponent } from './components/filters/editfilter/edit-filter.component';
import { NavigationListComponent } from './components/navigation/navigation-list.component';
import { ChartComponent } from './components/chart/chart.component';
import { MainPageComponent } from './components/mainpage/mainpage.component';
import { StoryComponent } from './components/story/story.component';
import { BookmarkAddComponent } from './components/bookmarks/bookmarkadd/bookmarkadd.component';
import { BookmarkEditComponent } from './components/bookmarks/bookmarkedit/bookmarkedit.component';
import { DocumentViewComponent } from './components/documentview/document.view.component';
import { ImageViewComponent } from './components/imageview/image.view.component';
import { GeometryCalculatorComponent } from './components/geometry-calculator/geometry-calculator.component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      {
        path: "", component: AppComponent, resolve: { esriModules: AppResolveService }, children: [
          {path: "", component: HomeComponent, children:
            [
              { path: "basemap", component: BookmarksAnonymousComponent },
              { path: "legends", component: LegendsComponent },
              { path: "map", component: BookmarkRegionComponent },
              { path: "map/:bookmarkid", component: BookmarkRegionComponent },
              { path: "charts/:bookmarkid", component: ChartComponent },
              { path: "attributes", component: ViewGeodataComponent },
              { path: "viewfile", component: ViewGeodataComponent },
              { path: "navigationlist/:layerid/:sublayerid/:layerdataguid", component: NavigationListComponent },
              { path: "navigationlist/:layerid/:sublayerid/:layerdataguid/:subtypeid", component: NavigationListComponent },
              { path: "navigationlist/:legendguid", component: NavigationListComponent },             
              {
                path: "", component: SidebarComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard], children: [
                  { path: "", component: LayersComponent },
                  { path: "story/:bookmarkid", component: StoryComponent },
                  { path: "layers", component: LayersComponent },
                  { path: "legend", component: LegendsComponent },
                  { path: "filter", component: FiltersComponent },
                  { path: "bookmarks", component: BookmarksComponent, children: [
                    { path: "charts/:bookmarkid", component: ChartComponent },
                    ]
                  },
                  { path: "filtersettings", component: EditFilterComponent },
                  { path: "filtersettings/:id", component: EditFilterComponent },
                  { path: "savebookmark", component: BookmarkAddComponent },
                  { path: "bookmark/:id", component: BookmarkEditComponent },
                  { path: "addstory/:parentid", component: BookmarkAddComponent },
                  { path: "bookmarks/:parentid/:id", component: BookmarksComponent },
                ]
              },
              { path: "", component: MapComponent, outlet: 'maprouter' },
              //{ path: "", component: SearchComponent, outlet: 'searchrouter' }                    
            ]
        }, { path: "home", component: MainPageComponent }         
        ]
      },
      { path: "addfeature/:layerguid/:subtypeid", component: EditGeodataComponent, canActivate: [AuthGuard], outlet: 'modalrouter' },
      { path: "addfeature/:layerguid", component: EditGeodataComponent, canActivate: [AuthGuard], outlet: 'modalrouter' },
      , { path: "modal", component: EditGeodataComponent, canActivate: [AuthGuard], outlet: 'modalrouter' }
      , { path: "viewdoc", component: DocumentViewComponent, canActivate: [AuthGuard], outlet: 'viewrouter' }
      , { path: "viewimage", component: ImageViewComponent, canActivate: [AuthGuard], outlet: 'viewrouter' }
      , { path: "geometrycalculate", component: GeometryCalculatorComponent, canActivate: [AuthGuard], outlet: 'modalrouter' }
    ])
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
   // AuthService, 
  ]
})
export class AppRoutingModule { }

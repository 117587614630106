export class ChartInfo {
  constructor(name?: string, chartType?: ChartType, values?: ChartValueInfo[]) {
    this.name = name;
    this.chartType = chartType;
    this.values = values;
  }

  name: string;
  chartType: ChartType;
  values: ChartValueInfo[] = [];
}

export class ChartValueInfo {
  constructor(id: string, name?: string, value?: number) {
    this.name = name;
    this.value = value;
    this.id = id;
  }
  id: string;
  name: string;
  value: number;
}


const ChartType = {
  pie: 'pie' as 'pie',
  bar: 'bar' as 'bar'
}
type ChartType = (typeof ChartType)[keyof typeof ChartType];
export { ChartType };

export class ChartLegendInfo {
  id: string;
  name: string;
  details: ChartLegendDetail[] = [];
}
export class ChartLegendDetail{
  id: string;
  chartLegendInfoID: string;
  layerID: string;
  layerDataID: string;
  name: string;
  url: string;
  textFilter: string;
  serviceName: string;
  layerName: string;

  expression: string;
}
export class ChartSettings {
  constructor(id?: string, name?: string, chartType?: ChartType, legendsInfo?: ChartLegendInfo[]) {
    this.id = id;
    this.name = name;
    this.chartType = chartType;
    this.legendsInfo = legendsInfo;
  }
  id: string;
  name: string;
  chartType: ChartType;
  isShowSum: boolean;
  description: string;
  showCountInLegend: boolean;
  legendsInfo: ChartLegendInfo[] = [];
}


import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http'; //URLSearchParams
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';



@Injectable()
export class ImageServiceEndpoint extends EndpointFactory {

  private readonly _getImageServiceByRegionUrl: string = "/api/imageservice/byregion";
  private readonly _getImagePublicServiceByIdUrl: string = "/api/imageservice/imagePublicService";


  get getImageServiceByRegionUrl() { return this.configService.getApiURI() + this._getImageServiceByRegionUrl; }
  get getImagePublicServiceByIdUrl() { return this.configService.getApiURI() + this._getImagePublicServiceByIdUrl; }


  getImageServiceByRegionEndpoint(regionId: string): Promise<any> {   
    let endpointUrl = `${this.getImageServiceByRegionUrl}/${regionId}`;

    return this.http.get(endpointUrl, this.getAuthHeader())
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getImageServiceByRegionEndpoint(regionId));
      })
  }

  getImagePublicServiceByIdEndpoint(Id: string): Promise<any> {
    let endpointUrl = `${this.getImagePublicServiceByIdUrl}/${Id}`;

    return this.http.get(endpointUrl, this.getAuthHeader())
      .toPromise().then((response: Response) => {
        return response;
      })
      .catch(error => {
        return this.handlePromiseError(error, () => this.getImagePublicServiceByIdEndpoint(Id));
      })
  }
}

import { Component, OnInit } from '@angular/core';
import { BookmarksAnonymousService } from '../../services/bookmarksanonymous/bookmarks.anonymous.service';
import { Layer } from "../../models/layers/layer.model";
import { LayersService } from "../../services/layers/layers.service";
import { MapLayersService } from "../../services/map/map.layers.service";
import notify from 'devextreme/ui/notify';
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";
import { Router} from '@angular/router';
import { Subscription } from "rxjs";

@Component({
  selector: 'app-bookmarksanonymous',
  templateUrl: './bookmarks.anonymous.component.html',
  styleUrls: ['./bookmarks.anonymous.component.scss']
})
export class BookmarksAnonymousComponent implements OnInit {

  constructor(
    private bookmarksAnonymousService: BookmarksAnonymousService,
    private layerService: LayersService,
    private mapLayersService: MapLayersService,
    private sharedService: SharedService,
    private user: UserService, private router: Router) { }

  Layers: Layer[];
  currentLayerId: string = "";
  subscription: Subscription;
  ngOnInit() {
    if (this.user.isLoggedIn()) {
      this.router.navigate(['/layers']);
    }
    this.currentLayerId = this.sharedService.currentLayerId;

    //this.layerService.getPublicLayers()
    //  .subscribe(results => {

    //    //this.Layers = results;

    //  },
    //  error => {

    //    //notify(`Не вдається завантажити шари.\r\n Помилка: "${error._body}"`, "error", 3000);
    //    notify(`Не вдається завантажити шари.\r\n `, "error", 3000);
    //  });
  }

  initMap(layerId: string) {
    this.currentLayerId = layerId;
    this.sharedService.currentLayerId = layerId;
  }

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";
import { UserBookmark } from "../../models/bookmarks/bookmark.model";
import { Layer } from "../../models/layers/layer.model";
import { MapLayersService } from "../../services/map/map.layers.service";
import { GalleryMapService } from "../../services/gallerymap/gallerymap.service";
import { BaseComponent } from "../basecomponent/base.component";
import { Subscription } from 'rxjs';
import { FiltersService } from '../../services/filters/filters.service';
import { FeatureLayerService } from '../../services/map/feature-layer.service';
import { Router } from '@angular/router';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Filter } from '../../models/filters/filter.model';
import { FilterLayer } from '../../models/filters/filter.layer.model';


@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent extends BaseComponent implements OnInit, OnDestroy {
  userBookmarks: UserBookmark[];
  selectedBookmark: UserBookmark;
  isShow: boolean = false;
  subscription: Subscription;
  backToBookmark: boolean = false;
  bookamrkTitle: string;
  selectedBookmarkSubscription: Subscription;
  userBookmarksInitSubscription: Subscription;
  regionIDSubscription: Subscription;

  constructor(
    private bookmarksService: BookmarksService,
    private sharedService: SharedService,
    private userService: UserService,
    private mapLayersService: MapLayersService,
    private galleryMapService: GalleryMapService,
    private filtersService: FiltersService,
    private featureLayerService: FeatureLayerService,
    private router: Router,
    private azureStorageService: AzureStorageService
  ) {
    super();
  }

  ngOnInit() {

    this.regionIDSubscription = this.sharedService.getRegionID().subscribe(u => {
      if (u) {
        if (!this.sharedService.getUserBookmarksInitValue()) {
          this.bookmarksService.getListByRegionUser(u, this.userService.User.id).then(result => {

            this.userBookmarks = result;
            this.isShow = this.userBookmarks.length > 0;
            this.sharedService.setUserBookmarkList(result);
            this.sharedService.setUserBookmarksInit(true);
          })
        }

      } else {
        this.userBookmarks = new Array<UserBookmark>();
        this.isShow = false;
      }

    });

    this.userBookmarksInitSubscription = this.sharedService.getUserBookmarksInit().subscribe(res => {
      if (res) {
        this.backToBookmark = false;
        let bookmarks = this.sharedService.getUserBookmarListValue();
        //this.sharedService.getUserBookmarkList().
        //  subscribe(bookmarks => {
          this.userBookmarks = bookmarks;
          this.isShow = this.userBookmarks?.length > 0;

        //}).unsubscribe();
      }
    })

    this.selectedBookmarkSubscription = this.sharedService.getSelectedBookmark().subscribe(userBookmark => {
      if (userBookmark?.isStory && userBookmark?.parentID) {
        let parent = this.sharedService.getUserBookmarListValue().find(f => f.id == userBookmark.parentID);
        if (parent) {
          this.bookamrkTitle = parent.name;
          parent.bookmarks.forEach(b => {
            b.status = parent.status;
          })
          this.backToBookmark = true;
          this.userBookmarks = parent.bookmarks;
        } else {
          this.bookamrkTitle = '';
          this.backToBookmark = false;
        }


        this.isShow = this.userBookmarks?.length > 0;
      } else {
        let bookmarks = this.sharedService.getUserBookmarListValue();
        this.backToBookmark = false;
        //this.sharedService.getUserBookmarkList()
        //  .subscribe(bookmarks => {
          this.userBookmarks = bookmarks;
          this.isShow = this.userBookmarks?.length > 0;
        //}).unsubscribe();
      }

      if (userBookmark) {

        if (userBookmark.imageSource) {

        } else {
          this.initImage(userBookmark);
        }
        
      }
    });

  }

  async selectBookmark(bookmark: UserBookmark) {
    let oldVal = bookmark.isSelected;
    this.userBookmarks.filter(f => f.isSelected).forEach(b => {
      b.isSelected = false;
    })
    if (bookmark.isStory && bookmark.bookmarks?.length > 0) {
      this.userBookmarks = bookmark.bookmarks;
      bookmark = bookmark.bookmarks[0];      
    }

    if (oldVal) {
      this.unselectBookmark(bookmark);
      return;
    }
    this.initImage(bookmark);
    this.sharedService.setSelectedBookmark(bookmark);
    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }
    
    bookmark.isSelected = true;
    bookmark.isExpanded = true;
    this.selectedBookmark = bookmark;

    let layers = this.sharedService.getLayerList().getValue();

    layers.forEach(_layer => {
      _layer.isSelected = this.selectedBookmark.details.find(x => x.layerID == _layer.id) != null;
      _layer.inBookmark = _layer.isSelected;
      this.selectChildren(_layer.layerChildren);

    })

    this.sharedService.setLayers(layers);


    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });

    let filter: Filter;
    if (this.selectedBookmark.filterID) {
      filter = await this.filtersService.prepareFilter(this.selectedBookmark.filterID, true);
      layers = this.sharedService.getLayerList().getValue();
      //layers.filter(f => !!!f.inFilter).forEach(_layer => {
      //  _layer.isSelected = this.selectedBookmark.details.find(x => x.layerID == _layer.id ) != null;
      //  _layer.inBookmark = _layer.isSelected;
      //  this.selectChildren(_layer.layerChildren);
      //})
    }
    layers.filter(x => x.isSelected).forEach(result => {  //  && !!!x.inFilter
      this.featureLayerService.showFeatureServer(result.id);
    });
    
    if (this.selectedBookmark.imageServiceID)
      this.galleryMapService.selectBaseMap(this.selectedBookmark.imageServiceID);

    this.GoTo();
    
  }

  unselectBookmark(bookmark: UserBookmark) {
    this.userBookmarks.forEach(bookmark => { 
      bookmark.isSelected = false;
      bookmark?.bookmarks.forEach(cb => {
        cb.isSelected = false;
      })
    })

    this.sharedService.setSelectedBookmark(null);

    this.selectedBookmark = null;

    let layers = this.sharedService.getLayerList().getValue();

    layers.forEach(_layer => {
      let item = (bookmark.details.find(x => x.layerID == _layer.id) != null)
        || (this.sharedService.getCurrentFilterValue()?.layers.find(f => f.layerID == _layer.id) != null);
      if (item) {
        _layer.isSelected = false;
        _layer.inBookmark = _layer.isSelected;
                
      }      

      this.unselectChildren(_layer.layerChildren, bookmark, this.sharedService.getCurrentFilterValue());

    })

    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }

    this.sharedService.setLayers(layers);


    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });   
    
  }


  hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (!_layer.isSelected)
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

    })
  }

  selectChildren(layers: Layer[]) {
    layers.forEach(_layer => {
      _layer.isSelected = this.selectedBookmark.details.find(x => x.layerID == _layer.id) != null;
      _layer.inBookmark = _layer.isSelected;
      this.selectChildren(_layer.layerChildren);
    })
  }

  unselectChildren(layers: Layer[], bookmark : UserBookmark, filter? : Filter) {
    layers.forEach(_layer => {
      let item = bookmark.details.find(x => x.layerID == _layer.id) != null;

      if (item) {
        _layer.isSelected = false;
        _layer.inBookmark = _layer.isSelected;
      } else if (filter) {
        item = filter.layers.find(f => f.layerID == _layer.id) != null;
        if (item) {
          _layer.isSelected = false;
          _layer.inBookmark = _layer.isSelected;
        }
      }
      
      this.unselectChildren(_layer.layerChildren, bookmark, filter);
    })
  }

  private GoTo() {
    let opts = {
      duration: 500
    };
    let _zoom: number = this.selectedBookmark.scale && this.selectedBookmark.scale != 0 ? this.selectedBookmark.scale : this.sharedService.mapView.scale;
    let latitude = this.selectedBookmark.latitude;
    let longitude = this.selectedBookmark.longitude;
    if (latitude && longitude)
      this.sharedService.mapView.goTo({ center: [longitude, latitude], zoom: _zoom }, opts);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription?.unsubscribe();
    this.selectedBookmarkSubscription?.unsubscribe();
    this.userBookmarksInitSubscription?.unsubscribe();
    this.regionIDSubscription?.unsubscribe();
  }

  private initImage(bookmark: UserBookmark) {
    if (bookmark.imageSource) {
      return;
    }
    this.azureStorageService.listBlobsUrlsByContainer(bookmark.id).then(result => {
      bookmark.imageSource = result;
    })
  }

  editSettings(bookmark: UserBookmark) {
    this.router.navigate(['./bookmark', bookmark.id]);
  }
  removeBookamrk(bookmark: UserBookmark) {
    var confirmation = confirm("Ви впевнені, що хочете видалити закладку?", "Видалити закладку");
    confirmation.then(dialogResult => {
      if (dialogResult) {
        
        this.bookmarksService.deleteUserBookmark(bookmark.id).then(delBookmark => {

          let index = this.userBookmarks.findIndex(f => f.id == bookmark.id);
          if (index >= 0) {
            this.userBookmarks.splice(index, 1);
            //this.sharedService.setUserBookmarkList(this.userBookmarks);
          }

          if (bookmark.isSelected) {
            this.unselectBookmark(bookmark);
          }

          this.azureStorageService.deleteContainer(bookmark.id);
          //this.bookmarksService.getListByRegionUser(this.sharedService.getRegionIDValue(), this.userService.User.id).then(result => {

          //  this.userBookmarks = result;
          //  this.isShow = this.userBookmarks.length > 0;
          //  this.sharedService.setUserBookmarkList(result);
          //  this.sharedService.setUserBookmarksInit(true);
          //})
        })
      }
    }, (error) => {

      notify(`Під час видалення сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
    })
  }

  addStory(bookmark) {
    this.router.navigate(['./addstory', bookmark.id]);
  }

  goToParents() {
    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }
    let selectedBookmark = this.sharedService.getSelectedBookmarkValue();
    if (selectedBookmark) {
      this.bookmarksService.unselectBookmark(selectedBookmark);
    }

    let layers = this.sharedService.getLayerList().getValue();
    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });
  }

  hideBookmarkContent(bookmark) {
    bookmark.isExpanded = !bookmark.isExpanded;
  }
}

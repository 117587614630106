import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { MapService } from '../../services/map/map.service';
import { SharedService } from "../../services/shared/shared.service";
import { UserService } from "../../services/user/user.service";
import { Observable ,  Subscription } from "rxjs";

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})

export class MapComponent implements OnInit, OnDestroy {
  isLoggedIn: Observable<boolean>;
  showBookmark: boolean;
  isGroupBookmark: Observable<boolean>;
  @ViewChild('map') mapEl: ElementRef;
  subscription: Subscription;
  map: any;
  isMeasurementToolbar: boolean = false;
  isGeometryCalculator: boolean = false;

  constructor(private mapService: MapService,
    private sharedService: SharedService,
    private userService: UserService, private viewContainerRef: ViewContainerRef) {

    this.isLoggedIn = userService.authNavStatus$;
    this.isGroupBookmark = sharedService.getShowGroupBookmark();
  }

  ngOnInit() {

    this.subscription = this.sharedService.getShowBookmark().subscribe(result => {
      this.showBookmark = result;
    })

    if (this.map) {
      // map is already initialized
      return;
    }
    // get the required esri classes from the route
    //const esriModules = this.route.snapshot.data['esriModules'];
    this.mapService._createMap(this.viewContainerRef);
    //this.toggleMap.InitToggleMap();

    this.subscription = this.sharedService.getStartedDrawing().subscribe(value => {
      this.isMeasurementToolbar = value;
    });

    this.subscription.add(
      this.sharedService.isGeometryCalculator$.subscribe(value => {
        this.isGeometryCalculator = value;
      })
    );
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    
  }

}


import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-geometrycalculatorbtn',
  //standalone: true,
  //imports: [],
  templateUrl: './geometrycalculatorbtn.component.html',
  styleUrl: './geometrycalculatorbtn.component.css'
})
export class GeometrycalculatorbtnComponent implements OnInit {
  isGeometryCalculator: boolean = false;
  constructor(private sharedService: SharedService, private router: Router,) {

  }

  ngOnInit(): void {
    this.sharedService.mapView.ui.add("calculate-area", "bottom-right");
    this.sharedService.isGeometryCalculator$.subscribe(value => {
      this.isGeometryCalculator = value;
    });
  }

  startCalculate() {
    this.sharedService.setIsGeometryCalculator(true);
    // this.router.navigate(['./', { outlets: { 'modalrouter': null } }], { skipLocationChange: true }).then(() => {
    //   this.router.navigate(['./', { outlets: { modalrouter: ['geometrycalculate'] } }], { skipLocationChange: true });
    // });
  }
  
}

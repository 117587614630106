<div class="filter-grid-container">
  <div class="content-overflow">
    <div class="flex-col content-padding">
      <dx-validation-group #validationFilterData>
        <dx-text-box [(value)]="filterEdit.name" label="Назва фільтру" [disabled]="!isLoadData">
          <dx-validator>
            <dxi-validation-rule type="required" message="Назва фільтру обов'язкова"></dxi-validation-rule>
          </dx-validator>
        </dx-text-box>
      </dx-validation-group>

      <dx-select-box #listBox [dataSource]="layerDataSource"
          [disabled]="!isLoadData"
          displayExpr="name"
          valueExpr="id"
          placeholder=""
          label="Додати шар до фільтру"
          (onValueChanged)="onValueChangedLayer($event)"
          noDataText="Немає даних">
      </dx-select-box>

      <section *ngFor="let layer of filterEdit.layers" class="card yellow content-padding flex-col">
        <div class="flex-row align-items-center space-between">
          <p class="card-title">{{layer.name}}</p>
          <dx-button icon="close" hint="Видалити" stylingMode="text" (click)="removeLayerFilter(layer)"></dx-button>
        </div>
        <hr>
        <dx-filter-builder #filterbuilder [fields]="layer.fields"
                            [disabled]="layer.fields?.length==0 || !isLoadData"
                            [(value)]="layer.textFilterData"
                            [groupOperations]="groupOperations"
                            [groupOperationDescriptions]="groupOperationDescriptions"
                            [customOperations]="customOperations"
                            (onInitialized)="updateTexts($event, layer.id)"
                            (onValueChanged)="onValueChanged($event, layer)"
                            (onOptionChanged)="onOptionChanged($event, layer)"
                            (onEditorPreparing)="onEditorPreparing($event)"
                            maxGroupLevel="1">
          <dx-tag-box *dxTemplate="let condition of 'tagBoxTemplate'"
                      [value]="condition.value"
                      [dataSource]="datasourceDomains[layer.layerDataID][condition.field.dataField]"
                      displayExpr="name"
                      valueExpr="id"
                      width="100%"
                      (onValueChanged)="tagBoxChanged($event, condition)"
                      [searchEnabled]="true"
                      [searchExpr]="['name']"
                      >
          </dx-tag-box>
          <dx-select-box *dxTemplate="let condition of 'listTemplate'"
                          [dataSource]="datasourceTst['adminpodil']['21']"
                          displayExpr="name"
                          valueExpr="id"
                          [value]="condition.value"
                          placeholder="Вибрати..."
                          (onValueChanged)="listBoxChanged($event, condition)"
                          noDataText="Немає даних">
          </dx-select-box>
          <dx-tag-box *dxTemplate="let condition of 'uniqueValuesTemplate'"
                      [value]="condition.value"
                      width="100%"
                      [dataSource]="datasourceTst[layer.layerDataID][condition.field.dataField]" displayExpr="name" valueExpr="id"
                      (onValueChanged)="tagBoxChanged($event, condition)"
                      [searchEnabled]="true"
                      [searchExpr]="['name']"
                      >
          </dx-tag-box>
        </dx-filter-builder>
      </section>

      <section *ngFor="let extentFilter of filterEdit.extents" class="card content-padding">
        <div class="flex-row align-items-center space-between">
          <p class="card-title">{{extentFilter.filterExtentType}}</p>
          <dx-button icon="close" hint="Видалити" stylingMode="text" (click)="removeExtentFilter(extentFilter)"></dx-button>
        </div>
      </section>

    </div>
  </div>
  <hr>
  <footer class="flex-row flex-end content-padding wrap">

    <dx-button
      stylingMode="text"
      text="Скасувати"
      type="normal"
      height="48"
      (onClick)="goBack()">
    </dx-button>

    <dx-button *ngIf="filterEdit.isSelected"
      stylingMode="text"
      text="Вимкнути"
      type="normal"
      height="48"
      [disabled]="!isLoadData"
      (onClick)="unselectFilter()">
    </dx-button>

    <dx-button
      stylingMode="text"
      [text]="filterEdit.isSelected ? 'Застосувати' : 'Увімкнути'"
      type="success"
      height="48"
      [disabled]="!isLoadData"
      (onClick)="apply()">
    </dx-button>

    <dx-button *ngIf="!filterEdit.isSelected"
      stylingMode="text"
      text="Зберегти"
      type="default"
      height="48"
      [disabled]="!isLoadData"
      (onClick)="save()">
    </dx-button>

  </footer>
</div>

import { Injectable } from "@angular/core";
import { EsriService } from "../esri/js-esri.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { SharedService } from "../shared/shared.service";
import { LayerServerService } from "../layers/layer-server.service";
import { MapConfig } from "../../models/map/map-config";
import { SquareUnits } from "../../models/draw/square-units.model";
import notify from "devextreme/ui/notify";

@Injectable()
export class GeometryCalculatorService {

  sketchViewModel: __esri.SketchViewModel;
  selectedGraphicLayer: __esri.GraphicsLayer;
  sketchSelectGraphicLayer: __esri.GraphicsLayer;
  calculateUnit: SquareUnits = SquareUnits.meter;
  private _clickHandler: IHandle | null = null;
  private _createSketchViewHandler: IHandle | null = null;
  private areaSubject: Subject<CalcAreaResult> = new Subject<CalcAreaResult>();
  private isSelectAreaSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private esriService: EsriService, private sharedService: SharedService, private layerServerService: LayerServerService) {

  }

  onInit() {    
    let _self = this;
    this.calculateUnit = SquareUnits.meter;
    this.selectedGraphicLayer = new this.esriService.GraphicsLayer({
      id: 'selectedLayer'
    })
    this.sharedService.map.layers.add(this.selectedGraphicLayer);
    this.sketchSelectGraphicLayer = new this.esriService.GraphicsLayer({
      id: 'sketchSelectGraphic'
    })
    this.sharedService.map.layers.add(this.sketchSelectGraphicLayer);

    this.sketchViewModel= new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: false,
      layer: this.sketchSelectGraphicLayer,
      pointSymbol: MapConfig.drawPointSymbol,
      polylineSymbol: MapConfig.drawPolylineSymbol,
      polygonSymbol: MapConfig.drawPolygonSymbol
    })

    let mapView = this.sharedService.mapView;   
    this._clickHandler = mapView.on("click", function (event) {
      //console.log("map clicked");
      mapView.hitTest(event).then(async (response) => {
        if (response.results.length && response.results.find(x => x.graphic.layer === _self.selectedGraphicLayer)) {
          
          response.results.forEach((result) => {
            if (result.graphic.layer === _self.selectedGraphicLayer)
            {
            _self.selectedGraphicLayer.graphics.remove(result.graphic);
            }
          });
          _self.calculateTotalArea();
        } else {
          const mapPoint = event.mapPoint;

          const bufferSizeInPixels = 3;

          const pixelSize = mapView.extent.width / mapView.width;
          const bufferSizeInMeters = bufferSizeInPixels * pixelSize;

          const buffer = _self.esriService.GeometryEngine.buffer(mapPoint, bufferSizeInMeters, "meters");
          let isChanged = await _self.selectFeatures(buffer);
          if (isChanged) {
            _self.calculateTotalArea();
          }
        }
      });
      
    })

    this._createSketchViewHandler = this.sketchViewModel.on("create", async function (event) {
      if (event.state === "complete") {
        let polygon = event.graphic.geometry;
        _self.sketchSelectGraphicLayer.removeAll();
        _self.isSelectAreaSubject.next(false);
        let isChanged = await _self.selectFeatures(polygon);
        if (isChanged) {
          _self.calculateTotalArea();
        }
      }

      if (event.state == "cancel") {
        _self.isSelectAreaSubject.next(false);
      }
    });
  }

  selectArea() {
    this.isSelectAreaSubject.next(true);
    this.sketchViewModel.create("polygon", { mode: "freehand" }); // hybrid, freehand, click
  }
  unselectAreaTool() {
    if (this.isSelectAreaSubject.getValue()) {
      this.sketchViewModel.cancel();
    }    
  }
  async selectAll() {
    this.unselectAreaTool();
    let isChanged = await this.selectFeatures(null);
    if (isChanged) {
      this.calculateTotalArea();
    }
  }

  unselectAll() {
    this.selectedGraphicLayer.graphics.removeAll();
    let result: CalcAreaResult = {
      totalArea: 0,
      totalLength: 0,
      amount: 0,
      unit: 'm'
    }

    this.updateArea(result);
  }

  async selectFeatures(extent: __esri.Geometry) {
    let isChanged = false;
    this.reorderGraphicLayer();
    let mapViewLayers = this.currentMapViewLayers();
    for (let layer of mapViewLayers) {
      let layerUrl = layer.get("layerId") != undefined ? layer.get<string>("url") : (layer as __esri.Sublayer).get<string>("parent.url");
      let layerId = layer.get("layerId") != undefined ? layer.get("layerId") : layer.get("id");
      let expression = layer.get("definitionExpression") && layer.get("definitionExpression") != '' ? layer.get("definitionExpression") : null;
      let layerInfo = await this.layerServerService.getLayerInfo(`${layerUrl}/${layerId}`);
      if (layerInfo?.type == 'Raster Layer') {
        continue;
      }
      let features = await this.getFeaturesByExtent(layerUrl, layerId, extent, expression);
      
      let isadded = this.createGraphics(features, this.layerServerService.serverTypeToEsriType(layerInfo.geometryType), layerUrl, layerId);
      if (!isChanged && isadded) {
        isChanged = true;
      }
    }
    return isChanged;
  }

  async getFeaturesByExtent(url: string, layerId: any, extent: __esri.Geometry, expression = null): Promise<any> {
    var params = {
      f: "json",
      geometry: extent ? JSON.stringify(extent.toJSON()) : '',
      geometryType: "esriGeometryPolygon",// "esriGeometryPoint",// "esriGeometryPolygon",
      spatialRel: "esriSpatialRelIntersects",
      where: expression ??"1=1",
      outFields: "OBJECTID",
      returnGeometry: true
    };
    let layerUrl = `${url}/${layerId}/query` 
    return await this.esriService.Request(layerUrl, {
      query: params,
      responseType: "json"
    }).then(function (response) {
      let features = response.data.features;
      return features;      
    }).catch(function (error) {      
      console.error("Error querying features:", error);
      notify(`Помилка завантаження даних - ${error}`);
      return [];
    });
  }


  public async calculateTotalArea() {
    let graphics = this.selectedGraphicLayer.graphics as any;

    const groupedObjects = graphics.reduce((result, current) => {
      const { layerName, layerId, objectid } = { layerName: current.selectedLayerUrl, layerId: current.layerId, objectid: current.attributes.OBJECTID };

      if (!result[layerName]) {
        result[layerName] = [];
      }

      let layerGroup = result[layerName].find(layer => layer.layerId === layerId);

      if (!layerGroup) {
        layerGroup = { layerId, objectIds: [] };
        result[layerName].push(layerGroup);
      }

      layerGroup.objectIds.push(objectid);
      return result;
    }, {} as { [key: string]: any[] });

    const groupLayers: any[] = Object.entries(groupedObjects).map(([layerName, layers]) => ({
      layerName,
      layers
    }));

    let result: CalcAreaResult = {
      totalArea: 0,
      totalLength: 0,
      amount: 0,
      unit: ''
    }
    for (const grp of groupLayers) {
      for (const layer of grp.layers) {
        let hasSOE = await this.hasAreaLengthCalculatorSOE(grp.layerName);
        let calcRes = hasSOE ?
          await this.calculateGeometryServ(grp.layerName, layer.layerId, `OBJECTID in (${layer.objectIds.toString()})`)
          : this.calculateFeaturesGeometry(this.selectedGraphicLayer.graphics.filter(f => f.get("selectedLayerUrl") == grp.layerName && f.get("layerId") == layer.layerId && layer.objectIds.find(attr => attr == f.attributes["OBJECTID"])).toArray())
        if (calcRes) {
          result.totalArea += calcRes.totalArea;
          result.totalLength += calcRes.totalLength;
          result.amount += calcRes.amount;
        }
      }      
    }
    this.updateArea(result);
  }

  async hasAreaLengthCalculatorSOE(layerUrl: string) {
    let mapLayerUrl = layerUrl.replace("FeatureServer", "MapServer");
    let layerInfo = await this.layerServerService.getLayerInfo(mapLayerUrl);
    return layerInfo.supportedExtensions?.includes("AreaLengthCalcSOE")
  }

  calculateFeaturesGeometry(features: any[]) {
    let isPolygon = false;
    let isPolyline = false;
    let totalArea = 0;
    let totalLength = 0;
    let totalAmount = 0;
    for (const graphic of features) {
      //let path: any[] = (graphic.geometry as __esri.Polygon).rings[0];
      //let allPath = Object.assign([], path);
      ////allPath.splice((path.length - 1), 1);
      //let _polyline = new this.esriService.Polyline({
      //  spatialReference: this.sharedService.mapView.spatialReference,
      //  hasZ: false,
      //  paths: allPath
      //});
      //var distance = this.esriService.GeometryEngine.geodesicLength(_polyline, "meters");
      //if (distance < 0) {
      //  // simplify the polygon if needed and calculate the area again
      //  var simplifiedPolygon = this.esriService.GeometryEngine.simplify(_polyline);
      //  if (simplifiedPolygon) {
      //    distance = this.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
      //  }
      //}
      isPolygon = graphic.geometry?.type == 'polygon';
      isPolyline = graphic.geometry?.type == 'polyline';
      if (isPolygon || isPolyline) {
        totalAmount += 1;
      }      
      if (isPolygon) {
        let area = this.esriService.GeometryEngine.geodesicArea(graphic.geometry, this.calculateUnit);
        if (area < 0) {
          // simplify the polygon if needed and calculate the area again
          let simplifiedPolygon = this.esriService.GeometryEngine.simplify(graphic.geometry);
          if (simplifiedPolygon) {
            area = this.esriService.GeometryEngine.geodesicArea(simplifiedPolygon, this.calculateUnit);
          }
        }
        totalArea += area;
      }

      if (isPolyline) {
        let distance = this.esriService.GeometryEngine.geodesicLength(graphic.geometry, "meters");
        if (distance < 0) {
          // simplify the polygon if needed and calculate the area again
          var simplifiedPolygon = this.esriService.GeometryEngine.simplify(graphic.geometry);
          if (simplifiedPolygon) {
            distance = this.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
          }
        }
        totalLength += distance;
      }
    }
    totalLength = this.calculateUnit == SquareUnits.hectares ? totalLength / 1000 : totalLength;

    let result: CalcAreaResult = {
      totalArea: totalArea,
      totalLength: totalLength,
      amount: totalAmount,
      unit : 'm'
    }

    return result;
    
  }

  public calculateGeometryServ(layerURL, layerId, expression, extent?) {
    let mapLayerUrl = layerURL.replace("FeatureServer", "MapServer");
    let url = `${mapLayerUrl}/exts/AreaLengthCalcSOE/calcArea`;

    return this.esriService.Request(url, {
      query: {
        f: "json",
        layerId: layerId,
        extent: JSON.stringify(extent),
        expression: expression,
        unit: this.calculateUnit
      },
      method: 'post',
      responseType: "json"
    }).then((response: any) => {
      return {
        totalArea: response.data.totalArea,
        totalLength: response.data.totalLength,
        amount: response.data.amount,
        unit: response.data.unit
      } as CalcAreaResult;
    });
  }

  onDestroy(): void {
    this._createSketchViewHandler?.remove();
    this.sketchViewModel?.destroy();
    this._clickHandler?.remove();
    this._clickHandler = null;
    this.sharedService.map.layers.remove(this.selectedGraphicLayer);
    this.sharedService.map.layers.remove(this.sketchSelectGraphicLayer);
  }

  private createGraphics(features: any[], geometryType, url, layerId) {
    let isAdded = false;
    for (const feature of features) {
      let existGraphic = this.selectedGraphicLayer.graphics.find(x => x.get("selectedLayerUrl") == url
        && x.get("layerId") == layerId
        && x.attributes["OBJECTID"] == feature.attributes["OBJECTID"]);
      if (existGraphic) {
        continue;
      }

      feature.geometry.type = geometryType;
      feature.geometry = this.esriService.webMercatorUtils.webMercatorToGeographic(feature.geometry);
      feature.geometry = this.esriService.Projection.project(feature.geometry, MapConfig.spatialReferenceWGS);
      let _symbol = new this.esriService.SimpleFillSymbol();
      switch (geometryType) {
        case "point":
          _symbol = MapConfig.popupPointSymbol;
          break;
        case "multipoint":
          _symbol = MapConfig.popupMultiPointSymbol;
          break;
        case "polyline":
          _symbol = MapConfig.popupPolylineSymbol;
          break;
        case "polygon": case "extent": default:
          _symbol = MapConfig.popupPolygonSymbol;
          break;
      }
      let graphic: __esri.Graphic = new this.esriService.Graphic({
        geometry: feature.geometry,
        attributes: feature.attributes,
        symbol: _symbol,
      });
      graphic.set("selectedLayerUrl", url);
      graphic.set("layerId", layerId);
      this.selectedGraphicLayer.add(graphic);
      isAdded = true;
    }
    return isAdded;
  }

  calculatedArea(): Observable<CalcAreaResult> {
    return this.areaSubject.asObservable();
  }

  private updateArea(newArea: CalcAreaResult) {
    this.areaSubject.next(newArea);
  }

  isSelectAreaTool(): Observable<boolean> {
    return this.isSelectAreaSubject.asObservable();
  }

  private currentMapViewLayers() {
    let layers = this.sharedService.mapView.allLayerViews.filter(x => x.layer.get<any>("LayerDataGUID")
      || (x.layer as __esri.MapImageLayer).sublayers?.some(l => l.get<any>("LayerDataGUID"))
      ).map(result => {
        if ((result.layer as __esri.MapImageLayer).sublayers?.length > 0) {
          return (result.layer as __esri.MapImageLayer).sublayers.toArray();
        } else {
          return result.layer;
        }
      }).toArray();
    return layers.flat();
  }

  private reorderGraphicLayer() {
    let map = this.sharedService.map as __esri.Map;
    const layerIndex = map.layers.indexOf(this.selectedGraphicLayer);
    if (layerIndex !== map.layers.length - 1) {
      map.reorder(this.selectedGraphicLayer, map.layers.length - 1);
    }
  }
}

export class CalcAreaResult {
  totalArea: number;
  totalLength: number;
  amount: number;
  unit: any;
}

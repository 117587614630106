import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from '../../services/shared/shared.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { EsriService } from '../../services/esri/js-esri.service';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { LegendItem } from '../../models/layers/legend.model';
import { UserService } from '../../services/user/user.service';
import { Subscription } from 'rxjs';
import { LayerServerService } from '../../services/layers/layer-server.service';
import { MapConfig } from '../../models/map/map-config';

@Component({
  selector: 'app-navigation-list',
  templateUrl: './navigation-list.component.html',
  styleUrls: ['./navigation-list.component.scss']
})
export class NavigationListComponent implements OnInit, OnDestroy {

  layerID: string;
  subLayerID: string;
  subTypeID: string;
  layerDataGuid: string;
  layerTitle = 'Пошук';
  features: any[] = [];
  featureLayer: __esri.FeatureLayer;
  graphicsLayer: __esri.GraphicsLayer;
  subLayer: __esri.Sublayer | __esri.FeatureLayer;
  legend: LegendItem;
  backUrl: any;
  fields: any;
  subscription: Subscription;
  isLoading: boolean = false;
  isNeedRefresh: boolean = false;
  needRefreshItem: any;
  subscriptionItem: Subscription;
  legendGUID: any;

  constructor(private activatedRoute: ActivatedRoute, private sharedService: SharedService,
    private navigationService: NavigationService, private esriService: EsriService, private router: Router,
    private userService: UserService, private layerServerService: LayerServerService) {

    this.subscription = this.sharedService.getRefreshNavigationList().subscribe(result => {
      if (result) {
        if (this.graphicsLayer) {
          this.graphicsLayer.removeAll();
        }
        this.getNavigationList();

      }
    });

    this.subscriptionItem = this.sharedService.getRefreshNavigationItem().subscribe(result => {
      if (result) {
        let item = this.features.find(f => f.geometry.attributes["OBJECTID"] == result.attributes["OBJECTID"]);
        if (item) {
          item.geometry.attributes = result.attributes;
          let attributes = this.subLayer.get<LayerDataAttribute[]>("layerAttributes") as LayerDataAttribute[]
          item.name = this.getFieldValue(this.fields, attributes, item.geometry);
        }
      }
    })

  }

  ngOnInit() {
    this.graphicsLayer = new this.esriService.GraphicsLayer({
      id : "navigationLayerID"
    });
    this.sharedService.map.add(this.graphicsLayer);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.backUrl = this.activatedRoute.snapshot.params['backUrl'] || '/';
      this.legendGUID = params['legendguid'];
      this.layerID = params['layerid'];
      this.subLayerID = params['sublayerid'] ? params['sublayerid'] : this.sharedService.publicLegends.find(x => x.legendGUID == params['legendguid'])?.id;
      this.subTypeID = params['subtypeid'];
      this.layerDataGuid = params["layerdataguid"];
      this.getNavigationList();
    });
  }

  private getNavigationList() {
    if (this.legendGUID) {
      let legend = this.sharedService.publicLegends.find(l => l.legendGUID == this.legendGUID);
      return this.navigationService.getNavigationListByLegend(legend).then(result => {
        this.featureLayer = result.featureLayer;
        this.subLayer = result.subLayer;
        this.legend = result.legend;
        this.layerTitle = this.legend.name;
        this.fields = result.featureSet.fields;
        let features = [];
        result.featureSet.features.forEach(x => {
          let item =
          {
            name: this.getFieldValue(result.featureSet.fields, x.attributes, x),
            geometry: x,
            isSelected: false
          }
          features.push(item);
        })
        this.features = features;

        this.checkUserAccess();
        return result;
      }).catch(function (error) {
        console.log('error - ', error);
        return;
      })
    }

    //return this.navigationService.getNavigationList(this.subLayerID, this.layerID, this.layerDataGuid, this.subTypeID).then(result => {
    //  this.featureLayer = result.featureLayer;
    //  this.subLayer = result.subLayer;
    //  this.legend = result.legend;
    //  this.layerTitle = this.legend.name;
    //  this.fields = result.featureSet.fields;
    //  let features = [];
    //  result.featureSet.features.forEach(x => {
    //    let item =
    //    {
    //      name: this.getFieldValue(result.featureSet.fields, x.attributes, x),
    //      geometry: x,
    //      isSelected: false
    //    }
    //    features.push(item);
    //  })
    //  this.features = features;

    //  this.checkUserAccess();
    //  return result;
    //}).catch(function (error) {
    //  console.log('error - ', error);      
    //})
  }

  private getFieldValue(fields: __esri.Field[], attributes: any[], _feauture) {
    let result = '';
    attributes = this.subLayer.get<LayerDataAttribute[]>("layerAttributes") as LayerDataAttribute[];
    attributes.forEach((x: LayerDataAttribute) => {
      if (x.inMini) {
        let field = fields.find(f => f.name == x.name)
        let _value = '';
        if (field.domain) {
          let values: any[] = field.domain.get('codedValues');
          let domainValue = values.map(val => {
            return { id: val.code, name: val.name };
          }).find(f => f.id == _feauture.attributes[x.name]);
          _value = domainValue ? domainValue?.name : '';
        } else
        if (x.subTypeFilters && x.subTypeFilters.length>0) { 
          let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";

          let typeValue = x.subTypeFilters.find(f => f.subTypeID == _id); 
          if (typeValue) {
            _value = typeValue.subTypeValue;
          }
        } else if (this.featureLayer.typeIdField == x.name) {
          let _types = this.featureLayer.types;
          if (_types) {
            let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
            let typeValue = _types.find(t => t.id == _id);
            if (typeValue) {
              _value = typeValue.name;
            }
          }
          
        }
        else {
          _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
        }

        result += ` ${field.alias} : ${_value}`;
      }

    })
    return result;
  }

  goTo(item) {
    
    if (this.isLoading) {
      this.needRefreshItem = item;
      this.isNeedRefresh = true;
      return;
    }
    this.isNeedRefresh = false;
    this.isLoading = true;
    this.needRefreshItem = null;
    
    let opts = {
      duration: 500
    };
    if (this.sharedService.mapView.popup.visible) {
      this.sharedService.mapView.popup.close();
    }
    this.graphicsLayer.removeAll();
    let selectedItem = this.features.find(f => f.isSelected);
    if (selectedItem) {
      selectedItem.isSelected = false;
    }
    item.isSelected = true;
    var query = {
      //distance: distance,
      //spatialRelationship: "esriSpatialRelIntersects",
      outFields: this.navigationService.getAttributes(this.subLayer.get<any>("layerAttributes")).toString(),
      returnGeometry: true,
      f: "json",
      where: "OBJECTID=" + item.geometry.attributes["OBJECTID"]
    };
    let _self = this;
    var queryUrl = `${this.featureLayer.url}/${this.featureLayer.layerId}/query`;
    this.esriService.Request(queryUrl, {
      query: query,
      responseType: "json"
    }).then(function (response) {
      let result = response.data;
      let feature = result.features[0];
      feature.geometry.type = _self.layerServerService.serverTypeToEsriType(result.geometryType);
      feature.geometry = _self.esriService.webMercatorUtils.webMercatorToGeographic(feature.geometry);
      feature.geometry = _self.esriService.Projection.project(feature.geometry, MapConfig.spatialReferenceWGS);
      let _symbol = new _self.esriService.SimpleFillSymbol();
      switch (_self.layerServerService.serverTypeToEsriType(result.geometryType)) {
        case "point":
          _symbol = MapConfig.popupPointSymbol;
          break;
        case "multipoint":
          _symbol = MapConfig.popupMultiPointSymbol;
          break;
        case "polyline":
          _symbol = MapConfig.popupPolylineSymbol;
          break;
        case "polygon": case "extent": default:
          _symbol = MapConfig.popupPolygonSymbol;
          break;
      }
      let graphic: __esri.Graphic = new _self.esriService.Graphic({
        geometry: feature.geometry,
        attributes: feature.attributes,
        symbol: _symbol,
        popupTemplate: {
          title: "Пошук - " + (_self.subLayer as any).name,
          content: '',
          actions: MapConfig.popupActions
        }
      });

      _self.graphicsLayer.add(graphic);

      let _return = {
        graphic: graphic,
        feature: feature,
        fields: result.fields
      }      
      return _return;
    }).then(result => {
      let zoom = this.sharedService.mapView.zoom;
      
      this.sharedService.mapView.goTo({ target: result.graphic, zoom: zoom }, opts).then(() => {
        if (_self.isNeedRefresh) {
          _self.isNeedRefresh = false;
          _self.isLoading = false;
          _self.goTo(_self.needRefreshItem);
          _self.needRefreshItem = null;
          return;
        }
        let content = _self.GetPopupContentFeaure(result.feature, result.fields);
        result.graphic.popupTemplate.content = content;
        _self.sharedService.mapView.popup.open({
          title: "Пошук - " + (this.subLayer as any).name,
          features: [result.graphic],
          //content: content
        });
        _self.isLoading = false;
      }).catch(function (error) {
        _self.isLoading = false;
        _self.isNeedRefresh = false;
        _self.needRefreshItem = null;
        console.log("goTo error message: ", error);
      })
      
    }).catch(function (error) {
      _self.isLoading = false;
      _self.isNeedRefresh = false;
      _self.needRefreshItem = null;
      console.log("goTo error message: ", error);
    }) 
  }

  private GetPopupContentFeaure(feature, fields) {
    let result = '*';
    let _feauture = feature;// as __esri.Graphic; 
    let attributes: LayerDataAttribute[] = this.subLayer.get<any>("layerAttributes");  
    //let fields = (_feauture.sourceLayer as __esri.FeatureLayer).fields;
    let sourceLayer = this.featureLayer;//(_feauture.sourceLayer as __esri.FeatureLayer);
    result = '<ul class="esri-popup__list">';

    result += `<span style="display:none">OBJECTID : {OBJECTID}</span>`;
    attributes.forEach(x => {
      if (x.inMini) {
        let field = fields.find(f => f.name == x.name)
        let _value = '';
        if (field.domain) {
          let values: any[] = field.domain.get('codedValues');
          let domainValue = values.map(val => {
            return { id: val.code, name: val.name };
          }).find(f => f.id == _feauture.attributes[x.name]);
          _value = domainValue ? domainValue?.name : '';
        } else
        if (sourceLayer.typeIdField && sourceLayer.typeIdField == x.name) {
          let _id = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let typeValue = sourceLayer.types.find(t => t.id == _id);
          if (typeValue) {
            _value = typeValue.name;
          }
        } else {
          _value = _feauture.attributes[x.name] ? _feauture.attributes[x.name] : "";
          let url;
          let isUrl: boolean = false;
          try {
            url = new URL(_value);
            isUrl = url.protocol === "http:" || url.protocol === "https:";
          } catch (_) {
            isUrl = false;
          }
          if (isUrl) {
            _value = `<a target='blank' href='${_value}'>Більше...</a>`
          }
        }

        result += `<li><span class=esri-popup__text-secondary>${field.alias}</span> : <span class="esri-popup__text-primary">${_value}</span></li>`;
      }

    })
    result += '</ul>';
    return result;
  }

  goToLayers() {
    
    let url = this.backUrl ? this.backUrl : '/legend';
    if (this.sharedService.publicGroup) {
      this.router.navigate([url], { queryParams: { group: '1' } });
    } else {
      this.router.navigate([url]);
    }
    
  }

  ngOnDestroy() {
    (this.sharedService.map as __esri.Map).layers.remove(this.graphicsLayer);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionItem) {
      this.subscriptionItem.unsubscribe();
    }
  }

  private checkUserAccess() {
    if (this.userService.User) {
      this.graphicsLayer.set<boolean>("isNavigationLayer", true);
      this.graphicsLayer.set<string>("LayerDataGUID", this.subLayer.get<any>("LayerDataGUID"));
      this.graphicsLayer.set<string>("name", this.legend.name); // isSearchLayer
      this.graphicsLayer.set<any>("fields", this.fields);
      let attributes: LayerDataAttribute[] = this.subLayer.get<any>("layerAttributes");
      let canAddObject = this.subLayer.get<boolean>("canAddObject");
      if (canAddObject) {
        this.graphicsLayer.set<boolean>("canAddObject", true);
      }
      let editable = attributes.find(x => x.editable);
      if (editable)
        this.graphicsLayer.set<boolean>("editable", true);
      else
        this.graphicsLayer.set<boolean>("editable", false);

      let showed = attributes.find(x => x.showed);

      if (showed) {
        this.graphicsLayer.set<boolean>("showed", true);
      } else {
        this.graphicsLayer.set<boolean>("showed", false);
      }
      this.graphicsLayer.set<string>("subLayerUrl", (this.subLayer as __esri.FeatureLayer)?.type == "feature" ?
        (this.subLayer as __esri.FeatureLayer).url : (this.subLayer as __esri.Sublayer).layer?.url);

      this.graphicsLayer.set<any>("layerId", this.subLayerID);
      this.graphicsLayer.set<any>("layerAttributes", attributes);
      this.graphicsLayer.set<any>("typeIdField", this.featureLayer.typeIdField);
      this.graphicsLayer.set<any>("types", this.featureLayer.types);
    }
  }

}


import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {switchMap} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParamsOptions, HttpResponse, HttpParams } from '@angular/common/http';





import { ConfigService } from './utils/config.service';
import { UserService } from '../user/user.service';
import notify from 'devextreme/ui/notify';


@Injectable()
export class EndpointFactory {

  static readonly apiVersion: string = "1";

  private readonly _loginUrl: string = "/connect/token";

  private get loginUrl() { return this.configService.getApiURI() + this._loginUrl; }



  private taskPauser: Subject<any>;
  private isRefreshingLogin: boolean;


  constructor(protected http: HttpClient, protected configService: ConfigService, private injector: Injector, private userService: UserService) {

  }


  protected getAuthHeader(includeJsonContentType?: boolean, params?: HttpParams): {} {



    let authToken = localStorage.getItem('auth_token');
    let headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}` });
    if (includeJsonContentType) {
      headers = new HttpHeaders({ 'Authorization': `Bearer ${authToken}`, "Content-Type" : "application/json" });
    }
    
    if (params) {
      const httpOptions = {
        headers: headers,
        params: params
      }

      return httpOptions;
    } else {
      const httpOptions = {
        headers: headers 
      }

      return httpOptions;
    }

    
  }



  protected handleError(error, continuation: () => Observable<any>) {
    console.log('handleError- ')
    if (error.status == 401) {
      this.userService.logout();
      notify("Сессія закінчилась", "info");
      return;
      if (this.isRefreshingLogin) {
        return this.pauseTask(continuation);
      }

      this.isRefreshingLogin = true;

      return this.refreshLogin()
        .mergeMap(data => {
          this.isRefreshingLogin = false;
          this.resumeTasks(true);

          return continuation();
        })
        .catch(refreshLoginError => {
          this.isRefreshingLogin = false;
          this.resumeTasks(false);

          if (refreshLoginError.status == 401 || (refreshLoginError.url && refreshLoginError.url.toLowerCase().includes(this.loginUrl.toLowerCase()))) {
            //this.authService.reLogin();
            return observableThrowError('session expired');
          }
          else {
            return observableThrowError(refreshLoginError || 'server error');
          }
        });
    }

    if (error.url && error.url.toLowerCase().includes(this.loginUrl.toLowerCase())) {
      //this.authService.reLogin();
      return observableThrowError('session expired');
    }
    else {
      return observableThrowError(error || 'server error');
    }
  }

  protected handlePromiseError(error, continuation: () => Promise<any>) {
    console.log('handleError- ')
    if (error.status == 401) {

      this.userService.logout();
      notify("Сессія закінчилась", "info");
      return;
      if (this.isRefreshingLogin) {
        return this.pauseTaskPromise(continuation);
      }

      this.isRefreshingLogin = true;

      return this.refreshLogin()
        .mergeMap(data => {
          this.isRefreshingLogin = false;
          this.resumeTasks(true);

          return continuation();
        })
        .catch(refreshLoginError => {
          this.isRefreshingLogin = false;
          this.resumeTasks(false);

          if (refreshLoginError.status == 401 || (refreshLoginError.url && refreshLoginError.url.toLowerCase().includes(this.loginUrl.toLowerCase()))) {
            //this.authService.reLogin();
            return observableThrowError('session expired');
          }
          else {
            return observableThrowError(refreshLoginError || 'server error');
          }
        });
    }

    if (error.url && error.url.toLowerCase().includes(this.loginUrl.toLowerCase())) {
      //this.authService.reLogin();
      return observableThrowError('session expired');
    }
    else {
      return observableThrowError(error || 'server error');
    }
  }

  private pauseTask(continuation: () => Observable<any>) {
    if (!this.taskPauser)
      this.taskPauser = new Subject();

    return this.taskPauser.pipe(switchMap(continueOp => {
      return continueOp ? continuation() : observableThrowError('session expired');
    }));
  }

  private pauseTaskPromise(continuation: () => Promise<any>) {
    if (!this.taskPauser)
      this.taskPauser = new Subject();

    return this.taskPauser.pipe(switchMap(continueOp => {
      return continueOp ? continuation() : observableThrowError('session expired');
    }));
  }

  private resumeTasks(continueOp: boolean) {
    setTimeout(() => {
      if (this.taskPauser) {
        this.taskPauser.next(continueOp);
        this.taskPauser.complete();
        this.taskPauser = null;
      }
    });
  }


  refreshLogin() {
    return null;
  }
}

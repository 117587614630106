import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { OverviewmapService } from "../overviewmap/overviewmap.service";
import { ConfigService } from "../shared/utils/config.service";
import { EsriService } from "../esri/js-esri.service";
import { ImageServiceEndpoint } from "./image-service-endpoint.service";
import { ImageService } from "../../models/imageservice/image-service.model";

@Injectable()
export class GalleryMapService {


  constructor(
    private sharedService: SharedService,
    private esriService: EsriService,
    //private overviewmapService: OverviewmapService,
    private configService: ConfigService,
    private imageServiceEndPoint: ImageServiceEndpoint) {
    
  }

  ImageServiceParameters: any;
  ImageryLayer: any;
  public galleryMap: any;

  getGalleryMap(): any {

    this._createBasemapGallery(this.esriService.BasemapGallery, this.esriService.Basemap);
  }

    _createBasemapGallery(BasemapGallery, Basemap) {
    if (this.galleryMap) {
      return;
    }
    var ImageryBasemap = Basemap.fromId("satellite");
    var GrayBasemap: __esri.Basemap = Basemap.fromId("gray-vector");
    var TopoBasemap = Basemap.fromId("topo-vector");
    const osmLayer = new this.esriService.OpenStreetLayer();
    var OpenStreetBasemap = 
      new Basemap({
        baseLayers: [osmLayer],
      title: "OpentStreet",
        thumbnailUrl: this.configService.WorldStreetThumbnailMapUrl
    })


    let localBasemapsSource: __esri.LocalBasemapsSource = new this.esriService.LocalBasemapsSource();
    localBasemapsSource.basemaps.addMany([OpenStreetBasemap, TopoBasemap, GrayBasemap, ImageryBasemap]); //  StreetsBasemap
    
    this.galleryMap = new BasemapGallery({
      showArcGISBasemaps: false,
      source: localBasemapsSource, // [Basemap.fromId("topo-vector"), Basemap.fromId("hybrid")],
      view: this.sharedService.mapView
    }, Constants.basemapId);

    var self = this;  

    this.galleryMap.watch("activeBasemap", function () {

      let selected = this.activeBasemap;

      let thumbnailUrl = selected.thumbnailUrl;
      self.sharedService.setThumbnailUrl(thumbnailUrl);
    });
  }

  addPublicMap(Id: string) {
    if (this.galleryMap) {
      return this.imageServiceEndPoint.getImagePublicServiceByIdEndpoint(Id).then(result => {
        return <ImageService>result
      }).then(result => {
        let _imageServices: ImageService = result;
        var ImageBasemap: __esri.Basemap = new this.esriService.Basemap({
          title: _imageServices.name,
          thumbnailUrl: "../../../assets/images/orthophoto.jpg"
        });
        _imageServices.details.forEach(serviceDetail => {
          let imageServiceLayer: any;
          if (serviceDetail.layers.length > 0) {
            let sublayers = serviceDetail.layers.map(x => {
              return { id: x.layerName }
            })
            imageServiceLayer = new this.esriService.MapImageLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
              sublayers: sublayers,
              disableClientCaching: false
            });
          } else {
            imageServiceLayer = new this.esriService.TileLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {              
              disableClientCaching: false
            });
          }
          ImageBasemap.baseLayers.add(imageServiceLayer);
          ImageBasemap.set<string>("ImageServiceGUID", _imageServices.id);
        });
        
          var _self = this;
          (this.galleryMap as __esri.BasemapGallery).when(function () {
              _self.galleryMap.source.basemaps.add(ImageBasemap);
              _self.galleryMap.source.refresh();
          })
      });
    }
  }
  async addUsersMaps(regionId: string): Promise<any> {

    if (this.galleryMap){

      if (regionId && regionId != "") {
          this.imageServiceEndPoint.getImageServiceByRegionEndpoint(regionId)
            .then(result => {
                let _imageServices: ImageService[] = result;
                let baseMaps: __esri.Basemap[] = [];
          _imageServices.forEach(_service => {
            var ImageBasemap: __esri.Basemap = new this.esriService.Basemap({
              title: _service.name,
              thumbnailUrl: "../../../assets/images/orthophoto.jpg"
            });

            _service.details.forEach(serviceDetail => {
              let imageServiceLayer: any;
              if (serviceDetail.layers.length > 0) {
                let sublayers = serviceDetail.layers.map(x => {
                  return { id: x.layerName }
                })
                imageServiceLayer = new this.esriService.MapImageLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
                  sublayers: sublayers,
                  disableClientCaching: false
                });
              } else {
                imageServiceLayer = new this.esriService.TileLayer(this.configService._baseUrlRegionServices + serviceDetail.serviceURL, {
                  disableClientCaching: false
                });
              }
              
              ImageBasemap.baseLayers.add(imageServiceLayer);
                ImageBasemap.set<string>("ImageServiceGUID", _service.id);
                baseMaps.push(ImageBasemap);
            });

          });
                var _self = this;
                (this.galleryMap as __esri.BasemapGallery).when(function () {
                    _self.galleryMap.source.basemaps.addMany(baseMaps);
                    _self.galleryMap.source.refresh();
                })
            });
      }
    }

  }
 
    clearBasemaps() {
    if (this.galleryMap) {
      let localStore = (this.galleryMap.source as __esri.LocalBasemapsSource);
      if (localStore.basemaps.length<=4) {
          return;
      }
      let _map = (this.galleryMap as __esri.BasemapGallery);
      let _id = _map.activeBasemap.id;

      let _needrefresh: boolean = false;
      while (localStore.basemaps.length > 4)
      {
        if (localStore.basemaps.getItemAt(4)?.id == _id) {
          _needrefresh = true;
        }
        localStore.basemaps.removeAt(4);
      }

      this.galleryMap.source.refresh();

      if (_needrefresh)
      {
        _map.activeBasemap = this.galleryMap.source.basemaps.items[0];
      }
    }
  }

  selectBaseMap(imageServiceID: string) {
    let localStore = (this.galleryMap.source as __esri.LocalBasemapsSource);
    let baseMap = localStore.basemaps.find(x => x.get<string>("ImageServiceGUID") == imageServiceID);
    if (baseMap) {
      let _map = (this.galleryMap as __esri.BasemapGallery);
      _map.activeBasemap = baseMap;
    }
  }

  getBaseMapImageServiceGUID() {
    let map = (this.galleryMap as __esri.BasemapGallery).activeBasemap;
    if (map.get<string>("ImageServiceGUID")) {
      return map.get<string>("ImageServiceGUID");
    } else {
      return null;
    }
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-coordwidget',
  templateUrl: './coordinates.component.html',
  styleUrls: ['./coordinates.component.scss']
})
export class CoordwidgetComponent implements AfterViewInit, OnDestroy {

  isStarted: boolean = true;
  isAddCoordinates: boolean = false;

  coordX: any;
  coordY: any;
  isPolyline: boolean = false;
  isPolygon: boolean = false;
  addedX: any;
  addedY: any;

  lengthLine: any;
  areaPolygon: any;

  _ref: any;
  @ViewChild('coordsWidgetID', { static: false }) coordsWidgetID: ElementRef<HTMLDivElement>;

  @Output() addEvent = new EventEmitter<object>();

  constructor(private sharedService: SharedService) { }

  ngAfterViewInit() {
    this.sharedService.mapView.ui.add(this.coordsWidgetID.nativeElement, "bottom-trailing");
    this.Init();
  }

  ngOnDestroy() {
    this.sharedService.mapView.ui.remove(this.coordsWidgetID.nativeElement);
    this.addEvent.unsubscribe();
  }

  Init() {
    this.isStarted = true;
    var self = this;
    this.sharedService.mapView.on("pointer-move", function (evt) {
      self.showCoordinates(self.sharedService.mapView.toMap({ x: evt.x, y: evt.y }));
    })
  }

  Stop() {
    this.isStarted = false;
    this._ref.destroy();
  }

  private showCoordinates(pt) {
    this.coordX = pt.longitude.toFixed(6);
    this.coordY = pt.latitude.toFixed(6);
    //let view = this.sharedService.mapView;
    //var coords =
    //  "X-" +
    //  pt.longitude.toFixed(6) + "°" +
    //  "   Y-" +
    //  pt.latitude.toFixed(6) + "°";
    //" | Scale 1:"
    //+ Math.round(view.scale * 1) / 1 +" | Zoom " +
    //view.zoom;
    //coordsWidget.innerHTML = coords;
  }

  createCoordinates() {
    this.addEvent.emit({ x: this.addedX, y: this.addedY });
    this.addedX = null;
    this.addedY = null;
  }
}

<dx-list #list
        class="list-compact"
        [dataSource]="filterList"
        selectionMode="none"
        [showSelectionControls]="true"
        [activeStateEnabled]="false"
        [pageLoadMode]="true"
        noDataText="Немає фільтрів">

  <div *dxTemplate="let filter of 'item'" [ngClass]="{'filter-admin' : filter.userID==null}">
    <div class="flex-row align-items-center">
      <dx-switch (onValueChanged)="switchValueChanged($event, filter)"
                  [value]="filter.isSelected">
      </dx-switch>
      <p class="switch-label">{{filter.name}}</p>
      <div>
        <!-- <dx-button *ngIf="filter.userID != null" icon="icon esri-icon-pie-chart" (onClick)="showChart(filter)" hint="Діаграма" stylingMode="text"></dx-button> -->
        <dx-button *ngIf="filter.userID != null" icon="icon esri-icon-settings" (onClick)="editSettings(filter)" hint="Налаштування" stylingMode="text"></dx-button>
        <dx-button *ngIf="filter.userID != null" icon="icon esri-icon-trash" (onClick)="removeFilter(filter)" hint="Видалити" stylingMode="text"></dx-button>
      </div>
    </div>
  </div>
  
</dx-list>
<div class="w-scales">
  <div  [hidden]="!isShowed" >
    <span (click)="onClickLabel()" class="scales__text">
      Масштаб 1 : {{ scalesValue }}
    </span>
  </div>
  <div  [hidden]="isShowed" >
    <dx-select-box [items]="scales"
                   width="176px"
                   placeholder="Виберіть масштаб"
                   noDataText=""
                   (onItemClick)="onItemClick($event)"
                   (onClosed)="onClosed($event)" >
    </dx-select-box>
  </div>
</div>

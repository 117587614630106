<div class="content-padding-xs flex-row align-items-center card">
  <div class="flex-row align-items-center first-switch">
    <dx-switch [value]="isShowMeasureDistance" (onValueChanged)="showDistance()">
    </dx-switch>
    <p class="switch-label">Кроки</p>
  </div>
  <div *ngIf="isShowSwitcher" class="flex-row align-items-center">
    <dx-switch [value]="!isSquareMeter" (onValueChanged)="changeSquareUnit()">
    </dx-switch>
    <p class="switch-label-off">м²</p>
    <p class="switch-label">га</p>
  </div>
  <dx-button icon="icon icon-eraser filled" (onClick)="stopDrawing()" id="btnStopDrawing" hint="Очистити">
  </dx-button>
</div>
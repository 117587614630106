import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from "@angular/common";
import { LegendsService } from "../../services/legends/legends.service";
import { SharedService } from "../../services/shared/shared.service";
import { Legend, LegengsIcon, LegendItem, isFlashType } from "../../models/layers/legend.model";
import { Observable, Subscription } from 'rxjs';
import { BaseComponent } from "../basecomponent/base.component";
import { ExportGeodataService } from "../../services/exportgeodata/export-geodata.service";
import { UserService } from "../../services/user/user.service";
import notify from 'devextreme/ui/notify';
import { ImportGeodataService } from "../../services/importgeodata/import-geodata.service";
import { EsriService } from '../../services/esri/js-esri.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Layer } from '../../models/layers/layer.model';
import { MapLayersService } from '../../services/map/map.layers.service';
import { map } from 'rxjs/operators';
import { ChartService } from '../../services/chart/chart.service';
import { LegendQuerySevice } from '../../services/legends/legend-query.service';



@Component({
  selector: 'app-publiclegends',
  templateUrl: './public.legends.component.html',
  styleUrls: ['./public.legends.component.scss']
})
export class PublicLegendsComponent extends BaseComponent implements OnInit, OnDestroy {
  icons: LegengsIcon[];
  itemHold: any;
  legends: Array<LegendItem> = new Array<LegendItem>();
  subscription: Subscription;
  regionTitle: string;
  public IsApp: boolean = true;
  enableLayers: boolean = false;

  constructor(
    private legendsService: LegendsService,
    private sharedService: SharedService,
    private location: Location,
    private exportGeodataService: ExportGeodataService,
    private esriService: EsriService,
    private chartService: ChartService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private mapLayersService: MapLayersService,
    private legendQuerySevice: LegendQuerySevice
  ) {
    super();
    
  }

  ngOnInit() {

    this.sharedService.getBookmarkChanged().subscribe(val => {
      this.chartService.listChartsByPublicBookmark(val).then(val => {
        this.enableLayers = val?.length > 0;
      })
    })
    
    //this.userService.isLoggedIn();
    this.legends = this.sharedService.publicLegends;

    this.subscription = this.sharedService.getPublicLegendsTitle().subscribe(val => {
      this.regionTitle = val;
    })
    //this.regionTitle = this.sharedService.getPublicLegendsTitleValue();
    //this.subscription = this.sharedService.getLegends().subscribe(legends => {
    //  this.legends = legends;
    //  if (this.sharedService.getPublicLegendsTitleValue()) {
    //    this.regionTitle = this.sharedService.getPublicLegendsTitleValue()
    //  }
    //})

  }

  ngOnDestroy() {
    //(this.sharedService.map as __esri.Map).allLayers.filter(_layer =>
    //  _layer.get<string>("FlashLayerID") != null && _layer.get<string>("FlashLayerID") != '').
    //  forEach(x => {
    //    this.legendsService.removeFlashLayer(x.id, x.get<string>("FlashLayerID"));
    //  });
    if (this.subscription)
      this.subscription.unsubscribe();
  }

  goBack() {
    this.location.back();
  }
  
  //getIcon(type: any): any {
      
  //  let temp = this.icons[this.icons.findIndex(el => el.type === type)];
  //  //console.log('type of symbol: ', type);
  //  return temp? temp.icon: '';
  //}

  onItemHold(e) {

    this.itemHold = e.itemData;

  }

  reorder(e) {

    if (!!this.itemHold) {
      let _map = this.sharedService.map as __esri.Map;      
      let _layer = _map.findLayerById(this.itemHold.id);     
      let _index = this.legends.length > 0 ? this.legends.length - e.toIndex - 1 : 0;
      _map.reorder(_layer, _index);
    }
  }

  selectLayerByID(id, layerId, selectValue, layerGuid, defaultValues) {
    let legend = defaultValues ? this.legends.find(x => x.layerGuid == layerGuid &&  x.id == id && x.defaultValues.find(v => v == defaultValues[0])) :
      this.legends.find(x => x.layerGuid == layerGuid &&  x.id == id);
    //let isRemoveRed = legend.isFlash == isFlashType.red && selectValue == isFlashType.red;
    legend.isFlash = selectValue == 'none' ? 'red' : 'none'; //(selectValue == 'red' ? 'flash' : 'none') ;

    if (legend.isFlash != isFlashType.none) {
      let subQuery = this.legendQuerySevice.getSubQuery(legend);
      if (legend.defaultValues) {

        
        if (legend.SubTypeField) {
        //if (defaultValues && defaultValues[0]) {
          //let filterSUbType = legend.filterTypeField + "=" + defaultValues[0];
            //let subQuery = (filterKOATUU ? filterKOATUU : '') + (filterSUbType ? (filterKOATUU ? ' and ' : '') + filterSUbType : '');           
            this.legendsService.flashLayer(id, layerId, legend, subQuery, defaultValues[0]);
          } else {
          this.legendsService.flashLayer(id, layerId, legend, subQuery);
          }
      } else {

        this.legendsService.flashLayer(id, layerId, legend, subQuery);
      }
    } else {
      legend.visibleCount = null;
      this.legendsService.removeFlashLayer(layerId, layerGuid, defaultValues ? defaultValues[0] : null);
    }


  }

  onValueChanged(e, data) {

    let layer = (this.sharedService.map as __esri.Map).findLayerById(data.layerID);

    let changeLayer = layer.type == "feature" ? layer : (layer as __esri.MapImageLayer).sublayers.find(x => x.id == data.id);
    if (changeLayer) {
      changeLayer.opacity = e.value / 100;
    } else {
      notify("шар не занайдено", "error", 2500);
    }
        
  }

  goToLayers() {
    this.goToCharts();
    //let layers = this.sharedService.getLayerList().getValue();
    //layers.forEach(result => {
    //  this.hideLayers(result.layerChildren);
    //  if (result.isSelected) {
    //    result.isSelected = false;
    //    this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
        
    //  }
    //});
    //this.sharedService.setLayers(layers);
    //this.mapLayersService.RemoveAllLayers();
    //this.sharedService.setShowBookmark(false);
    //this.sharedService.groupBookmarks = [];
    //this.sharedService.publicGroup = false;
    //this.router.navigate(['/layers']);
  }

  goToCharts() {
    const url: Observable<string> = this.activeRouter.url.pipe(map(segments => segments.join('/')));
    url.subscribe(_url => {
      this.router.navigate(['/charts', this.sharedService.PublicBookmarkID, { backUrl: _url }], { skipLocationChange: true });
    })
  }
  hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (_layer.isSelected) {
        _layer.isSelected = false;
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);
        
      }
    })
  }

  navigateToLayer(legendGUID) {
    //let _backUrl =

    const url: Observable<string> = this.activeRouter.url.pipe(map(segments => segments.join('/')));
    url.subscribe(_url => {
      this.router.navigate(['/navigationlist', legendGUID, { backUrl: _url/*this.router.routerState.snapshot.url*/ }], { skipLocationChange: true });
    })
    //this.activeRouter.url.pipe(map((data, i) => { return '/' + data[i].path })).subscribe(res => {

    //  this.router.navigate(['/navigationlist', legendGUID, { backUrl: res/*this.router.routerState.snapshot.url*/ }], { skipLocationChange: true });
    //})
   
  }

  navigateToRaster(legendGUID) {
    let item = this.legends.find(X => X.legendGUID == legendGUID);
    if (item) {
      var extent = item.extent;
      let opts = {
        duration: 500
      };
      if (!extent) {
        return;
      }
      var centerPoint = new this.esriService.Point({
        x: (extent.xmin + extent.xmax) / 2,
        y: (extent.ymin + extent.ymax) / 2,
        spatialReference: this.esriService.SpatialReference.WebMercator // extent.spatialReference 
      });
      var geographicCenter = this.esriService.webMercatorUtils.webMercatorToGeographic(centerPoint);
      this.sharedService.mapView.goTo({ center: [geographicCenter.longitude, geographicCenter.latitude] }, opts);
    }
  }
    
}

import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from "rxjs";
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { Filter, FilterType } from '../../models/filters/filter.model';
import { MapLayersEndpoint } from '../map/endpoint-map.layers.service';
import { LayerDataWithAttr } from '../../models/layers/layer.data.model';
import { SquareUnits } from '../../models/draw/square-units.model';
import { EsriService } from '../esri/js-esri.service';

@Injectable()
export class ExtentFilterService {

  graphicsFilterLayer: any
  sketchViewModel: any;
  newID: number = 0;


  private _createdEvent = new BehaviorSubject<any>(null);

  getCreatedEvent(): Observable<any> {
    return this._createdEvent.asObservable();
  }

  setCreatedEvent(value: any) {
    this._createdEvent.next(value);
  }

  constructor(private sharedService: SharedService, private mapLayersEndpoint: MapLayersEndpoint, private esriService: EsriService) {
    

  }

  createSketchModel() {
    this.graphicsFilterLayer = new this.esriService.GraphicsLayer({
      id: "filterExtentLayer"
    });

    this.sharedService.map.layers.add(this.graphicsFilterLayer);
    this.sketchViewModel = new this.esriService.SketchViewModel({
      view: this.sharedService.mapView,
      updateOnGraphicClick: false,
      layer: this.graphicsFilterLayer,
      pointSymbol: {
        type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
        style: "circle",
        color: [20, 146, 220, 1],
        size: "1px",
        outline: { // autocasts as new SimpleLineSymbol()
          color: [20, 146, 220, 1],
          width: 1
        }
      },
      polylineSymbol: {
        type: "simple-line", // autocasts as new SimpleLineSymbol()
        color: [20, 146, 220],
        width: "2",
        cap: "round",
        join: "round"
      },
      polygonSymbol: {
        type: "simple-fill", // autocasts as new SimpleFillSymbol()
        color: [20, 146, 220, 0.26],
        style: "solid",
        outline: {
          color: [20, 146, 220],
          width: 2
        }
      }
    })
    var _self = this;
    this.sketchViewModel.on("create", function (event) {

      if (event.state != "complete") {
        return;
      }
      let objectId = _self.getNewID();
      let distance = _self.esriService.GeometryEngine.geodesicLength(event.graphic.geometry, "meters");
      let square = _self.esriService.GeometryEngine.geodesicArea(event.graphic.geometry, SquareUnits.meter);
      let longitude = 0;
      let latitude = 0;

      switch (event.graphic.geometry.type) {
        case 'point':
          longitude = event.graphic.geometry.longitude;
          latitude = event.graphic.geometry.latitude;
          break;
        case 'polygon':
          longitude = event.graphic.geometry.centroid.longitude;
          latitude = event.graphic.geometry.centroid.latitude;
          break;
        default: break;
      }

      let attr = {
        OBJECTID: objectId,
        Name: "Об'єкт " + objectId,
        Description: "",
        Longitude: longitude,
        Latitude: latitude,
        Length: distance.toFixed(2),
        Square: square.toFixed(2),
        Type: event.graphic.geometry.type,
      };


      event.graphic.attributes = attr;
      _self.setCreatedEvent(event.graphic);
    });
  }

  drawPoint() {
    //this.sketchViewModel.pointSymbol = {
    //  type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
    //  style: "square",
    //  color: [20, 146, 220, 1],
    //  size: "10px",
    //  outline: { // autocasts as new SimpleLineSymbol()
    //    color: [20, 146, 220, 1],
    //    width: 2
    //  }
    //}

    this.sketchViewModel.create("point");
  }
  drawPolyline() {
    //this.sketchViewModel.polylineSymbol = {
    //  type: "simple-line", // autocasts as new SimpleLineSymbol()
    //  color: [20, 146, 220],
    //  width: "2",
    //  cap: "round",
    //  join: "round"
    //}
    this.sketchViewModel.create("polyline");
  }
  drawPolygon() {
    //this.sketchViewModel.polygonSymbol = {
    //  type: "simple-fill", // autocasts as new SimpleFillSymbol()
    //  color: [20, 146, 220, 0.26],
    //  style: "solid",
    //  outline: {
    //    color: [20, 146, 220],
    //    width: 2
    //  }
    //};
    this.sketchViewModel.create("polygon");
  }

  getNewID() {
    return "123";
  }
}

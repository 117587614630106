import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicBookmark } from "../../models/bookmarks/bookmark.model";
import { Layer } from '../../models/layers/layer.model';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';
import { FiltersService } from '../../services/filters/filters.service';
import { MapLayersService } from '../../services/map/map.layers.service';
import { SharedService } from '../../services/shared/shared.service';
import { BaseComponent } from "../basecomponent/base.component";


@Component({
  selector: 'app-bookmarks-story',
  templateUrl: './bookmarks-story.component.html',
  styleUrls: ['./bookmarks-story.component.scss']
})
export class BookmarksStoryComponent extends BaseComponent implements OnInit, OnDestroy {
  public userBookmarks: PublicBookmark[];
  get UserBookmarks(): PublicBookmark[] {
    return this.userBookmarks;
  }
  set UserBookmarks(value: PublicBookmark[]) {
    value.forEach(b => {
      this.initImage(b);
    })
    this.userBookmarks = value;
  }
  selectedBookmark: PublicBookmark;
  isShow: boolean = false;
  subscription: Subscription;
  selectedBookmarkID: string;
  bookamrkTitle: string;
  slideshowDelay = 2000;
  firstInit: boolean = true;
  backToBookmark = false;
  @Output() changeEvent = new EventEmitter<PublicBookmark>();

  constructor(private sharedService: SharedService, private router: Router, private azureStorageService: AzureStorageService,
    private filtersService: FiltersService,
    private bookmarksService: BookmarksService,
    private mapLayersService: MapLayersService
  ) {
    super();
  } 
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    }
    ngOnInit(): void {
      this.subscription = this.sharedService.getPublicStoryTitle().subscribe(val => {
        this.bookamrkTitle = val;
      })
    }

  setSelectedBookmark(bookmark: PublicBookmark) {
    //this.selectedBookmark = bookmark;
    this.selectedBookmarkID = bookmark ? bookmark.id : (this.userBookmarks?.length > 0 ? this.userBookmarks[0].id : null); 
  }

  selectBookmark(event) {
    
    if (!this.firstInit /* && event.addedItems?.length > 0*/) {
      
      this.selectedBookmark = this.userBookmarks.find(x => x.id == event.addedItems[0].id);
      this.selectedBookmarkID = event.addedItems[0].id; // this.firstInit ? this.selectedBookmark[0].id : event.addedItems[0].id;      
      this.changeEvent.emit(this.selectedBookmark);
    } else if (this.firstInit && event.addedItems?.length > 0) {
      this.firstInit = false;
      this.selectedBookmark = this.userBookmarks.find(x => x.id == this.selectedBookmarkID);
      //this.selectedBookmarkID = event.addedItems[0].id; // this.firstInit ? this.selectedBookmark[0].id : event.addedItems[0].id;

      if (event.addedItems[0].id == this.selectedBookmarkID) {
        this.changeEvent.emit(this.selectedBookmark);
      }
      
    }    
  }

  private initImage(bookmark: PublicBookmark) {
    this.azureStorageService.listBlobsUrlsByContainer(bookmark.id).then(result => {
      bookmark.imageSource = result;
    })
  }

  goToBookmark() {
    //this.sharedService.setSelectedBookmark(null);
    if (this.sharedService.getCurrentFilterValue()) {
      this.filtersService.unselectFilter();
    }
    let selectedBookmark = this.sharedService.getSelectedBookmarkValue();
    if (selectedBookmark) {
      this.bookmarksService.unselectBookmark(selectedBookmark);
    }

    let layers = this.sharedService.getLayerList().getValue();
    layers.forEach(result => {
      this.hideLayers(result.layerChildren);
      if (!result.isSelected) {
        this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
      }
    });


    //let layers = this.sharedService.getLayerList().getValue();

    //layers.forEach(_layer => {
    //  let item = bookmark.details.find(x => x.layerID == _layer.id) != null;
    //  if (item) {
    //    _layer.isSelected = false;
    //    _layer.inBookmark = _layer.isSelected;
    //  }

    //  this.unselectChildren(_layer.layerChildren, bookmark);

    //})

    //this.sharedService.setLayers(layers);


    //layers.forEach(result => {
    //  this.hideLayers(result.layerChildren);
    //  if (!result.isSelected) {
    //    this.mapLayersService.HideLayerDataByLayerIDEx(result.id);
    //  }
    //});

    this.router.navigate(['./bookmarks']);
  }

  private hideLayers(layers: Layer[]) {
    layers.forEach(_layer => {
      this.hideLayers(_layer.layerChildren);
      if (!_layer.isSelected)
        this.mapLayersService.HideLayerDataByLayerIDEx(_layer.id);

    })
  }
}

import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from "../../services/shared/shared.service";
import { Layer, SelectChildrenType } from "../../models/layers/layer.model";
import { MapLayersService } from "../../services/map/map.layers.service";
import { Subscription } from 'rxjs';
import { CadastreService } from "../../services/cadastre/cadastre.service";
import { BaseComponent } from "../basecomponent/base.component";
import notify from 'devextreme/ui/notify';
import {
  DxTreeViewComponent
} from 'devextreme-angular';
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import { FeatureLayerService } from '../../services/map/feature-layer.service';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';



@Component({
  selector: 'app-layers',
  templateUrl: './layers.component.html',
  styleUrls: ['./layers.component.scss']
})
export class LayersComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

 // @ViewChild(DxTreeViewComponent) treeView;

  @ViewChild('treeView', { static: true }) treeView: DxTreeViewComponent;

  get ShowCadaster() {
    return this.sharedService.IsShowedCadastre;
  }
  constructor(
    private sharedService: SharedService,
    private mapLayersService: MapLayersService,
    private cadastreService: CadastreService,
    private sketchViewService: SketchViewService,
    private featureLayerService: FeatureLayerService,
    private bookmarksService: BookmarksService
  ) { super(); }

  Layers: Layer[];
  checkedLayers: Layer[] = [];
  subscription: Subscription;
  isCadastreLoading: boolean = false;
  isCadastreSelect: boolean = false;
  isCadastreDisabled: boolean = false;
  isShowCustomLayer: boolean = true;
  isCheckedCustomLayer: boolean = false;
  isCustomLayereDisabled: boolean = false;
  subscriptionShowedCustomLayer: Subscription;
  subscriptionCheckedCustomLayer: Subscription;
  subscriptionCustomLayereDisabled: Subscription;
  subscriptionFilter: Subscription;
  subscriptionSelectionChanged: Subscription;

  pinnedLayerName: string;
  isPinned: boolean = false;
  isPinnedSelect: boolean = false;
  pinnedLayer: Layer;
  pinnedLayerDisabled: boolean = false;

  ngOnInit() {
    this.isCadastreSelect = this.sharedService.IsShowedCadastre;

    this.subscription =
      this.sharedService.getLayers().subscribe(layers => {
        this.clearPinnedLayer();
        let pinnedLayers = layers.filter(l => l.isPinned);
        if (pinnedLayers?.length > 0) {
          this.pinnedLayerName = pinnedLayers[0].name;
          this.isPinned = true;
          this.pinnedLayer = pinnedLayers[0];
          this.isPinnedSelect = this.pinnedLayer.isSelected;
        }
        this.Layers = layers.filter(f => !f.isPinned); // layers;
        this.pinnedLayerDisabled = this.sharedService.getCurrentFilterValue() != null;
      }
    );
    this.subscriptionCustomLayereDisabled = this.sharedService.getIsEditCustomLayer().subscribe(result => {
      this.isCustomLayereDisabled = result;
    })
    this.subscriptionCheckedCustomLayer = this.sharedService.getIsCheckedCustomLayer().subscribe(result => {
      this.isCheckedCustomLayer = result;
      //if (result) {
      //  this.sketchViewService.setGraphics();
      //} else {
      //  this.sketchViewService.removeGraphics();
      //}
    })

    //this.subscriptionFilter = this.sharedService.getCurrentFilter().subscribe(result => {
      //if (result) {      
      //  this.pinnedLayerDisabled = true;
      //} else {
      //  this.pinnedLayerDisabled = false;
      //}
    //})
  }

  ngAfterViewInit() {
    this.subscriptionSelectionChanged =this.treeView.onItemSelectionChanged.subscribe(e => {
      this.selectionChanged(e);
    })
  }

  getParentNode(node) {
    if (node.parent != null) {      
      let parentItem = this.getParentNode(node.parent);
      this.treeView.instance.selectItem(node.parent.key);
    }
  }

  selectionChanged(e) {
    let value = e.node;
 
    if (value.selected) {
      this.subscriptionSelectionChanged.unsubscribe();
      this.treeView.instance.beginUpdate();
      this.getParentNode(value);
      this.treeView.instance.endUpdate();
      this.subscriptionSelectionChanged = this.treeView.onItemSelectionChanged.subscribe(e => {
        this.selectionChanged(e);
      })
    }
    
    let model = new Layer();
    let layer: Layer = Object.assign(model, value.itemData);
    if (layer.isSelected) {
      if (layer.isEmpty) {
        notify(`В шарі ${layer.name} нема даних`, "warning", 2000);
      }
      //if (this.sharedService.getCurrentFilterValue()) {
      //  let filter = this.sharedService.getCurrentFilterValue();
      //  let filterLayers = filter.layers.filter(f => f.layerID == layer.id);
      //  this.featureLayerService.showFeatureByFilterLayers(filterLayers);
      //}
      this.featureLayerService.showFeatureServer(layer.id);
     //this.mapLayersService.showMapServerEx(layer.id);
    } else {
      this.unselectParentNode(value);
      //if (layer.inBookmark) {
      //  this.sharedService.getSelectedBookmark().subscribe(s => {
      //    if (s) {
      //      this.bookmarksService.unselectBookmark(s);
      //    }
      //  }).unsubscribe();
      //}
      this.mapLayersService.HideLayerDataByLayerIDEx(layer.id);
      layer.layerChildren.forEach(x => {
        if (x.isSelected) {
          x.hasFlash = false;

        }
      });
    }    
  }

  isChildrenSelected(layer: Layer): boolean {
    let returnResult: boolean = false;
    let result = layer.isSelected;
    if (result) {
      returnResult = true;
    }

    for (var item of layer.layerChildren) {
      if (item.isSelected) {
        result = this.isChildrenSelected(item);
        if (!result) {
          returnResult = false;
        }
      } else {
        returnResult = false;
      }

    }
    if (returnResult) {
      layer.selectChildrenType = SelectChildrenType.all;
    } else {
      layer.selectChildrenType = SelectChildrenType.none;
    }
    
    return returnResult;
  }

  unselectParentNode(node) {
    if (node.parent != null) {
      node.parent.itemData.selectChildrenType = SelectChildrenType.any;
      let parentItem = this.unselectParentNode(node.parent);
    }
  }

  selectChildrenLayers(data) {
    let layer: Layer = data;

    if (layer.selectChildrenType == SelectChildrenType.none || layer.selectChildrenType == SelectChildrenType.any || layer.selectChildrenType == undefined) {
      layer.selectChildrenType = SelectChildrenType.all;
      this.mapLayersService.selectChildrenLayers(data);
      this.featureLayerService.showFeatureServer(data.id);
    } else if (layer.selectChildrenType == SelectChildrenType.all) {
      layer.selectChildrenType = SelectChildrenType.none;
      this.mapLayersService.unselectChildrenLayers(data);
    }
    
  }
  //getSelected(value) {
  // debugger
  //  if (this.isLayer(value)) {

  //    let model = new Layer();
  //    let layer = Object.assign(model, value.itemData);
  //    this.updateSelectedLayer(layer);

  //    //this.getSelected(value);
  //  }
  //  else {
  //    value.items.forEach((layerdata, index) => {
  //      let model = new Layer();
  //      let layer = Object.assign(model, layerdata.itemData);
  //      this.updateSelectedLayer(layer);

  //      if (this.isLayer(layerdata)) {
  //        this.getSelected(layerdata); 
  //      }
  //    });
  //  }
  //}

  updateSelectedLayer(layer: Layer) {
     
    let itemIndex = -1;
    
    this.checkedLayers.forEach((item, index) => {
      if (item.id === layer.id) {
        itemIndex = index;
        return false;
      }
    });

    if (layer.isSelected && itemIndex === -1) {
      this.checkedLayers.push(layer);
    } else if (!layer.isSelected) {
      this.checkedLayers.splice(itemIndex, 1);
    }
  }

  isLayer(data) {
    return !data.items.length;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    //this.subscriptionShowedCustomLayer.unsubscribe();
    this.subscriptionCheckedCustomLayer.unsubscribe();
    this.subscriptionCustomLayereDisabled.unsubscribe();
    this.subscriptionSelectionChanged.unsubscribe();
  }


  selectCadastre() {
    if (!this.sharedService.IsShowedCadastre && !this.isCadastreDisabled)
    {
      this.isCadastreLoading = true;
      this.isCadastreDisabled = true;
      //this.cadastreService.createCadastrDynamicMap().then(result => {
      //  this.isCadastreDisabled = false;
      //  this.isCadastreSelect = result;
      //});
      this.cadastreService.createCadastrWMSService().then(result => {
        this.isCadastreDisabled = false;
        this.isCadastreSelect = result;
      })
      

    } else if (!this.isCadastreDisabled)
    {
      
      this.cadastreService.hideCadastreMap();
      this.isCadastreSelect = false;
      this.isCadastreLoading = false;
      //this.sharedService.IsShowedCadastre = false;
    }
    
  }

  selectCustomLayer() {
    this.isCheckedCustomLayer = !this.isCheckedCustomLayer;

    if (this.isCheckedCustomLayer) {
      this.sketchViewService.setGraphics();
    } else {
      this.sketchViewService.removeGraphics();
    }
    this.sharedService.setIsCheckedCustomLayer(this.isCheckedCustomLayer);
    
  }

  selectPinnedLayer() {
    this.isPinnedSelect = !this.isPinnedSelect;

    if (this.isPinnedSelect) {
      this.pinnedLayer.isSelected = true;
      this.pinnedLayer.selectChildrenType = SelectChildrenType.all;
      this.mapLayersService.selectChildrenLayers(this.pinnedLayer);
      this.featureLayerService.showFeatureServer(this.pinnedLayer.id);
      //this.mapLayersService.showMapServerEx(layer.id);
    } else {
      this.pinnedLayer.isSelected = false;
      this.pinnedLayer.selectChildrenType = SelectChildrenType.none;
      this.mapLayersService.HideLayerDataByLayerIDEx(this.pinnedLayer.id);
      this.pinnedLayer.layerChildren.forEach(x => {
        if (x.isSelected) {
          x.hasFlash = false;

        }
      });
    }
  }

  private clearPinnedLayer() {
    this.isPinned = false;
    this.pinnedLayerName = "";
    this.isPinnedSelect = false;
    this.pinnedLayer = null;
    this.pinnedLayerDisabled = false;
  }
}




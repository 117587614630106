<dx-button *ngIf="listDashboards.length > 0" 
  icon="lg-icon esri-icon-chart text-stroke" 
  [id]="listDashboards.length === 1 ? 'external-chart-link' : 'external-chart-popover'" 
  [hint]="listDashboards.length === 1 ? listDashboards[0]?.name : 'Графіки'"
  (onClick)="openSingleChart()">
</dx-button>

<dx-popover *ngIf="listDashboards.length > 1" target="#external-chart-popover" showEvent="click" position="top">
  <div *dxTemplate="let data of 'content'" class="content-padding flex-col">
    <a *ngFor="let dashboard of listDashboards" [href]="dashboard.url" target="_blank">
      {{ dashboard.name }}
    </a>
  </div>
</dx-popover>
import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { LocationsEndpoint } from "./locations-endpoint.service";
import { UserRegions } from "../../models/userregions/user-regions.model";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class LocationsService {

  constructor(private sharedService: SharedService, private locationsEndpoint: LocationsEndpoint) {
    

  }

  //get user regions
  getUserRegions(userId?: string) {

    return this.locationsEndpoint.getUserRegionsEndpoint(userId)
      .pipe(map((response: any) =>
        <UserRegions[]>response));    
  }
}

<div class="grid-bookmarks">
  <header *ngIf="backToBookmark" class="content-padding-xs flex-row align-items-center border-bottom">
    <dx-button icon="icon esri-icon-left-arrow" hint="Повернутися до закладок" stylingMode="text" (click)="goToParents()"></dx-button>
    <p id="legendList" class="text-ellipsis" title="{{bookamrkTitle}}">
      {{bookamrkTitle}}
    </p>
  </header>

  <div *ngIf="isShow" class="w-scroll">
    <dx-scroll-view direction="vertical">
        <ul>
          <li *ngFor="let bookmark of userBookmarks" class="item-bookmark" [ngClass]="{'js-admin' : !bookmark.isSelected && !bookmark.userID , 'js-active' : bookmark.isSelected}" >
            <div *ngIf="bookmark.userID" class="item-bookmark__btn">
              <dx-button *ngIf="!!!bookmark.parentID" icon="icon esri-icon-plus-circled" hint="Додати" stylingMode="text" (click)="addStory(bookmark)"></dx-button>
              <dx-button icon="icon esri-icon-settings" hint="Налаштувати" stylingMode="text" (click)="editSettings(bookmark)"></dx-button>
              <dx-button icon="icon esri-icon-trash" hint="Видалити" stylingMode="text" (click)="removeBookamrk(bookmark)"></dx-button>
            </div>
            <button *ngIf="bookmark.isSelected && (bookmark.imageSource?.length>0 || bookmark.htmlText?.length>0)" class="btn-icon btn-icon--expansion " [ngClass]="{'js-expand' : bookmark.isExpanded}"  (click)="hideBookmarkContent(bookmark)">
              <span class="esri-icon-arrow-down-circled"></span>
            </button>
            <a class="item-bookmark__title" (click)="selectBookmark(bookmark)" title="{{bookmark.name}}"> {{bookmark.name}} </a>
            <div *ngIf="bookmark.isSelected && bookmark.isExpanded && (bookmark.imageSource?.length > 0 || bookmark.htmlText?.length > 0)" class="item-bookmark-content flex-col">
              <dx-gallery #gallery
                          id="gallery"
                          *ngIf="bookmark.imageSource?.length > 0"
                          [dataSource]="bookmark.imageSource"
                          [height]="240"
                          [loop]="true"
                          [showNavButtons]="bookmark.imageSource?.length > 1"
                          [showIndicator]="bookmark.imageSource?.length > 1">
              </dx-gallery>
              <div [innerHTML]="bookmark.htmlText"></div>
              <router-outlet></router-outlet>
            </div>
          </li>
        </ul>
    </dx-scroll-view>
  </div>

  <div *ngIf="!isShow" class="dx-empty-message">
    Нема закладок
  </div>
</div>

import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { DashboardSettingEndpoint } from './dashboard.setting-endpoint.service';
import { DashboardSetting } from '../../models/dashboard-setting/dashboard-setting.model';


@Injectable()
export class DashboardSettingService {

  constructor(private dashboarSettingEndpoint: DashboardSettingEndpoint) {
    
  }  

  getByRegion(regionId: string): Observable<DashboardSetting[]> {
    return this.dashboarSettingEndpoint.getByRegionEndpoint(regionId).pipe(
      map((result: any) =>
        <DashboardSetting[]>result
      ));
  }
  
}

import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import {
  DxTextBoxModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
  DxLoadPanelModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxValidationGroupModule,
  DxPopupModule,
  DxSelectBoxModule,
  DxTreeViewModule,
  DxDropDownBoxModule,
  DxListModule,
  DxDataGridModule,
  DxTabsModule,
  DxSliderModule,
  DxSwitchModule,
  DxTemplateModule,
  DxLoadIndicatorModule,
  DxScrollViewModule,
  DxColorBoxModule,
  DxNumberBoxModule,
  DxFilterBuilderModule,
  DxTagBoxModule,
  DxPopoverModule,
  DxPieChartModule,
  DxChartModule,
  DxGalleryModule,
  DxAccordionModule,
  DxHtmlEditorModule,
  DxRadioGroupModule
} from 'devextreme-angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

//components 
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { MapComponent } from './components/map/map.component';
import { SearchComponent } from './components/search/search.component';
import { HomebtnComponent } from './components/homebtn/homebtn.component';
import { LocatebtnComponent } from './components/locatebtn/locatebtn.component';
import { GallerymapComponent } from './components/gallerymap/gallerymap.component';
import { OverviewmapComponent } from './components/overviewmap/overviewmap.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileComponent } from './components/profile/profile.component';
import { FiltersComponent } from './components/filters/filters.component';
import { EditFilterComponent } from './components/filters/editfilter/edit-filter.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { BookmarkAddComponent } from './components/bookmarks/bookmarkadd/bookmarkadd.component';

import { LayersComponent } from './components/layers/layers.component';
import { LegendsComponent } from './components/legends/legends.component';
import { LocationsComponent } from './components/locations/locations.component';
import { ScalebarComponent } from './components/scalebar/scalebar.component';
import { SwipeComponent } from './components/swipe/swipe.component';
import { HomeComponent } from './components/home/home.component';
import { PolygonComponent } from './components/draw/polygon/polygon.component'
import { PolylineComponent } from './components/draw/polyline/polyline.component';
import { RemoveDrawComponent } from './components/draw/remove/remove-draw.component';
import { PrintMapComponent } from './components/print/print-map.component';
import { BaseComponent } from "./components/basecomponent/base.component";
import { MeasurementToolbarComponent } from './components/draw/measurementtoolbar/measurement-toolbar.component';
import { EditGeodataComponent } from "./components/editgeodata/edit-geodata.component";
import { ViewGeodataComponent } from './components/editgeodata/viewgeodata/view-geodata.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { BookmarkRegionComponent } from './components/region/bookmark.region.component';
import { PublicLegendsComponent } from './components/publiclegends/public.legends.component';
import { DocumentUploadComponent } from './components/documentupload/document.upload.component';
import { NavigationListComponent } from './components/navigation/navigation-list.component';
import { CoordwidgetComponent } from './components/coordinates/coordinates.component';
import { ChartComponent } from './components/chart/chart.component';
import { MainPageComponent } from './components/mainpage/mainpage.component';
import { PublicBoomarkListComponent } from './components/pulbicbookmarklist/public-bookmark-list.component';
import { ExternalChartsComponent } from './components/external-charts/external-charts.component';


//services
import { EsriService } from './services/esri/js-esri.service';
import { ConfigService } from './services/shared/utils/config.service';
import { FiltersService } from './services/filters/filters.service';
import { GalleryMapService } from './services/gallerymap/gallerymap.service';
import { HomebtnService } from './services/homebtn/homebtn.service';
import { LayersService } from './services/layers/layers.service';
import { LegendsService } from './services/legends/legends.service';
import { LocatebtnService } from './services/locatebtn/locatebtn.service';
import { LocationsService } from './services/locations/locations.service';
import { MapService } from './services/map/map.service';
import { OverviewmapService } from './services/overviewmap/overviewmap.service';
import { ProfileService } from './services/profile/profile.service';
import { SearchService } from './services/search/search.service';
import { SidebarService } from './services/sidebar/sidebar.service';
import { BookmarksService } from './services/bookmarks/bookmarks.service';
import { BookmarksAnonymousService } from './services/bookmarksanonymous/bookmarks.anonymous.service';
import { ScalebarService } from './services/scalebar/scalebar.service';
import { SwipeService } from './services/swipe/swipe.service';
import { MapLayersService } from "./services/map/map.layers.service";
import { SharedService } from "./services/shared/shared.service";
import { BookmarksAnonymousComponent } from "./components/bookmarksanonymous/bookmarks.anonymous.component";
import { AppResolveService } from './services/shared/loader/app-resolve.service';
import { UserService } from './services/user/user.service';
import { EndpointFactory } from "./services/shared/endpoint-factory.service";
import { LayersEndpoint } from './services/layers/layers-endpoint.service';
import { MapLayersEndpoint } from './services/map/endpoint-map.layers.service';
import { LocationsEndpoint } from "./services/locations/locations-endpoint.service";
import { AttributesComponent } from "./components/layers/attributes/attributes.component";
import { ImageServiceEndpoint } from './services/gallerymap/image-service-endpoint.service';
import { BookmarksEndpointService } from './services/bookmarks/bookmarks-endpoint.service';
import { DrawPoligonService } from './services/draw/draw-polygon.service';
import { DrawPolylineService } from './services/draw/draw-polyline.service';
import { DrawMeasureService } from './services/draw/draw-measure.service';
import { PrintMapService } from './services/print/print-map.service';
import { PrintFileEndpoint } from './services/print/print-file.end-point.service';
import { EditGeodataService } from './services/editgeodata/edit-geodata.service';
import { ExportGeodataService } from './services/exportgeodata/export-geodata.service';
import { ImportGeodataService } from './services/importgeodata/import-geodata.service';
import { LoadingMapService } from './services/loading/loading.service';
import { CadastreService } from './services/cadastre/cadastre.service';
import { SketchViewService } from './services/customlayer/sketch-view.service';
import { CustomLayerEndpoint } from './services/customlayer/custom.layer-endpoint.service';
import { CustomLayerService } from './services/customlayer/custom-layer.service';
import { FilterAttributeService } from './services/filterattribute/filter-attribute.service';
import { AzureStorageService } from './services/azurestorage/azure-storage.service';
import { AzureStorageEndpointService } from './services/azurestorage/azure-storage-endpoint.service';
import { FilterEndpointService } from './services/filters/filters-endpoint.service';
import { ExtentFilterService } from './services/filters/extent.filter.service';
import { NavigationService } from './services/navigation/navigation.service';
import { CadastralXMLService } from './services/cadastralXML/cadastral-xml.service';
import { FeatureLayerService } from './services/map/feature-layer.service';
import { FlashLegendsService } from './services/legends/flash-legends.service';
import { ResizableModule } from 'angular-resizable-element';
import { ChartService } from './services/chart/chart.service';
import { ChartEndpointService } from './services/chart/chart-endpoint.service';
import { BookmarksStoryComponent } from './components/bookmarksstory/bookmarks-story.component';
import { StoryComponent } from './components/story/story.component'
import { BookmarkEditComponent } from './components/bookmarks/bookmarkedit/bookmarkedit.component';
import { DocumentViewComponent } from './components/documentview/document.view.component';
import { ImageViewComponent } from './components/imageview/image.view.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';
import { LegendQuerySevice } from './services/legends/legend-query.service';
import { LayerAttributeHeplerService } from './services/layers/layer.attribute-helper.service';
import { LayerServerService } from './services/layers/layer-server.service';
import { CopygeodataComponent } from './components/copygeodata/copygeodata.component';
import { CopyGeodataService } from './services/copygeodata/copy-geodata.service';
import { VisibleFilterPipe } from './components/pipe/visible-filter.pipe';
import { GeometryCalculatorComponent } from './components/geometry-calculator/geometry-calculator.component';
import { GeometryCalculatorService } from './services/geometrycalculator/geometry-calculator.service';
import { GeometrycalculatorbtnComponent } from './components/geometrycalculatorbtn/geometrycalculatorbtn.component';
import { ExportEndpointService } from './services/exportgeodata/export-endpoint.service';
import { DashboardSettingService } from './services/dashboardsetting/dashboard.setting.service';
import { DashboardSettingEndpoint } from './services/dashboardsetting/dashboard.setting-endpoint.service';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    SearchComponent,
    HomebtnComponent,
    LocatebtnComponent,
    GallerymapComponent,
    OverviewmapComponent,    
    SidebarComponent,
    ProfileComponent,
    FiltersComponent,
    EditFilterComponent,
    BookmarksComponent,
    LayersComponent,
    LegendsComponent,
    LocationsComponent,
    ScalebarComponent,
    SwipeComponent,
    HomeComponent,
    BookmarksAnonymousComponent,
    AttributesComponent,
    PolygonComponent,
    PolylineComponent,
    RemoveDrawComponent,
    PrintMapComponent,
    BaseComponent,
    MeasurementToolbarComponent,
    EditGeodataComponent,
    ViewGeodataComponent,
    ToolbarComponent,
    BookmarkRegionComponent,
    PublicLegendsComponent,
    DocumentUploadComponent,
    NavigationListComponent,
    CoordwidgetComponent,
    ChartComponent,
    MainPageComponent,
    BookmarksStoryComponent,
    PublicBoomarkListComponent,
    StoryComponent,
    BookmarkAddComponent,
    BookmarkEditComponent,
    DocumentViewComponent,
    ImageViewComponent,
    CopygeodataComponent,
    VisibleFilterPipe,
    GeometryCalculatorComponent,
    GeometrycalculatorbtnComponent,
    ExternalChartsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    DxTextBoxModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxValidationGroupModule,
    DxPopupModule,
    DxTreeViewModule,
    DxDropDownBoxModule,
    DxListModule,
    DxDataGridModule,
    DxTabsModule,
    DxSliderModule,
    DxSwitchModule,
    DxTemplateModule,
    DxLoadIndicatorModule,
    DxScrollViewModule,
    DxColorBoxModule,
    DxNumberBoxModule,
    DxFilterBuilderModule,
    DxTagBoxModule,
    DxPopoverModule,
    ResizableModule,
    DxPieChartModule,
    DxChartModule,
    DxGalleryModule,
    DxAccordionModule,
    DxHtmlEditorModule,
    NgxExtendedPdfViewerModule,
    DxRadioGroupModule
  ],
  providers: [ConfigService,
    EsriService,
    MapService,
    BookmarksService,
    FiltersService,
    GalleryMapService,
    HomebtnService,
    LayersService,
    LegendsService,
    LocatebtnService,
    LocationsService,
    OverviewmapService,
    ProfileService,
    SearchService,
    SidebarService,    
    ScalebarService,
    SwipeService,   
    MapLayersService,
    SharedService,
    BookmarksAnonymousService,
    AppResolveService,
    UserService,
    EndpointFactory,
    LayersEndpoint,
    MapLayersEndpoint,
    LocationsEndpoint,
    ImageServiceEndpoint,
    BookmarksEndpointService,
    DrawPoligonService,
    DrawPolylineService,
    DrawMeasureService, 
    PrintMapService,
    PrintFileEndpoint,
    EditGeodataService,
    ExportGeodataService,
    ImportGeodataService,
    LoadingMapService,
    CadastreService,
    SketchViewService,
    CustomLayerEndpoint,
    CustomLayerService,
    FilterAttributeService,
    AzureStorageService,
    AzureStorageEndpointService,
    FilterEndpointService,
    ExtentFilterService,
    NavigationService,
    CadastralXMLService,
    FeatureLayerService,
    ChartService,
    ChartEndpointService,
    FlashLegendsService,
    LegendQuerySevice,
    LayerAttributeHeplerService,
    LayerServerService,
    CopyGeodataService,
    ExportEndpointService,
    GeometryCalculatorService,
    DashboardSettingService,
    DashboardSettingEndpoint,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { Tab } from "../../models/sidebar/tab.model";

@Injectable()
export class SidebarService {

  constructor(private sharedService: SharedService) {
    
  }
 
  getSideBarTabsMenu(): Tab[] {
    let tabs: Tab[] = [
      {
        id: 0,
        content: 'layers',
        icon: 'layers'
      },
      {
        id: 1,
        content: 'bookmarks',
        icon: 'bookmarks'
      },
      {
        id: 2,
        content: 'legend',
        icon: 'legend'
      },
      {
        id: 3,
        content: 'filter',
        icon: 'filter'
      } 
    ];
    return tabs;
  }
}

import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';




@Injectable()
export class FlashLegendsService {

  constructor() {
    
  }    

  private flashLayerViews: __esri.LayerView[] = [];

  private flashSubscription: Subscription;

  private flashVisible: boolean = false;

  AddFlashLayerView(layerView: __esri.LayerView) {
    this.flashLayerViews.push(layerView);
    if (this.flashSubscription) {

    } else {
      this.flashSubscription = interval(1500).subscribe(() => {
        this.flashVisible = !this.flashVisible;
        this.flashLayerViews.forEach(view => {
          view.visible = this.flashVisible;
        })
      })
    }
  }

  RemoveFlashLayerViewByLayerID(layerID: string) {
    let flashLayerIndex = this.flashLayerViews.findIndex(v => v.layer.id == layerID);
    if (flashLayerIndex >= 0) {
      this.flashLayerViews.splice(flashLayerIndex, 1);
      if (this.flashLayerViews.length == 0) {
        this.flashSubscription.unsubscribe();
        this.flashSubscription = null;
      }
    }
  }

  RemoveAllFlashLayerViews() {
    this.flashSubscription.unsubscribe();
    this.flashSubscription = null;
    this.flashLayerViews = [];
  }

}

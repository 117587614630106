
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EndpointFactory } from '../shared/endpoint-factory.service';

@Injectable()
export class ExportEndpointService extends EndpointFactory {

  private readonly _exportfeatures: string = "/api/GisServer/exportfeatures";

  get exportFeaturesUrl() { return this.configService.getApiURI() + this._exportfeatures; }

  exportFeatures(layerQueries : any[]): Observable<Response> {
    
    let strQuery = JSON.stringify(layerQueries);
    const baseOptions = this.getAuthHeader(true);
    const httpOptions = {
      ...baseOptions,
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(this.exportFeaturesUrl, strQuery, httpOptions)
  }


}

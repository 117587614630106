import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SidebarService } from "../../services/sidebar/sidebar.service";
import { Tab } from "../../models/sidebar/tab.model";
import { Router, ResolveEnd, NavigationEnd} from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  DxTabsComponent, DxTabsModule
} from 'devextreme-angular';
import { Subscription } from "rxjs";
import { SharedService } from "../../services/shared/shared.service";
import { ExportGeodataService } from "../../services/exportgeodata/export-geodata.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  tabContent: string;                              // value from tab content  
  activeRouteSegment: string;                      // default region segment
  tabs: Tab[] = [];    
  isLogined: boolean = true;
  subscription: Subscription;
  //@ViewChild(DxTabsComponent) dxTabs;
  @ViewChild('apiTabs', { static: true }) dxTabs: DxTabsComponent;
  loadIndicatorVisible: boolean = false;
  routerSubscription: Subscription;
  bookmarkSubscription: Subscription;
  filterSubscription: Subscription;

  constructor(
    private sidebarService: SidebarService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private exportGeodataService: ExportGeodataService) {
    
  }

  ngOnInit() {
    this.tabs = this.sidebarService.getSideBarTabsMenu();    

    if (this.router.isActive("bookmarks", true) || this.router.isActive("charts", true)) {
      this.dxTabs.selectedIndex = 1;
    } else if (this.router.isActive("legend", true)) {
      this.dxTabs.selectedIndex = 2;
    } else if (this.router.isActive("filter", true) || this.router.isActive("filtersettings", true)) {
      this.dxTabs.selectedIndex = 3;
    }
    else {// if (this.router.isActive("layers", true)){
      this.dxTabs.selectedIndex = 0;
    }

    this.routerSubscription= this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        switch (event.url) {
          case "/filtersettings":
          case "/filter":
            this.dxTabs.selectedIndex = 3;
            break;
          case "/legend":
            this.dxTabs.selectedIndex = 2;
            break;
          case "/bookmarks":
          case "/savebookmark":
          case "/bookmark":
          case "/charts":
            this.dxTabs.selectedIndex = 1;
            break;
          case "/layers":
            this.dxTabs.selectedIndex = 0;
            break;
        }
      }
    });

    this.bookmarkSubscription = this.sharedService.getSelectedBookmark().subscribe(bookmark => {
      this.repaintTabs();
    })

    this.filterSubscription = this.sharedService.getCurrentFilter().subscribe(filter => {
      this.repaintTabs();
    })
  }

  private repaintTabs() {
    this.dxTabs.instance?.beginUpdate();
    this.dxTabs.instance?.repaint();
    this.dxTabs.instance?.endUpdate();
  }

  //tab changed
  selectTab(e) {
    
    this.tabContent = this.tabs[e.itemIndex].content;
    this.activeRouteSegment = this.tabContent;
    this.router.navigate(['/' + this.activeRouteSegment]);
  }

  goToLegends() {
    this.router.navigate(['./legends']);
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription?.unsubscribe();
    this.routerSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
    this.bookmarkSubscription.unsubscribe();
  }

  onItemRendered(e) {
    let _element = $(e.itemElement);
    let ttt = _element.children().children();//.filter("dx-tab");
    if (e.itemIndex == 1) {
      if (this.sharedService.getSelectedBookmarkValue()) {
        _element.addClass("dx-tab--bookmark");
      } else {
        _element.removeClass("dx-tab--bookmark");
      }
    }
    
    if (e.itemIndex == 3) {
      if (this.sharedService.getCurrentFilterValue()) {
        _element.addClass("dx-tab--filter");
      } else {
        _element.removeClass("dx-tab--filter");
      }
    }    
  }
}

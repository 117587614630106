import { BehaviorSubject, Observable } from "rxjs";


export class FeatureLayerInfo {
  url: string;
  layerInfo: any;
  public loadingPromise?: Promise<any>;

  private loadedSubject = new BehaviorSubject<boolean>(false);

  getLoaded(): Observable<boolean> {
    return this.loadedSubject.asObservable();
  }

  setLoaded(value: boolean) {
    this.loadedSubject.next(value);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardSettingService } from '../../services/dashboardsetting/dashboard.setting.service';
import { DashboardSetting } from '../../models/dashboard-setting/dashboard-setting.model';
import { SharedService } from '../../services/shared/shared.service';
import { Subscription, first } from 'rxjs';

@Component({
  selector: 'app-external-charts',
  templateUrl: './external-charts.component.html',
  styleUrl: './external-charts.component.css'
})
export class ExternalChartsComponent implements OnInit, OnDestroy {

  listDashboards: DashboardSetting[] = [];
  subscriptionRegion: Subscription;
  constructor(private dashboardSettingService: DashboardSettingService, private sharedService: SharedService) {

  }
  ngOnDestroy(): void {
    this.subscriptionRegion?.unsubscribe();
  }

  ngOnInit(): void {
    this.subscriptionRegion = this.sharedService.getRegionID().subscribe(regId => {
      if (regId) {
        this.dashboardSettingService.getByRegion(regId).pipe(first()).subscribe(result => {
            this.listDashboards = result
        })
      }
    })
    
  }

  openSingleChart(): void {
    if (this.listDashboards.length === 1) {
      const url = this.listDashboards[0].url;
      window.open(url, '_blank');
    }
  }
    
}

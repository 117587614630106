import { Filter } from "../filters/filter.model";
import { LayerDataWithAttr } from "../layers/layer.data.model";

export class UserBookmark {
  constructor(
    id?: string,
    name?: string,
    regionID?: string,
    imageServiceID?: string,
    status?: string,
    latitude?: number,
    longitude?: number,
    scale?: number,
    details?: UserBookmarkData[]) {

    this.id = id;
    this.name = name;
    this.regionID = regionID;
    this.imageServiceID = imageServiceID;
    this.status = status;
    this.latitude = latitude;
    this.longitude = longitude;
    this.scale = scale;
    this.details = details;
  }

  public id: string;
  public parentID: string;
  public name: string;
  public status: string;
  public regionID: string;
  public imageServiceID: string;
  public latitude: number;
  public longitude: number;
  public scale: number;  
  public details: UserBookmarkData[];  
  public htmlText: string;
  public isStory: boolean;
  public filterID: string;
  public filter: Filter;
  public bookmarks: UserBookmark[] = [];

  public isExpanded: boolean;
  public isSelected: boolean;
  public selectedValue: string;
  public imageSource: string[] = [];
  public userID: string;
}

export class UserBookmarkData {
  constructor(
    id?: string,
    userBookmarkId?: string,
    layerID?: string) {

    this.id = id;
    this.userBookmarkId = userBookmarkId;
    this.layerID = layerID;
  }

  public id: string;
  public userBookmarkId: string;
  public layerID: string;
}

export class PublicBookmark extends UserBookmark {
  public token: string;
  public code: string;
  public layers: LayerDataWithAttr[];
  

}

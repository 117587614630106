<div class="legend-list legend-grid-container">

    <dx-list [items]="legends"
            allowItemReordering="true"
            [hoverStateEnabled]="false"
            (onItemHold)="onItemHold($event)"
            (onItemReordered)="reorder($event)"
            itemTemplate="legendsTemplate"
            noDataText="Немає легенд">
            <!--<dxo-item-dragging--! group="legends"
                        [data]="plannedLegends"
                        [allowReordering]="true"
                        [onDragStart]="onDragStart"
                        [onAdd]="onAdd"
                        [onRemove]="onRemove">
            </dxo-item-dragging-->
      <div *dxTemplate="let legend of 'legendsTemplate'">
        <div class="legend-item" [ngClass]="{'legend-item--active': legend.isOpened}">
          <div *ngIf="!legend.isRaster" class="icon icon--traffic-light icon--btn" [ngClass]="{filled : legend.isFlash=='red', multi : legend.isFlash=='flash' }" (click)="selectLayerByID(legend.id, legend.layerID, legend.isFlash, legend.layerGuid, legend.defaultValues)" title="Вибрати"></div>

          <div *ngIf="!legend.isRaster" class="icon icon--count icon--btn" (click)="navigateToLayer(legend.legendGUID)" title="{{legend.totalCount}}"><span class="count" [ngClass]="{'esri-icon-layer-list' : legend.totalCount==undefined}">{{legend.totalCount}}</span></div>
          <div *ngIf="legend.isRaster" class="icon icon--count icon--btn" (click)="navigateToRaster(legend.legendGUID)" title="Перейти до даних"><span class="count" [ngClass]="{'icon-arrow-right' : legend.totalCount==undefined}"></span></div>
          <div class="legend-item-content">
            
            <div *ngIf="isExportLegends" class="content-padding-xs">
              <dx-check-box [(value)]="legend.isChecked" (valueChange)="checkIfAllLegendsSelected()" hint="Вибрати для експорту"></dx-check-box>
            </div>

            <span class="legend-icon {{legend.icon}}" [style.background-color]="legend.color">
              <img *ngIf="!legend.isRaster" src="{{legend.image}}">
              <span *ngIf="legend.isRaster" class="esri-icon-default-action"></span>
            </span>
            <span (click)="legend.isOpened=!legend.isOpened" title="{{ legend.name }}">
              <span>
                {{ legend.name }}
              </span>
            </span>
          </div>
          <div class="legend-item-content-bottom">
            <div class="legend-btn">
              <!---div ng class="icon esri-icon-plus-circled" (click)="addObject(legend)" title="Додати"></div!-->
              <span *ngIf="legend.canAddObject" class="icon esri-icon-plus-circled" (click)="addObject(legend)" title="Додати"></span>
              <span *ngIf="hasExportPermissions&&!legend.isRaster" class="icon esri-icon-download" (click)="exportData(legend.id, legend.layerID, legend)" title="Експорт"></span>
              <span *ngIf="false" class="icon esri-icon-upload">
                <input type="file" [attr.layerID]="legend.layerID" [attr.sublayerID]="legend.id" id="fileToUpload" (change)="fileEvent($event)" title="Імпорт">
              </span>
            </div>
            <dx-slider [min]="0"
                      [max]="100"
                      [step]="10"
                      [value]="legend.opacity || 100"
                      [name]="legend.id"
                      (onValueChanged)="onValueChanged($event,legend)">
            </dx-slider>
          </div>
        </div>
      </div>
    </dx-list>
  
  <section *ngIf="isExportLegends" class="flex-col gap-none">
    <hr>
    <div class="content-padding-xs">
      <dx-check-box (click)="onCheckBoxClick()" [(value)]="selectAllExport" (valueChange)="toggleAllExport($event)" (onValueChanged)="onCheckBoxClick()" text="Вибрати усі легенди для експорту" [disabled]="!isDataAvailable"></dx-check-box>
    </div>
    <hr>
    <div class="flex-row flex-end content-padding wrap">
      <dx-button
        type="normal"
        stylingMode="text"
        height="48"
        (onClick)="closeExportLegends()"
        text="Скасувати">
      </dx-button>
      <dx-button
        type="default"
        stylingMode="text"
        height="48"
        (onClick)="startExportLegends()"
        [disabled]="!isDataAvailable||!hasChecked"
        text="Експорт">
      </dx-button>
    </div>
  </section>

  <div id="legendDiv"></div>
</div>

<section class="app-toolbar toolbar flex-row align-items-center" [ngClass]="{'disableDiv': isDisabled}">

  <section *ngIf="!showCustomLayer" class="flex-row align-items-center space-between">
    <div class="flex-row align-items-center">
      <dx-button icon="lg-icon icon-circle-plus" id="pointButton" (onClick)="CustomLayer(true)" hint="Додавання власних об'єктів"></dx-button>
      
      <dx-button icon="lg-icon icon-bookmark-add" id="createBookmark" (onClick)="createBookmark()" hint="Створити закладку"></dx-button>

      <dx-button icon="lg-icon icon-filter-add" id="createFilter" (onClick)="createFilter()" hint="Створити фільтр"></dx-button>

      <dx-button icon="lg-icon icon-download" id="exportLegends" (onClick)="goToExportLegends()" [type]="isExportLegends ? 'default' : 'normal'" hint="Експорт"></dx-button>

      <dx-button *ngIf="isShowCadastralXML" icon="icon" id="exportLegends" hint="Імпорт обміного файлу">
        <i class="dx-icon lg-icon icon-upload"></i>
        <input type="file" class="input-import" id="XMLToUpload" (change)="XMLfileEvent($event)" title="Імпорт обміного файлу">
      </dx-button>
    </div>

    <app-external-charts></app-external-charts>

  </section>

  <section *ngIf="showCustomLayer && !showSettings" class="flex-row align-items-center space-between">
    <div class="flex-row">
      <div class="btn-group">
        <dx-button icon="icon esri-icon-map-pin" (onClick)="drawPoint()" id="pointButton" hint="Маркер"></dx-button>
        <dx-button icon="spindown" (onClick)="symbolSetting('point')" id="pointButtonSetting" hint="Налаштування маркера"></dx-button>
      </div>
      <div class="btn-group">
        <dx-button icon="icon esri-icon-polyline" (onClick)="drawPolyline()" id="polylineButton" hint="Лінія"></dx-button>
        <dx-button icon="spindown" (onClick)="symbolSetting('line')" id="polylineButtonSetting" hint="Налаштування лінії"></dx-button>
      </div>
      <div class="btn-group">
        <dx-button icon="icon esri-icon-polygon" (onClick)="drawPolygon()" id="polygonButton" hint="Полігон"></dx-button>
        <dx-button icon="spindown" (onClick)="symbolSetting('polygon')" id="polygonButtonSetting" hint="Налаштування полігону"></dx-button>
      </div>
    </div>
    <dx-button icon="close" (onClick)="CustomLayer(false)" id="closeBtn" hint="Закрити"></dx-button>
  </section>

  <section *ngIf="showSettings">
    <dx-popup
      title="Налаштування {{isTitleName}}"
      maxWidth="640px"
      width="100%"
      height="100%"
      position="left top"
      [showCloseButton]="false"
      [dragEnabled]="false"  
      [animation]="{ 
        hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
        show: {type: 'slideIn', direction: 'left', duration: 512 }
      }"
      [shading] ="false"
      [closeOnOutsideClick]="false"
      [visible]="true">
      <div *dxTemplate="let data of 'content'" class="flex-col">

        <dx-color-box [(value)]="CurrentColor"
                      [editAlphaChannel]="true"
                      label="Колір"
                      pplyButtonText="Готово"
                      cancelButtonText="Відміна">
        </dx-color-box>

        <div *ngIf="currentTypeSymbol=='point'">
          <dx-select-box [dataSource]="stylePointDataSource"
                          displayExpr="name"
                          valueExpr="id"
                          label="Стиль"
                          placeholder=""
                          [(value)]="currentStyle"
                          noDataText="Немає даних">
            <dx-validator>
              <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>
        
        <div  *ngIf="currentTypeSymbol=='line'">
          <dx-select-box [dataSource]="styleLineDataSource"
                          displayExpr="name"
                          valueExpr="id"
                          label="Стиль"
                          placeholder=""
                          [(value)]="currentStyleLine"
                          (onOpened)="onOpened($event)"
                          noDataText="Немає даних">
            <dx-validator>
              <dxi-validation-rule type="required" message="Обов'язкове"></dxi-validation-rule>
            </dx-validator>
          </dx-select-box>
        </div>

        <div *ngIf="currentTypeSymbol!='polygon'">
          <dx-number-box [max]="32"
                          [min]="0.1"
                          [step]="0.5"
                          label="Розмір"
                          width="128"
                          [showSpinButtons]="true"
                          [(value)]="currentWidth">
          </dx-number-box>
        </div>
      </div>
      <dxi-toolbar-item
          widget="dxButton"
          toolbar="bottom"
          location="after"
          [options]="saveButtonOptions">
      </dxi-toolbar-item>
    </dx-popup>
  </section>

</section>

import { Component, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { AzureStorageService } from '../../services/azurestorage/azure-storage.service';
import { SharedService } from '../../services/shared/shared.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

declare var Tiff: any;

@Component({
  selector: 'app-image-view',
  templateUrl: './image.view.component.html',
  styleUrls: ['./image.view.component.scss']
})
export class ImageViewComponent  {
  imageUrl: any;
  isTiff: boolean;
  isImage: boolean;
  isLoading: boolean;
  documentName: any;
  constructor(
    private azureStorageService: AzureStorageService,
    private sharedService: SharedService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.documentName = this.sharedService.currentDocumentName;
    let ext = this.sharedService.currentDocumentName.substring(this.sharedService.currentDocumentName.lastIndexOf('.') + 1);
    switch (ext.toLowerCase()) {
      case "tif":
      case "tiff":
        this.openTiff();
        break;
      case "png":
      case "jpg":
      case "jpeg":
        this.openImage();
        break;
      default:
        break;
    }
    
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
    return window.btoa(encodeURIComponent(binary));
  }

  openTiff() {
    this.isLoading = true;
    this.azureStorageService.getBlobOnly(this.sharedService.currentDocumentContainer, this.sharedService.currentDocumentName).then(result => {

      result.arrayBuffer().then(_bufffer => {
        //let _size = this.sharedService.RegionSizeFile ? this.sharedService.RegionSizeFileByte : 1000000000; // 125 MB // _bufffer.byteLength * 8;
        if (_bufffer.byteLength > this.sharedService.RegionSizeFileByte) {          
          notify(`"Не можливо відкрити файл більше ${this.sharedService.RegionSizeFile} МБ`, "error", 4000);
          this.isLoading = false;
          return;
        }
        Tiff.initialize({ TOTAL_MEMORY: this.sharedService.RegionSizeFileByte });
        const tiff = new Tiff({ buffer: _bufffer });
        var canvas = tiff.toCanvas();
        canvas.style.maxWidth = '100%';
        canvas.style.maxHeight = '100%';
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");        
        this.isLoading = false;
        this.isImage = true;
        this.imageUrl = this.sanitize(image);
      }).catch(() => {
        notify("Не можливо відкрити файл", "error", 4000);
        this.isLoading = false;
      })    
    });
  }

  openImage() {
    this.isLoading = true;
    this.azureStorageService.getBlobUrl(this.sharedService.currentDocumentContainer, this.sharedService.currentDocumentName).then(result => {
      this.isLoading = false;
      this.isImage = true;
      this.imageUrl = result;
    })
  }

  download() {
    this.azureStorageService.getBlob(this.sharedService.currentDocumentContainer, this.sharedService.currentDocumentName).then(() => {

    });
  }
  //arrayBufferToBase64(ab) {
  //  const uint = new Uint8Array(ab).buffer;
  //  const decoder = new TextDecoder()
  //  const decodedText = decoder.decode(uint)
  //  const base64Code = btoa(encodeURIComponent(decodedText));
  //  return base64Code;


  //var dView = new Uint8Array(ab);   //Get a byte view        

  //var arr = Array.prototype.slice.call(dView); //Create a normal array        

  //var arr1 = arr.map(function (item) {
  //  return String.fromCharCode(item);    //Convert
  //});

  //return window.btoa(arr1.join(''));   //Form a string

//}

  goBack() {
    this.router.navigate(['./', { outlets: { 'viewrouter': null } }], { skipLocationChange: true });
  }
  //ngOnDestroy() {

  //}


}

import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { SquareUnits } from "../../models/draw/square-units.model";

@Injectable()
export class DrawMeasureService {

  constructor(private sharedService: SharedService, private esriService: EsriService) {
    
  }

  createGraphicsLayers() {
    if (!this.sharedService.MeasureLayer) {
      this.sharedService.MeasureLayer = new this.esriService.GraphicsLayer(
        {
          title: 'Точки'
        }
      );
    }
    if (!(this.sharedService.map as __esri.Map).findLayerById(this.sharedService.MeasureLayer.id)) {
      this.sharedService.map.add(this.sharedService.MeasureLayer);
    }

    if (!this.sharedService.MeasureLabelLayer) {
      this.sharedService.MeasureLabelLayer = new this.esriService.GraphicsLayer(
        {
        title: 'Замри'
      });
    }
    if (!(this.sharedService.map as __esri.Map).findLayerById(this.sharedService.MeasureLabelLayer.id)) {
      this.sharedService.map.add(this.sharedService.MeasureLabelLayer);
    }

    if (!this.sharedService.MeasureMarkerLayer) {
      this.sharedService.MeasureMarkerLayer = new this.esriService.GraphicsLayer(
        {
          title: 'Точки'
        });
    }
    if (!(this.sharedService.map as __esri.Map).findLayerById(this.sharedService.MeasureMarkerLayer.id)) {
      this.sharedService.map.add(this.sharedService.MeasureMarkerLayer);
    }    
  }


  setLabelLayer(value: boolean) {
    this.sharedService.MeasureLabelLayer.visible = value;
  }

  getCenterPoint(_point, _point2) {
    let x_start = _point.x > _point2.x ? _point2.x : _point.x;
    let x_end = _point.x > _point2.x ? _point.x : _point2.x;
    let x_center = (x_end - x_start) / 2 + x_start;

    let y_start = _point.y > _point2.y ? _point2.y : _point.y;
    let y_end = _point.y > _point2.y ? _point.y : _point2.y;
    let y_center = (y_end - y_start) / 2 + y_start;;

    let centerPoint = new this.esriService.Point(
      {
        spatialReference: this.sharedService.mapView.spatialReference,
        x: x_center,
        y: y_center
      }
    )
    return centerPoint;
  }

  labelMarker(point) {
    var symbolPoint = {
      labelType: true,
      type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
      style: "square",
      color: [255, 255, 255, 0.26],
      size: "8px",  // pixels
      outline: {  // autocasts as new SimpleLineSymbol()
        color: [255, 74, 255],
        width: 2  // points
      }
    };
    let copyPoint = point.clone();
    copyPoint.z = 5;
    let symbolGraphic = new this.esriService.Graphic({
      geometry: copyPoint,
      symbol: symbolPoint
      //visible: false
    });

    return symbolGraphic;

  }

  labelDistance(centerpoint, area) {

    let textSymbol = {
      labelType: true,
      type: "text",  // autocasts as new TextSymbol()
      color: [255, 255, 255],
      text: area.toFixed(2),
      xoffset: 3,
      yoffset: 3,
      font: {  // autocast as new Font()
        family: 'Arial',
        size: 16
      }
    };

    let graphic = new this.esriService.Graphic({
      geometry: centerpoint,
      symbol: textSymbol
    });

    return graphic;          
  }

  removeGraphic() {

    if (this.sharedService.polylineDrawingValue && this.sharedService.polygonDraw.activeAction.vertices.length <= 1) {
      this.sharedService.polygonDraw.reset();
      
    } else if (this.sharedService.polylineDrawingValue && this.sharedService.polygonDraw.activeAction.vertices.length <= 2) {
      this.sharedService.polygonDraw.reset();
      
    } else {
      this.sharedService.polygonDraw.activeAction.complete();
    }
    this.sharedService.isShowSwitcher = false;
    this.sharedService.setPolylineDrawing(false);
    this.sharedService.setPolygonDrawing(false);
    this.sharedService.setStartedDrawing(false);
    this.sharedService.MeasureLabelLayer.removeAll();
    this.sharedService.MeasureLayer.removeAll();
    this.sharedService.MeasureMarkerLayer.removeAll();
    (this.sharedService.map as __esri.Map).removeMany([this.sharedService.MeasureLabelLayer, this.sharedService.MeasureLayer, this.sharedService.MeasureMarkerLayer]);

  }
  
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription, first } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../basecomponent/base.component';
import { UserBookmark } from '../../../models/bookmarks/bookmark.model';
import { FeatureLayerService } from '../../../services/map/feature-layer.service';
import { BookmarksService } from '../../../services/bookmarks/bookmarks.service';
import { UserService } from '../../../services/user/user.service';
import { SharedService } from '../../../services/shared/shared.service';
import { DocumentUploadComponent } from '../../documentupload/document.upload.component';
import { AzureStorageService } from '../../../services/azurestorage/azure-storage.service';



@Component({
  selector: 'app-bookmarkedit',
  templateUrl: './bookmarkedit.component.html',
  styleUrls: ['./bookmarkedit.component.css']
})
export class BookmarkEditComponent extends BaseComponent implements OnInit, OnDestroy {

  bookmarkEdit: UserBookmark = new UserBookmark();
  documentContainerName: string = "test";
  listDocuments: any[];
  isSaving: boolean;
  regionSubscription: Subscription;

  @ViewChild('documentUpload', { static: false }) documentUpload: DocumentUploadComponent;

  closeButtonOptions: Record<string, unknown>;
  saveButtonOptions: Record<string, unknown>;

  constructor(
    private featureLayerService: FeatureLayerService,
    private bookmarksService: BookmarksService,
    private userService: UserService,
    private sharedService: SharedService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private azureStorageService: AzureStorageService,
  ) {
    super();

    this.closeButtonOptions = {
      text: 'Скасувати',
      stylingMode: 'text',
      type: 'normal',
      height: '48',
      onClick: this.goBack.bind(this),
    };

    this.saveButtonOptions = {
      text: 'Зберегти',
      stylingMode: 'text',
      type: 'default',
      height: '48',
      onClick: this.save.bind(this),
    };
  }

  ngOnInit() {
    let bookmarkID = this.activatedRoute.snapshot.params['id'];
    let parentBookmarkID = this.activatedRoute.snapshot.params['parentid'];
    this.regionSubscription = this.sharedService.getRegionID().subscribe(regId => {
      if (bookmarkID) {
        this.bookmarksService.getUserBookmark(bookmarkID).then(result => {
          if (result.regionID != regId) {
            this.goBack();
          }
          this.bookmarkEdit = result;
          this.documentContainerName = result.id;
          this.documentUpload.containerName = this.documentContainerName;
          this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(list => {
            this.listDocuments = list;
          })
        })
      } else {

      }
    })    
    
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.regionSubscription?.unsubscribe();
  }

  goBack() {
    this.router.navigate(["/bookmarks"]);
    notify(`Відміна редагування закладки`, "info", 3000);
  }

  save() {
     

      let bookmark = this.featureLayerService.collectBookmark(this.bookmarkEdit);

      this.bookmarksService.saveUserBookmark(bookmark).then(result => {
        notify(`Зміни закладки \"${bookmark.name}\" успішно збережені`, "success", 3000);

        this.sharedService.getUserBookmarkList().pipe(first()).subscribe(list => {
          let bookmarkID = (bookmark.isStory && bookmark.parentID) ? bookmark.parentID : bookmark.id;

          //let index = list.findIndex(z => z.id == bookmark.id);
          let index = list.findIndex(z => z.id == bookmarkID);
          if (index >= 0) {
            let childItem = bookmark.isStory && bookmark.parentID ? list[index].bookmarks?.findIndex(f => f.id == bookmark.id) : -1;

            let item = childItem >= 0 ? list[index].bookmarks[childItem] : list[index];
            bookmark.isSelected = item.isSelected;
            bookmark.isExpanded = true;
            if (childItem >= 0) {
              list[index].bookmarks[childItem] = bookmark;
            } else {
              list[index] = bookmark;
            }

            if (bookmark.isSelected) {
              this.sharedService.setSelectedBookmark(bookmark);
            }
          }
        })
      }).catch(ex => {
        notify(`Під час збереження закладки сталася помилка.\r\n Помилка: "${ex._body}"`, "error", 3000);
      })

    this.router.navigate(["/bookmarks"]);
    
  }

  refreshFiles(obj?) {
    this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      this.listDocuments = result;
      this.clearImageSource();
    })
  }

  deleteDoc(doc) {
    this.azureStorageService.deleteBlob(this.documentContainerName, doc).then(() => {
      this.refreshFiles();
      //this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      //  this.listDocuments = result;
      //})
    });
  }

  downloadDoc(doc) {
    this.azureStorageService.getBlob(this.documentContainerName, doc).then(() => {
      //this.azureStorageService.listBlobsByContainer(this.documentContainerName).then(result => {
      //  this.listDocuments = result;
      //})
      this.refreshFiles();
    });
  }

  private clearImageSource() {
    this.sharedService.getUserBookmarkList().subscribe(result => {
      let index = result.findIndex(f => f.id == this.bookmarkEdit.id && f.imageSource);
      if (index >= 0) {
        result[index].imageSource = null;
      }
    })
  }

}

import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { EsriService } from "../../esri/js-esri.service";

@Injectable()
export class AppResolveService implements Resolve<any> {

  constructor(private esriLoader: EsriService) { }

  resolve() {
    return this.esriLoader.resolve();
  }
}

import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Layer } from "../../models/layers/layer.model";
import { Attributes } from "../../models/layers/attributes";
import { LayerData, LayerDataWithAttr } from "../../models/layers/layer.data.model";
import { SquareUnits } from "../../models/draw/square-units.model";
import { FlashWatch, Legend, LegendInfo, LegendItem } from "../../models/layers/legend.model";
import { Filter } from '../../models/filters/filter.model';
import { PublicBookmark, UserBookmark } from '../../models/bookmarks/bookmark.model';
import { FeatureLayerInfo } from '../../models/layers/feature-layer.info.model';

@Injectable()
export class SharedService {
  
  constructor() { }
  homeButton: __esri.Home;
  overviewMap: any;

  private _map: any;

  get map() {

    return this._map;
  }
  set map(value) {

    this._map = value;

  }

  private _mapView: __esri.MapView;

  get mapView(): __esri.MapView {

    return this._mapView;
  }
  set mapView(value) {

    this._mapView = value;

  }


  private _event: any;

  get event() {

    return this._event;
  }

  set event(value) {

    this._event = value;
  }

  private _currentLayerId: any;

  get currentLayerId() {

    return this._currentLayerId;
  }

  set currentLayerId(value) {

    this._currentLayerId = value;
  }



  private _layerInfo: Array<any> = new Array<any>();

  get layerInfo() {

  return this._layerInfo;
  }
  set layerInfo(value) {

  this._layerInfo = value;

  }

  //let layerInfo: Array<any> = new Array<any>();

  private regionID: string = "";
  private currentRegionID = new BehaviorSubject<string>(this.regionID);

  setRegionID(regionId: string) {
    this.currentRegionID.next(regionId);
  }

  getRegionID(): Observable<any> {
    return this.currentRegionID.asObservable();
  }

  getRegionIDValue(): string {
    return this.currentRegionID.getValue();
  }

  private currentRegionCode: string;

  get CurrentRegionCode() {
    return this.currentRegionCode;
  }

  set CurrentRegionCode(value) {
    this.currentRegionCode = value;
  }

  private regionAmountFiles: number;
  private _defaultAmoutFiles: number = 10;
  get RegionAmountFiles() {
    return this.regionAmountFiles ? this.regionAmountFiles : this._defaultAmoutFiles;
  }

  set RegionAmountFiles(value) {
    this.regionAmountFiles = value;
  }

  private regionSizeFile: number;
  private _defaultSizeFile: number = 5;

  get RegionSizeFile() {
    return this.regionSizeFile ? this.regionSizeFile : this._defaultSizeFile;
  }

  set RegionSizeFile(value) {
    this.regionSizeFile = value;
  }

  get RegionSizeFileByte() {
    return (this.regionSizeFile ? this.regionSizeFile : this._defaultSizeFile) * Math.pow(1024, 2);
  }

  private regionSizeStorage: number;

  get RegionSizeStorage() {
    return this.regionSizeStorage;
  }

  set RegionSizeStorage(value) {
    this.regionSizeStorage = value;
  }
  
 /////////////////////////////////////////////////////////////////
  private layers: Layer[] = [];
  private selectedLayers = new BehaviorSubject<Layer[]>(this.layers);

  setLayers(layers: Layer[]) {
    this.selectedLayers.next(layers);
  }
 
  clearLayers() {
    this.selectedLayers.next(this.layers);
  }
 
  getLayers(): Observable<Layer[]> {
    return this.selectedLayers.asObservable();
  }

  getLayerList() {
    return this.selectedLayers;
  }

  ///////////////////////////////////////////////////////////////////////
  private baseMapThumbnailUrl: string = "../../assets/images/world_street_map.jpg";// "../../assets/images/map.jpg";
  private baseMapThumbnailUrlSelected = new BehaviorSubject<string>(this.baseMapThumbnailUrl);

  setThumbnailUrl(thumbnailUrl: string) {
    this.baseMapThumbnailUrlSelected.next(thumbnailUrl);
  }

  //clearThumbnailUrl() {
  //  this.baseMapThumbnailUrlSelected.next(this.baseMapThumbnailUrl);
  //}

  getThumbnailUrl(): Observable<string> {
    return this.baseMapThumbnailUrlSelected.asObservable();
  }

  private _layerData = new BehaviorSubject<LayerDataWithAttr[]>([]);

  getlayerData(): Observable<LayerDataWithAttr[]> {

    return this._layerData;//.asObservable();
  }
  setlayerData(value: LayerDataWithAttr[]) {

    this._layerData.next( value);

  }

  getlayerDataValue() {
    return this._layerData.getValue();
  }


  /////////////////////////////////////////////////////////////////
  private attributes: Attributes[] = [];
  private selectedAttributes = new BehaviorSubject<Attributes[]>(this.attributes);

  setAttributes(attributes: Attributes[]) {

    this.selectedAttributes.next(attributes);
  }

  clearAttributes() {
    this.selectedAttributes.next(this.attributes);
  }

  getAttributes(): Observable<any> {
    return this.selectedAttributes.asObservable();
  }

  private _polygonDraw: any;

  get polygonDraw() {

    return this._polygonDraw;
  }
  set polygonDraw(value) {

    this._polygonDraw = value;

  }  
  /////////////////////////////////////////////////////////////////////

  public isShowSwitcher: boolean = false;

  private startedDrawing = new BehaviorSubject<boolean>(false);

  setStartedDrawing(value: boolean) {
    this.startedDrawing.next(value);
  }

  getStartedDrawing(): Observable<boolean> {
    return this.startedDrawing.asObservable();
  }


  private polylineDrawing = new BehaviorSubject<boolean>(false);

  setPolylineDrawing(value: boolean) {
    this.polylineDrawing.next(value);
  }

  isPolylineDrawing(): Observable<boolean> {
    return this.polylineDrawing.asObservable();
  }

  polylineDrawingValue(): boolean {
    return this.polylineDrawing.getValue();
  }

  private polygonDrawing = new BehaviorSubject<boolean>(false);

  setPolygonDrawing(value: boolean) {
    this.polygonDrawing.next(value);
  }

  isPolygonDrawing(): Observable<boolean> {
    return this.polygonDrawing.asObservable();
  }

  polygonDrawingValue(): boolean {
    return this.polygonDrawing.getValue();
  }

  private showMeasureDistance = new BehaviorSubject<boolean>(true);

  setMeasureDistance(value: boolean) {
    this.showMeasureDistance.next(value);
  }

  ShowMeasureDistance(): Observable<boolean> {
    return this.showMeasureDistance.asObservable();
  }

  private currentSquareUnit = new BehaviorSubject<SquareUnits>(SquareUnits.meter);

  setCurrentSquareUnit(value: SquareUnits) {
    this.currentSquareUnit.next(value);
  }
 
  getCurrentSquareUnit(): Observable<SquareUnits> {
    return this.currentSquareUnit.asObservable();
  }

  currentSquareUnitValue() {
    return this.currentSquareUnit.getValue();
  }

  private measureLayer: __esri.GraphicsLayer;

  get MeasureLayer() {
    return this.measureLayer;
  }

  set MeasureLayer(value) {
    this.measureLayer = value;
  }

  private measureLabelLayer: __esri.GraphicsLayer;

  get MeasureLabelLayer() {
    return this.measureLabelLayer;
  }

  set MeasureLabelLayer(value) {
    this.measureLabelLayer = value;
  }

  private measureMarkerLayer: __esri.GraphicsLayer;

  get MeasureMarkerLayer() {
    return this.measureMarkerLayer;
  }

  set MeasureMarkerLayer(value) {
    this.measureMarkerLayer = value;
  }

  ///////////////////////////////////////////////////////////////////////

  private selectedFeature = new BehaviorSubject<__esri.Graphic>(null);

  setSelectedFeature(value: __esri.Graphic) {
    this.selectedFeature.next(value);
  }

  getSelectedFeature(): Observable<__esri.Graphic> {
    return this.selectedFeature.asObservable();
  }

  SelectedFeatureValue() {
    return this.selectedFeature.getValue();
  }

  private watchFlashList: FlashWatch[] = [];

  get WatchFlashList() {
    return this.watchFlashList;
  }

  set WatchFlashList(value) {
    this.watchFlashList = value;
  }

  //////////////////////////////////////////////////////////////////////

  //private _isLoading = new BehaviorSubject<boolean>(false);

  //getIsLoading(): Observable<boolean> {
  //  return this._isLoading.asObservable()
  //}

  //setIsLoading(value: boolean) {
  //  this._isLoading.next(value);
  //}


  ///////////////////////////////////////////////////////////////////////

  public IsShowedCadastre: boolean = false;

  ///////////////////////////////////////////////////////////////////////

  private _IsShowedCustomLayer = new BehaviorSubject<boolean>(false);

  getIsShowedCustomLayer(): Observable<boolean> {
    return this._IsShowedCustomLayer.asObservable()
  }

  setIsShowedCustomLayer(value: boolean) {
    this._IsShowedCustomLayer.next(value);
  }

  private _IsCheckedCustomLayer = new BehaviorSubject<boolean>(false);

  getIsCheckedCustomLayer(): Observable<boolean> {
    return this._IsCheckedCustomLayer.asObservable()
  }

  setIsCheckedCustomLayer(value: boolean) {
    this._IsCheckedCustomLayer.next(value);
  }

  getIsCheckedCustomLayerValue() {
    return this._IsCheckedCustomLayer.getValue();
  }

  private _IsEditCustomLayer = new BehaviorSubject<boolean>(false);

  getIsEditCustomLayer(): Observable<boolean> {
    return this._IsEditCustomLayer.asObservable()
  }

  setIsEditCustomLayer(value: boolean) {
    this._IsEditCustomLayer.next(value);
  }

  private _showBookmark = new BehaviorSubject<boolean>(false);

  getShowBookmark(): Observable<boolean> {
    return this._showBookmark.asObservable();
  }

  setShowBookmark(value: boolean) {
    this._showBookmark.next(value);
  }

  ShowBookmarkValue() {
    return this._showBookmark.getValue();
  }

  /////////////////////////////////////////
  private legends = new BehaviorSubject<LegendItem[]>(null);

  setLegends(value: LegendItem[]) {
    this.legends.next(value);
  }

  getLegends(): Observable<LegendItem[]> {
    return this.legends.asObservable();
  }

  getLegendsValue() {
    return this.legends.getValue();
  }

  _publicLegends: LegendItem[] = [];

  get publicLegends() {
    return this._publicLegends;
  }

  set publicLegends(value) {
    this._publicLegends = value;
  }

  private _publicLegendsChanged = new BehaviorSubject<boolean>(false);

  getPublicLegendChanged(): Observable<boolean> {
    return this._publicLegendsChanged.asObservable();
  }

  setPublicLegendChanged(value: boolean) {
    this._publicLegendsChanged.next(true);
  }

  privateLegends: LegendItem[] = [];

  /////////////////////////////////////////
  listFilter: Filter[] = [];

  private currentFilter = new BehaviorSubject<Filter>(null);

  getCurrentFilter(): Observable<Filter> {
    return this.currentFilter.asObservable();
  }

  setCurrentFilter(value: Filter) {

    this.ShowFilterSettings = (value && value.userID) ? true : false;
     
    this.currentFilter.next(value);
  }

  getCurrentFilterValue(): Filter {
    return this.currentFilter.getValue();
  }

  private _showFilterSettings: boolean = false;
  get ShowFilterSettings(): boolean {

    return this._showFilterSettings;
  }

  set ShowFilterSettings(value: boolean){

    this._showFilterSettings = value;
  }

  /////////////////////////////////////////
  private publicLegendsTitle = new BehaviorSubject<string>(null);

  setPublicLegendsTitle(value: string) {
    this.publicLegendsTitle.next(value);
  }

  getPublicLegendsTitle(): Observable<string> {
    return this.publicLegendsTitle.asObservable();
  }

  getPublicLegendsTitleValue() {
    return this.publicLegendsTitle.getValue();
  }

  private publicStoryTitle = new BehaviorSubject<string>(null);

  getPublicStoryTitle(): Observable<string> {
    return this.publicStoryTitle.asObservable();
  }

  setPublicStoryTitle(value: string) {
    this.publicStoryTitle.next(value);
  }


  ///////////////////////////////////////

  private _showPanel = new BehaviorSubject<boolean>(true);

  getShowPanel(): Observable<boolean> {
    return this._showPanel.asObservable();
  }

  setShowPanel(value: boolean) {
    this._showPanel.next(value);
  }

  ////////////////////////////////////////
  private _refreshNavigationList = new BehaviorSubject<boolean>(false);

  getRefreshNavigationList(): Observable<boolean> {
    return this._refreshNavigationList.asObservable();
  }

  setRefreshNavigationList(value: boolean) {
    this._refreshNavigationList.next(value);
  }

  ////////////////////////////////////////
  private _refreshNavigationItem = new BehaviorSubject<any>(null);

  getRefreshNavigationItem(): Observable<any> {
    return this._refreshNavigationItem.asObservable();
  }

  setRefreshNavigationItem(value: any) {
    this._refreshNavigationItem.next(value);
  }

  /////////////////////////////////////////
  
  private legendInfoList: LegendInfo[] = [];

  get LegendInfoList(): LegendInfo[] {
    return this.legendInfoList;
  }

  set LegendInfoList(value: LegendInfo[]) {
    this.legendInfoList = value;
  }

  private _featureLayerInfoList: FeatureLayerInfo[] = [];

  get featureLayerInfoList(): FeatureLayerInfo[] {
    return this._featureLayerInfoList;
  }

  set featureLayerInfoList(value: FeatureLayerInfo[]) {
    this.featureLayerInfoList = value;
  }

  publicGroup: boolean = false;

  groupBookmarks: PublicBookmark[] = [];

  private publicBookmarkGroup = new BehaviorSubject<PublicBookmark[]>(null);

  getPublicBookmarkGroup(): Observable<PublicBookmark[]> {
    return this.publicBookmarkGroup.asObservable();
  }

  setPublicBookmarkGroup(value: PublicBookmark[]) {
    this.publicBookmarkGroup.next(value);
  }

  ////////////////
  public ParentStoryBookmarkID: string;

  private storyBookmars = new BehaviorSubject<PublicBookmark[]>(null);

  getStoryBookmars(): Observable<PublicBookmark[]> {
    return this.storyBookmars.asObservable();
  }

  setStoryBookmars(value: PublicBookmark[]) {
    this.storyBookmars.next(value);
  }

  ////////////////
  private _isShowBookmarkApp: boolean;
  get isShowBookmarkApp(): boolean {
    return this._isShowBookmarkApp;
  }

  set isShowBookmarkApp(value: boolean) {
    this._isShowBookmarkApp = value;
  }
  private showGroupBookmark = new BehaviorSubject<boolean>(false);

  getShowGroupBookmark(): Observable<boolean> {
    return this.showGroupBookmark.asObservable();
  }

  setShowGroupBookmark(value: boolean) {
    this.showGroupBookmark.next(value);
  }


  private publicBookmarkID: string;

  get PublicBookmarkID() {
    return this.publicBookmarkID;
  }

  set PublicBookmarkID(value) {
    this.publicBookmarkID = value;
  }

  private publicBookmarkGroupID: string;

  get PublicBookmarkGroupID() {
    return this.publicBookmarkGroupID;
  }

  set PublicBookmarkGroupID(value) {
    this.publicBookmarkGroupID = value;
  }

  private bookmarkChanged = new BehaviorSubject<string>("");

  setBookmarkChanged(value: string) {
    this.bookmarkChanged.next(value);
  }

  getBookmarkChanged(): Observable<string> {
    return this.bookmarkChanged.asObservable();
  }

  ///////////////////////////////////////////////////////////////

  private selectedBookmark = new BehaviorSubject<UserBookmark>(null);

  getSelectedBookmark(): Observable<UserBookmark> {
    return this.selectedBookmark.asObservable();
  }

  setSelectedBookmark(value: UserBookmark) {
    this.selectedBookmark.next(value);
  }

  getSelectedBookmarkValue() {
    return this.selectedBookmark.getValue();
  }

  private userBookmarkList = new BehaviorSubject<UserBookmark[]>(null);

getUserBookmarkList(): Observable < UserBookmark[] > {
  return this.userBookmarkList.asObservable();
  }

  getUserBookmarListValue() {
    return this.userBookmarkList.getValue();
  }
  setUserBookmarkList(value: UserBookmark[]) {
    this.userBookmarkList.next(value);
  }

  private isUserBookmarksInit = new BehaviorSubject<boolean>(false);

  getUserBookmarksInit(): Observable<boolean> {
    return this.isUserBookmarksInit.asObservable();
  }

  setUserBookmarksInit(value: boolean) {
    this.isUserBookmarksInit.next(value);
  }

  getUserBookmarksInitValue() {
    return this.isUserBookmarksInit.getValue();
  }
  /////////////////////////////////////

  private navigationMenuWidth = new BehaviorSubject<number>(0);

  setNavigationMenuWidth(value: number) {
    this.navigationMenuWidth.next(value);
  }

  getNavigationMenuWidth(): Observable<number> {
    return this.navigationMenuWidth.asObservable();
  }

  private searchInit = new BehaviorSubject<boolean>(false);

  getSearchInit() {
    return this.searchInit.asObservable();
  }

  setSearchInit(value: boolean) {
    this.searchInit.next(value);
  }

  private _hiddenSearching = new BehaviorSubject<boolean>(false);

  getHiddenSearching() {
    return this._hiddenSearching.asObservable();
  }

  setHiddenSearching(value: boolean) {
    this._hiddenSearching.next(value);
  }

  private _isGeometryCalculator = new BehaviorSubject<boolean>(false);
  isGeometryCalculator$ = this._isGeometryCalculator.asObservable();

  setIsGeometryCalculator(value: boolean) {
    this._isGeometryCalculator.next(value);
  }

  private _isExportLegends = new BehaviorSubject<boolean>(false);
  isExportLegends$ = this._isExportLegends.asObservable();
  
  setIsExportLegends(value: boolean) {
    this._isExportLegends.next(value);
  }

  exportLegendsValue(): boolean {
    return this._isExportLegends.getValue();
  }

  /////////////////////////////////////////////////////////////////
  currentDocumentContainer: string;
  currentDocumentName: string;
}

import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";
import * as FileSaver from 'file-saver';
import { PrintFileEndpoint } from "../print/print-file.end-point.service";
import notify from 'devextreme/ui/notify';
import { LayerDataAttribute } from '../../models/layers/layer-data-attribute.models';
import { LegendItem } from '../../models/layers/legend.model';
import { LegendsService } from '../legends/legends.service';

@Injectable()
export class ExportGeodataService {

  constructor(private sharedService: SharedService, private esriService: EsriService, private configService: ConfigService,
    private printFileEndpoint: PrintFileEndpoint,
    private legendService: LegendsService
  ) {
    
  }

  exportGeodataById(Id, layerId, url, subQuery?, subTypeFieldName?) {

    let layer: __esri.Layer = (this.sharedService.map as __esri.Map).findLayerById(layerId);
    let foundLayer: __esri.FeatureLayer | __esri.Sublayer
    if ((layer as __esri.MapImageLayer).type == 'map-image' && (layer as __esri.MapImageLayer).sublayers) {
      //let sublayer = (layer as __esri.MapImageLayer).sublayers.find(x => x.id == Id);
      foundLayer = (layer as __esri.MapImageLayer).sublayers.find(x => x.id == Id);
    }
    else if (layer?.type == 'feature') {
      foundLayer = layer as __esri.FeatureLayer;
    }

    if (layer) {
      let docum = (subTypeFieldName ? subTypeFieldName : layer.get<string>("name")) + '.xls';
      var self = this;
      return this.exportGeodata(foundLayer, url, subQuery).then((resultData) => {
        let url = resultData.results[0].value.url;
        console.debug("exportGeodataById - FileSaver.saveA")
        self.printFileEndpoint.getPrintedFileEndpoint(url).subscribe(
          (response) => {
            return FileSaver.saveAs(response, docum);
          }
        );
        return;
      }).catch(ex => {
        console.log(`Помилка при завантажені даних.\r\n  ${ex._body}`);
      })
    }
  }

  exportGeodata(layer: __esri.Sublayer | __esri.FeatureLayer, layerUrl, subQuery?) {
    let url = this.configService.ExportGeodataServiceUrl +'?token=' + this.configService.getGisToken();

    let gp: __esri.Geoprocessor = new this.esriService.Geoprocessor(url);
    gp.outSpatialReference = this.sharedService.mapView.spatialReference;    
    let docum = layer.get<string>("name") + '.xls';    

    var self = this;      
    var query: __esri.Query = new this.esriService.Query();
    query.returnGeometry = true;
    //let filterKOATUU = layer.get<string>('filterKOATUU');
    //let filterSUbType = layer.get<string>("subtypeFieldName") + "=" + subTypeId;
    //let subQuery = (filterKOATUU ? filterKOATUU : '') + (filterSUbType ? (filterKOATUU ? ' and ' : '') + filterSUbType : '');
    if (subQuery) {
      query.where = subQuery;
    } else {
      query.where = '1=1' + (layer.definitionExpression ? ' and ' + layer.definitionExpression : '');
    }

    query.outFields = this.getAttributes(layer.get<any>("layerAttributes"));//["*"];
    let featureLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer();
    featureLayer.url = layerUrl;

    return featureLayer.queryFeatures(query)
      .then((results) =>
      {
        if (results) {
          let params = {
            "Input_Table": results,
            "Output_Excel_File": docum,
            "Use_field_alias_as_column_header": "true",
            "Use_domain_and_subtype_description": "true"
          };
          return gp.execute(params); 
        }   
      })     
      .catch(ex => {
        console.log(`Помилка при експорті даних.\r\n  ${ex._body}`);
        return null;
    })      
  }

  public getAttributes(attributes: LayerDataAttribute[]): any[] {

    let result = [];
    attributes.filter(f => f.showed || f.editable || f.inMini).forEach(x => {
      result.push(x.name);
    })
    return result;


  }

}

import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";

@Injectable()
export class SwipeService {

  constructor(private sharedService: SharedService) {
    console.log("SearchService");
  }

  private swipe: any;

  getScalebar() {
    if (!this.swipe) {

      //this.esriLoader.loadModules([Constants.layerSwipe]).then(([result]) => {
      //  //this.swipe = new LayerSwipe({
      //  //  type: "vertical",  //Try switching to "scope" or "horizontal"  
      //  //  map: this.sharedService.map,
      //  //  //layers: [Hurricanes]
      //  //}, Constants.swipeId);

      //  //this.swipe.startup();
      //  //console.log("swipe.startup");

      //  //this.swipe.on("error", msg => {
      //  //  console.log("swipe error:  ", msg);
      //  //});      
      //});
    }
  }

}

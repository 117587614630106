<div #calculateGeometryWidgetID class="card">
  <dx-popover target="#btnInfo" showEvent="click" position="top">
    <div *dxTemplate="let data = model; of: 'content'" class="content-padding">
      <p>Розрахунок проводиться у системі координат <strong><abbr title="World Geodetic System">WGS</abbr> 1984 Web Mercator (Auxiliary Sphere)</strong></p>
    </div>
  </dx-popover>
  <div class="content-padding-xs flex-row gap-sm align-items-center border-bottom flex-end wrap">
    <dx-button icon="icon esri-icon-description" id="btnInfo" hint="Система координат">
    </dx-button>
    <div class="flex-row align-items-center">
      <dx-switch [value]="!isSquareMeters" (valueChange)="onSwitchChange(!$event)">
      </dx-switch>
      <p class="switch-label-off">м</p>
      <p class="switch-label">га</p>
    </div>
    <dx-button icon="icon esri-icon-lasso"
                (onClick)="selectArea()"
                id="btnSelectArea" 
                hint="Виділити область на карті"
                [type]="isSelectAreaTool ? 'default' : 'normal'">
    </dx-button>
    <dx-button *ngIf="false"  icon="icon esri-icon-collection" (onClick)="selectAll()" id="btnSelectAll" hint="Вибрати всі об`єкти">
    </dx-button>
    <dx-button icon="icon icon-eraser filled" (onClick)="unselectAll()" id="btnUnselectAll" hint="Зняти виділення всіх об`єктів">
    </dx-button>
    <dx-button icon="close" (onClick)="close()" id="btnClouse" hint="Закрити">
    </dx-button>
  </div>
  <div class="flex-col gap-none content-padding-xs">
    <p>
      <span class="body-sm">
        Площа:
      </span>
      <span class="body-md">
        {{totalArea}}
      </span>
      <span class="body-sm" *ngIf="(calculateUnit === 'square-meters')">
        м²
      </span>
      <span class="body-sm" *ngIf="(calculateUnit === 'hectares')">
        га
      </span>
    </p>
    <p>
      <span class="body-sm">
        Довжина:
      </span>
      <span class="body-md">
        {{totalLength}}
      </span>
      <span class="body-sm" *ngIf="(calculateUnit === 'square-meters')">
        м
      </span>
      <span class="body-sm" *ngIf="(calculateUnit === 'hectares')">
        км
      </span>
    </p>
    <p>
      <span class="body-sm">
        Кількість:
      </span>
      <span class="body-md">
        {{amount}}
      </span>
    </p>
  </div>
</div>

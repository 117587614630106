import { Component, OnDestroy, OnInit } from '@angular/core';
import { DrawPolylineService } from "../../../services/draw/draw-polyline.service";
import { Subscription } from "rxjs";
import { SharedService } from "../../../services/shared/shared.service";

@Component({
  selector: 'app-polyline',
  templateUrl: './polyline.component.html',
  styleUrls: ['./polyline.component.css']
})
export class PolylineComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  isShowed: boolean = false;
  constructor(private drawPolylineService: DrawPolylineService, private sharedService: SharedService) { }

  ngOnInit() {

    this.drawPolylineService.getPolygonBtn();
    this.subscription = this.sharedService.isPolylineDrawing().subscribe(value => {
      this.isShowed = value;
    });
    
  }

  startDraw() {
    this.drawPolylineService.startDraw();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

}

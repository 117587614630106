//export enum SquareUnits {
//  meter = 1,
//  hectare = 2
//}

const SquareUnits = {
  meter: 'square-meters' as 'square-meters',
  hectares: 'hectares' as 'hectares'
}
type SquareUnits = (typeof SquareUnits)[keyof typeof SquareUnits];
export { SquareUnits };

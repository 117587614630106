
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EndpointFactory } from '../shared/endpoint-factory.service';

@Injectable()
export class DashboardSettingEndpoint extends EndpointFactory {

  private readonly _getByRegionUrl: string = "/api/dashboardsetting/byregion";
  
  get getByRegionUrl() { return this.configService.getApiURI() + this._getByRegionUrl; }


  getByRegionEndpoint(regionId: string): Observable<Response> {

    let endpointUrl = `${this.getByRegionUrl}/${regionId}`;

    return this.http.get<any>(endpointUrl, this.getAuthHeader()).pipe(
      catchError(error => {
        return this.handleError(error, () => this.getByRegionEndpoint(regionId));
      }),);
  }


}

import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { DrawMeasureService } from "./draw-measure.service";
import { timer } from 'rxjs'

@Injectable()
export class DrawPolylineService {

  constructor(private sharedService: SharedService, private esriService: EsriService, private drawMeasureService: DrawMeasureService) {
    
  }


  private polygonBtn: any;
  private self: any = this;
  private graphics: __esri.Collection<__esri.Graphic>;
  private graphicsLabel: __esri.Collection<__esri.Graphic>;
  private needsAdd: boolean = true;

  getPolygonBtn() {
    this.sharedService.mapView.ui.add("draw-polyline", "bottom-right");
    
  }

  startDraw() {
    this.needsAdd = true;
    this.graphics = new this.esriService.Collection();
    this.graphicsLabel = new this.esriService.Collection();
    this.drawMeasureService.createGraphicsLayers();
    this.sharedService.setStartedDrawing(true);
    this.sharedService.setPolylineDrawing(true);
    this.sharedService.setPolygonDrawing(false);
    this.enableCreatePolyline(this.sharedService.polygonDraw, this.sharedService.mapView);
  }

  enableCreatePolyline(draw, view) {
    // create() will return a reference to an instance of PolygonDrawAction
    var action = draw.create("polyline", { mode: "click" });
    var self = this;
    // focus the view to activate keyboard shortcuts for drawing polygons
    view.focus();

    // listen to vertex-add event on the action
    action.on("vertex-add", function (env) {     
      self.drawPolyline(env, draw)
    });

    // listen to cursor-update event on the action
    action.on("cursor-update", function (env) {
      self.drawPolyline(env, draw)
    }); 

    // listen to vertex-remove event on the action
    action.on("vertex-remove", function (env) {
      self.drawPolyline(env, draw)
    });

    // *******************************************
    // listen to draw-complete event on the action
    // *******************************************
    action.on("draw-complete", function (env) {
      self.needsAdd = false;
      self.drawPolyline(env, draw);
      self.graphics.removeAll();
      self.graphicsLabel.removeAll();
      self.sharedService.setPolylineDrawing(false);
    });
  }



  drawPolyline(evt, draw) {    
    var vertices = evt.vertices;
    // create a new polygon
    var polygon = this.createPoliline(vertices);

    if (evt.type != "draw-complete") {
      let tempGraphicx: any[] = [];
      //remove existing graphic

      this.graphics.forEach(x => {
        if (!x.symbol.get<boolean>("labelType")) {
          tempGraphicx.push(x);
        }
      });         
      this.sharedService.MeasureLayer.removeMany(tempGraphicx);
      // create a new graphic representing the polygon, add it to the view
      var graphic = this.createGraphic(polygon);
      this.graphics.add(graphic);      
      this.sharedService.MeasureLayer.add(graphic);
      // calculate the area of the polygon
      
      // start displaying the area of the polygon
      if (evt.type == "vertex-add") {
        let _point = polygon.getPoint(0, polygon.paths[0].length - 2);
        let _point2 = polygon.getPoint(0, polygon.paths[0].length - 1);

        if (evt.vertices.length > 1) {          
          let centerPoint = this.drawMeasureService.getCenterPoint(_point, _point2);         
          let lastSegment = this.getLastSegment(polygon);
          let distance = this.esriService.GeometryEngine.geodesicLength(lastSegment, "meters");
          if (vertices.length == 2) {
            timer(300).subscribe(() => {
              if (this.needsAdd) {
                this.labelDistance(centerPoint, distance);
              }
          });
            
          } else {
            this.labelDistance(centerPoint, distance);
          }
         
        }
                
        this.labelMarker(_point2);
      }
    } else {
      if (vertices.length == 2) {
        this.sharedService.MeasureLabelLayer.removeMany(this.graphicsLabel.toArray());
      }
    }
       
    var area = this.esriService.GeometryEngine.geodesicLength(polygon, "meters");
    if (area < 0) {
      // simplify the polygon if needed and calculate the area again
      var simplifiedPolygon = this.esriService.GeometryEngine.simplify(polygon);
      if (simplifiedPolygon) {
        area = this.esriService.GeometryEngine.geodesicLength(simplifiedPolygon, "meters");
      }
    }
    this.labelAreas(polygon, area);
  }



 

  createPoliline(vertices) {
    return new this.esriService.Polyline({
      paths: vertices,
      spatialReference: this.sharedService.mapView.spatialReference
    });
  }

  getLastSegment(polyline) {
    let line = polyline.clone();
    let _point = polyline.getPoint(0, polyline.paths[0].length - 2);
    let _point2 = polyline.getPoint(0, polyline.paths[0].length - 1);
    
    return new this.esriService.Polyline({
      spatialReference: this.sharedService.mapView.spatialReference,
      hasZ: false,
      paths: [
        [
          [_point2.x, _point2.y],
          [_point.x, _point.y]
        ]
      ]
    });
  }

  createGraphic(polyline) {
    //let attribute = {
    //  Name: "Keystone Pipeline",
    //  Owner: "TransCanada",
    //  Length: "3,456 km"
    //};
    let graphic = new this.esriService.Graphic({
      geometry: polyline,
      symbol: {
        type: "simple-line", // autocasts as SimpleLineSymbol
        color: [255, 74, 255],
        width: 2,
        cap: "round",
        join: "round"
      },
      //attributes: attribute,
      //popupTemplate: {  // autocasts as new PopupTemplate()
      //  title: "{Name}",
      //  content: [{
      //    type: "fields",
      //    fieldInfos: [{
      //      fieldName: "Name"
      //    }, {
      //      fieldName: "Owner"
      //    }, {
      //      fieldName: "Length"
      //    }]
      //  }]
      //}
    });
    return graphic;
  }
  //Label polyon with its area
  labelAreas(geom, area) {

    let _point = geom.getPoint(0, geom.paths[0].length - 1);
    let graphic = new this.esriService.Graphic({
      geometry: _point,
      symbol: {
        type: "text",
        color: [255, 255, 255],
        text: area.toFixed(2) + " м",
        xoffset: 3,
        yoffset: 3,
        font: { // autocast as Font
          family: 'Arial',
          size: 18
        }
      }
    });
    
    this.graphics.add(graphic);    
    this.sharedService.MeasureLayer.add(graphic);
    
  }

  labelDistance(centerpoint, area) {    
    let graphic = this.drawMeasureService.labelDistance(centerpoint, area);
    this.sharedService.MeasureLabelLayer.add(graphic);
    this.graphicsLabel.add(graphic);      
  }

  labelMarker(point) {   
    let symbolGraphic = this.drawMeasureService.labelMarker(point);

    this.graphics.add(symbolGraphic);   
    this.sharedService.MeasureMarkerLayer.add(symbolGraphic);
  }

    
}

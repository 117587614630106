import { Component, OnInit } from '@angular/core';
import { ProfileService } from "../../services/profile/profile.service";
import { UserService } from "../../services/user/user.service";
import { Observable } from 'rxjs';
import { MapLayersService } from "../../services/map/map.layers.service";
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import { SharedService } from '../../services/shared/shared.service';
import { finalize } from "rxjs/operators";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isLoggedIn: Observable<boolean>;
  credentials: any = { email: '', password: '' };

  errors: string;
  isRequesting: boolean;
  submitted: boolean = false;
  isShow: boolean = false;
  userShortName: string = 'X';
  constructor(private profileService: ProfileService, private userService: UserService, private mapLayerService: MapLayersService,
    private sketchViewService: SketchViewService, private sharedService: SharedService) {
    this.isLoggedIn = userService.authNavStatus$;
 }

  ngOnInit() {
    if (this.isLoggedIn && !!this.userService.User) {
      this.userShortName = this.userService.User.shortName;
    }
  }

  login({ valid }: { valid: boolean }) {

    this.submitted = true;
    this.isRequesting = true;
    this.errors = '';

    if (valid) {
      this.userService.login(this.credentials.email, this.credentials.password)
        .pipe(finalize(() => this.isRequesting = false))
        .subscribe(
        result => {
          if (result) {
            this.userShortName = this.userService.User.shortName;
            this.sharedService.setShowPanel(true);
            this.ClosePopup();
            this.mapLayerService.RemoveAllLayers();
            this.sharedService.publicGroup = false;
            this.sharedService.setShowGroupBookmark(false);
          }
        },
        error => this.errors = error);
    }
  }

  ShowPopup()
  {
    this.isShow = true;
  }

  ClosePopup()
  {
    this.isShow = false;
  }



  logout() {
    this.userService.logout();
    this.ClosePopup();
  }

}

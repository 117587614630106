import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { Legend, LegengsIcon, LegendItem, FlashWatch, isFlashType } from "../../models/layers/legend.model";
import { MapService } from "../map/map.service";
import { EsriService } from "../esri/js-esri.service";
import notify from 'devextreme/ui/notify';
import { FlashLegendsService } from './flash-legends.service';
import { LegendQuerySevice } from './legend-query.service';




@Injectable()
export class LegendsService {

  constructor(private sharedService: SharedService,
    private esriService: EsriService,
    private mapService: MapService,
    private flashLegendsService: FlashLegendsService,
    private legendQueryService: LegendQuerySevice) {
    
  }    

  getDynamicLayers(): Array<any> {
    let allLayers = (this.sharedService.map as __esri.Map).layers;
    let legends = new Array<Legend>();
    let filteredLayers = allLayers.filter(x => x.visible == true); // && x.type == "map-image"
    for (let i = 0; i < filteredLayers.length; i++) {
      let layer = (filteredLayers.getItemAt(i) as __esri.MapImageLayer);
      let legendInfo = layer.get<any>("legendInfo");
      if (legendInfo) {
        if (layer.sublayers && layer.sublayers.length > 0) {

          layer.sublayers.forEach(_sublayer => {
            let visibledSubtypes = (_sublayer as __esri.Sublayer).get<any[]>("visibledSubtypes");

            let tempLayer = legendInfo.layers.find(x => x.layerId == _sublayer.id);
            if (tempLayer) {
              let arrSubTypeFilter: any;
              if (visibledSubtypes) {
                arrSubTypeFilter = tempLayer.legend.filter(f => visibledSubtypes.find(vs => vs == f.values[0]));
              } else {
                arrSubTypeFilter = tempLayer.legend;
              }
              arrSubTypeFilter.
                forEach(tmpLegend => {
                  let imageData = 'data:image/png;base64,' + tmpLegend.imageData;
                  let legend = new LegendItem()
                  legend.id = _sublayer.id.toString();
                  legend.layerID = layer.id;
                  legend.name = (tmpLegend.label) ? tmpLegend.label : _sublayer.get<string>('name');//+ " " + tmpLegend.label;
                  legend.opacity = _sublayer.opacity * 100;
                  legend.image = imageData;
                  legend.canAddObject = _sublayer.get<boolean>('canAddObject')
                  if (tmpLegend.values) {
                    let flashTypes = (_sublayer as __esri.Sublayer).get<any[]>("flashTypes");
                    if (flashTypes) {
                      let flashType = flashTypes.find(f => f.subTypeID == tmpLegend.values[0]);
                      if (flashType) {
                        legend.isFlash = flashType.isFlashType;
                      }
                    }
                  } else {
                    legend.isFlash = _sublayer.get<any>('IsFlash') ? _sublayer.get<any>('IsFlash') : isFlashType.none;
                  }

                  legend.layerGuid = _sublayer.get<string>('LayerDataGUID');
                  legend.defaultValues = tmpLegend.values;
                  legend.layerURL = layer.get<any>('url');
                  legend.filterGroupType = _sublayer.get<any>("filterGroupType"); 
                  legend.filterKOATUU = _sublayer.get<string>('filterKOATUU');
                  legend.layerFilterExpression = _sublayer.get<string>('layerFilterExpression');
                  if (legend.isFlash != isFlashType.none) {
                    if (legend.defaultValues) {
                      let typesCount: any[] = _sublayer.get<any[]>("typesCount");
                      if (typesCount) {
                        let item = typesCount.find(x => x.id == legend.defaultValues[0]);
                        if (item) {
                          legend.totalCount = item.value;
                        }
                      }
                    } else {
                      legend.totalCount = _sublayer.get<number>('totalCount');
                    }
                  }
                  legends.push(legend);
                })
            } else {
              console.log("not found layer " + _sublayer.id);
            }
          })
        }
      } else {
        let _self = this;
        
        setTimeout(function () {
          _self.getDynamicLayers();
        }, 3000);
        break;
      }

    }

    return legends;
  }

  getLegendsByLayers(): Array<LegendItem> {
    let allLayers = (this.sharedService.map as __esri.Map)?.layers;
    let legends = new Array<LegendItem>();
    if (allLayers) {
      let filteredLayers = allLayers.filter(x => x.visible == true && !x.get<boolean>("isFlashing") && (x.type == "map-image" || x.type == "feature")); // && x.type == "map-image"
      let iDs = filteredLayers.map((l) => {
        return l.id;
      })
      legends = this.sharedService.publicLegends.filter(f => iDs.includes(f.layerID));
    }
    return legends;
  }

  getLegendsByFilter(): Array<LegendItem> {
    //let allLayers = (this.sharedService.map as __esri.Map).layers;
    let legends = new Array<LegendItem>();
    //let filteredLayers = allLayers.filter(x => x.visible == true && !x.get<boolean>("isFlashing") && (x.type == "map-image" || x.type == "feature")); // && x.type == "map-image"
    //let iDs = filteredLayers.map((l) => {
    //  return l.id;
    //})
    let iDs: any[] = this.sharedService.getCurrentFilterValue().layers.map(m => { return m.id });

    legends = this.sharedService.publicLegends.filter(f => iDs.includes(f.filterLayerGUID)) // f.isFilter
    return legends;
  }

  flashLayer(id: any, layerID: string, legendItem: any, subQuery?, subTypeID?) {    
    var self = this;
    let map = this.sharedService.map as __esri.Map
    let _layer = map.findLayerById(layerID);
    let subLayer: __esri.Sublayer;
    if (_layer && _layer.type == 'map-image'){
      //if (_layer && (_layer as __esri.MapImageLayer).sublayers && (_layer as __esri.MapImageLayer).sublayers.length > 0) {
      if ((_layer as __esri.MapImageLayer)?.sublayers?.length > 0) {
        subLayer = (_layer as __esri.MapImageLayer).sublayers.find(l => l.id == id)
        
      }
    } else if (_layer && _layer.type == 'feature') {

    }

    this.sharedService.mapView.whenLayerView(_layer).then(function (lyrView) {
      let layerDataGuidID = (subLayer ? subLayer : _layer).get<string>("LayerDataGUID") + (subTypeID ? subTypeID : '');
      let flashWath = new FlashWatch(layerDataGuidID);
      self.sharedService.WatchFlashList.push(flashWath);
      //var _watchHandle = lyrView.watch("updating", function (val) {
      //  //this.sharedService.WatchFlashList. find(x => x.flashId == FlashLayerID)
      //  let exists = self.sharedService.WatchFlashList.find(x => x.flashId == layerDataGuidID)
      //  if (!val && exists) {  // wait for the layer view to finish updating
      //    notify("updating flash", "info", 1000);
      //    self.selectLayer((subLayer ? subLayer : _layer), legendItem.isFlash, subQuery, subTypeID);
      //  }
      //});
      //flashWath.handle = _watchHandle;

      //let flashWath = new FlashWatch(layerDataGuidID, _watchHandle);
      //self.sharedService.WatchFlashList.push(flashWath);
      self.selectLayerTest((subLayer ? subLayer : _layer), legendItem.isFlash, subQuery, subTypeID);
      if ((legendItem as LegendItem).legendGUID && (legendItem as LegendItem).totalCount) {
        return;
      }
      self.mapService.getRecordCount(subLayer, subQuery).then(function (count) {
        legendItem.totalCount = count;
        if (subTypeID) {
          let typesCount: any[] = subLayer.get<any[]>("typesCount");
          if (typesCount) {

          } else {
            typesCount = [];
          }

          let item = typesCount.find(x => x.id == subTypeID);
          if (item) {
            item.value = count;
          } else {
            item = { id: subTypeID, value: count };
            typesCount.push(item);
            subLayer.set<any[]>("typesCount", typesCount);
          }
        } else {

          subLayer.set<number>("totalCount", count);
        }
      })
    });
      
  }

  private selectLayerTest(layer, isFlashType, subQuery?, subTypeID?) {    
    (layer as __esri.Sublayer).set<any>("IsFlash", isFlashType);
    if (subTypeID) {
      let flashTypes: any[] = [];
      if ((layer as __esri.Sublayer).get<any[]>("flashTypes")) {
        flashTypes = (layer as __esri.Sublayer).get<any[]>("flashTypes");
      }
      let subType = flashTypes.find(x => x.subTypeID == subTypeID);
      if (subType) {

      } else {
        flashTypes.push({ subTypeID: subTypeID, isFlashType: isFlashType });
      }
      (layer as __esri.Sublayer).set<any[]>("flashTypes", flashTypes);
    }
  
    let tempLayer: any = layer;
    let featureUrl: string = tempLayer.type == 'feature' ? tempLayer.parsedUrl.path : tempLayer.url;
    let _url = featureUrl.replace('MapServer', 'FeatureServer');   
    
    var self = this;
    let layerDataGuidID: string = (layer as __esri.Layer).get<string>("LayerDataGUID") + (subTypeID ? subTypeID : '');
    
    let flashLayer: __esri.FeatureLayer = new self.esriService.FeatureLayer({     
      url: _url,
      popupEnabled: false,
      minScale: 46978595, //3000000
      maxScale : 0,
      definitionExpression: (subQuery ? subQuery : '')
    });
    
    flashLayer.set<string>("FlashLayerID", layerDataGuidID);
    flashLayer.set<boolean>("isFlashing", true);
    if (self.sharedService.WatchFlashList.find(f => f.flashId == layerDataGuidID)) {
      //notify("flash layer is added", "info", 1000);
      (self.sharedService.map as __esri.Map).add(flashLayer);
      self.sharedService.mapView.whenLayerView(flashLayer).then(function (layerView) {
        
        var _symbol = self.getSymbolByFlashType(flashLayer.geometryType, isFlashType);          
          let fRenderer: __esri.SimpleRenderer = new self.esriService.SimpleRenderer({
            type: "simple",  // TO DO ,   // autocasts as new SimpleRenderer()
            symbol: _symbol
          });
        flashLayer.renderer = fRenderer;
        flashLayer.labelsVisible = false;
        self.flashLegendsService.AddFlashLayerView(layerView);
      })
    }
    //else {
    //  notify("flash layer is not added", "error", 1000);
    //}
    if (isFlashType == isFlashType.flash) {
      (self.sharedService.map as __esri.Map).reorder(flashLayer, 0);
    }
   
  }


  removeFlashLayer(layerID, FlashLayerID, subType?) {
    
    let _layer = (this.sharedService.map as __esri.Map).findLayerById(layerID);//  layers.find(x => x.get<string>("LayerDataGUID") == FlashLayerID);
      
    if (_layer) {
      if (_layer.type == 'feature') {
        _layer.set<any>("IsFlash", isFlashType.none);
      } else if (_layer.type == 'map-image') {
        let sublayer = (_layer as __esri.MapImageLayer).sublayers.find(x => x.get<string>("LayerDataGUID") == FlashLayerID);
        if (sublayer) {
          let flashTypes = sublayer.get<any[]>("flashTypes");
          if (flashTypes) {
            let _index = flashTypes.findIndex(x => x.subTypeID == subType);
            if (_index >= 0) {
              flashTypes.splice(_index, 1);
            }
            if (flashTypes.length == 0) {
              sublayer.set<any>("IsFlash", isFlashType.none);
            }
          } else {
            sublayer.set<any>("IsFlash", isFlashType.none);
          }
        }
      }
               
    }

    if (subType) {
      FlashLayerID = FlashLayerID + subType;
    }

    let flashWatch = this.sharedService.WatchFlashList.find(x => x.flashId == FlashLayerID);
      let watchHandle = flashWatch.handle;
      watchHandle?.remove();
      let index = this.sharedService.WatchFlashList.indexOf(flashWatch);
    this.sharedService.WatchFlashList.splice(index, 1);
    //if (subType) {
    //  FlashLayerID = FlashLayerID + subType;
    //}
    let flashLayer = (this.sharedService.map as __esri.Map).layers.find(layer => layer.get<string>("FlashLayerID") == FlashLayerID);
    if (flashLayer) {
      //console.log("flashLayer found - " + FlashLayerID);
      (this.sharedService.map as __esri.Map).remove(flashLayer);      
      this.flashLegendsService.RemoveFlashLayerViewByLayerID(flashLayer.id);
    } else {
      console.log("flashLayer not found");
      //notify("flashLayer not found", "error", 1000);  // TO DO remove
    }

  }

  getSymbolByFlashType(geometryType, _isFlashType: isFlashType): __esri.SimpleMarkerSymbol | __esri.SimpleLineSymbol | __esri.SimpleFillSymbol {
    let _symbol = new this.esriService.SimpleFillSymbol(this.esriService.SimpleFillSymbol.STYLE_SOLID,
      new this.esriService.SimpleLineSymbol(this.esriService.SimpleLineSymbol.STYLE_SOLID, new this.esriService.Color([255, 0, 0]), 2),
      new this.esriService.Color([255, 0, 0, 0.25]));
    switch (geometryType) {
      case "point": //case "esriGeometryMultipoint":
        if (_isFlashType == isFlashType.red) {
          _symbol = {
            type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
            style: "circle",
            color: [255, 0, 0, 0.5],
            size: "10px",  // pixels
            outline: {  // autocasts as new SimpleLineSymbol()
              color: [255, 0, 0, 0.7],
              style: "solid",
              width: 0.5  // points
            }
          };            
        } else if (_isFlashType == isFlashType.flash) {
          _symbol = {
            type: "picture-marker",  // autocasts as new PictureFillSymbol()                   
            url: "/assets/images/circle.gif",
            width: "12px",
            height: "12px",
            outline: {
              width: 1,
              style: "solid"
            },
          };
        }      
       
        break;
      case "multipoint":
        _symbol = {
          type: "simple-marker",  // autocasts as new SimpleMarkerSymbol()
          style: "circle",
          color: [255, 0, 0, 0.5],
          size: "10px",  // pixels
          outline: {  // autocasts as new SimpleLineSymbol()
            color: [255, 0, 0, 0.7],
            style: "solid",
            width: 0.5  // points
          }
        };
        break;
      case "polyline":
        if (_isFlashType == isFlashType.red) {
          _symbol = {
            type: "simple-line",  // autocasts as new SimpleLineSymbol()
            color: [255, 0, 0, 0.5],
            width: "3px",
            style: "solid"
          };
        } else if (_isFlashType == isFlashType.flash) {
          _symbol = {
            type: "picture-marker",  // autocasts as new PictureFillSymbol()                   
            url: "/assets/images/circle.gif",
            width: "8px",
            height: "8px",
            outline: {
              width: 2,
              style: "solid",
              color: new this.esriService.Color([255, 0, 0, 0.5])
            },
          };
        }
        break;
      case "polygon": case "extent": default:
        if (_isFlashType == isFlashType.red) {
          _symbol = {
            type: "simple-fill",  // autocasts as new SimpleFillSymbol()
            color: [255, 0, 0, 0.25],
            style: "solid",
            outline: {  // autocasts as new SimpleLineSymbol()
              color: [255, 0, 0, 0.7],
              style: "solid",
              width: 1
            }
          };
        } else if (_isFlashType == isFlashType.flash) {
          _symbol = {
            type: "picture-fill",  // autocasts as new PictureFillSymbol()                   
            url: "/assets/images/flash.gif",
            width: "16px",
            height: "16px",
            outline: {
              width: 0.5,
              style: "solid",
              color: "#004680" // new self.esriService.Color([255, 0, 0, 0.5])
            },
          };
        }
        break;
    }

    return _symbol;
  }

  getLegendsIcon(): LegengsIcon[] {
    //https://resources.arcgis.com/en/help/rest/apiref/index.html?difference.html

    let icons: LegengsIcon[] = [
      {
        type: 'point',
        icon: 'legend-icon--point'
      },
      {
        type: 'multipoint',
        icon: 'legend-icon--multipoint'
      },
      {
        type: 'polyline',
        icon: 'legend-icon--polyline'
      },
      {
        type: 'polygon',
        icon: 'legend-icon--polygon'
      }

    ];
    return icons;
  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }


}

import { Injectable } from "@angular/core";
import { FeatureLayerInfo } from "../../models/layers/feature-layer.info.model";
import { EsriService } from "../esri/js-esri.service";
import { SharedService } from "../shared/shared.service";
import { ConfigService } from "../shared/utils/config.service";

@Injectable()
export class LayerServerService {
  constructor(private configService: ConfigService, private sharedService: SharedService, private esriService: EsriService) {

  }

  async getLayerInfo(layerURL): Promise<any> {
    let options: __esri.RequestOptions =
    {
      responseType: 'json'
    };
    let url = layerURL + "?f=pjson&token=" + this.configService.getGisToken();
    let layerInfo: any;
    let info = this.sharedService.featureLayerInfoList.find(i => i.url == layerURL);
    if (info) {
      if (!info.loadingPromise) {
        info.loadingPromise = new Promise<any>((resolve, reject) => {
          info.getLoaded().subscribe(val => {
            if (val) {
              resolve(info.layerInfo);
              //sub.unsubscribe(); // TO DO - CHECK
            } else {
              reject("Layer not loaded");
            }
          });
        })
      }
      return info.loadingPromise;
    } else {
      info = new FeatureLayerInfo();
      info.url = layerURL;
      this.sharedService.featureLayerInfoList.push(info);
      info.loadingPromise = this.esriService.Request(url, options).then(function (result) {
        layerInfo = result.data;
        info.layerInfo = layerInfo;
        info.setLoaded(true);
        return layerInfo;
      }).catch(function (error) {
        console.log("informative error message: ", error.message);
      });

      return info.loadingPromise;
    }
  }

  public serverTypeToEsriType(value): "point" | "multipoint" | "polyline" | "polygon" | "extent" {
    let result;
    switch (value) {
      case "esriGeometryPoint":
        result = "point";
        break;
      case "esriGeometryMultipoint":
        result = "multipoint";
        break;
      case "esriGeometryPolyline":
        result = "polyline";
        break;
      case "esriGeometryPolygon":
        result = "polygon";
        break;
      case "esriGeometryEnvelope":
        result = "extent";
        break;
      default:
        result = '';
        break;
      //case "esriGeometryMultiPatch":
      //  result = "mesh";
      //  break;
    }
    return result
  }

  public serverFieldTypeToEsri(value) {
    switch (value) {
      case "esriFieldTypeSmallInteger":
      case "esriFieldTypeInteger":
      case "esriFieldTypeSingle":
      case "esriFieldTypeDouble":
        return "number";        

      case "esriFieldTypeString": {
        return "string";
      }
      case "esriFieldTypeDate": {
        return "datetime";
      }
      default:
        return "any";
    }
  }

  public subTypeInfo(layerInfo) {
        let subtypeFieldName: string;
        let subtypes: { id: number, name: string }[] = [];
        if (layerInfo?.subtypeField) {
            subtypes = layerInfo.subtypes.map(m => {
                return { id: m.code, name: m.name }
            })
            subtypeFieldName = layerInfo.subtypeField;
        } else if (layerInfo?.typeIdField) {
            subtypeFieldName = layerInfo.typeIdField;
            subtypes = layerInfo.types.map(m => {
                return { id: m.id, name: m.name };
            })
        } else if (layerInfo?.drawingInfo?.renderer?.type == "uniqueValue") {
            let renderer = layerInfo.drawingInfo.renderer;
            subtypeFieldName = renderer?.field1;

            subtypes = renderer.uniqueValueInfos.map(val => {

                return { id: val.value, name: val.label }
            })
        }
        return { subtypeFieldName: subtypeFieldName, subtypes: subtypes };
  }  

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router} from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { SharedService } from "../../services/shared/shared.service";
import { SketchViewService } from "../../services/customlayer/sketch-view.service";
import { UserLayer } from "../../models/userlayer/user-layer.model";
import { CustomLayerService } from "../../services/customlayer/custom-layer.service";
import { EsriService } from "../../services/esri/js-esri.service";
import 'devextreme/integration/jquery';
import { UserService } from '../../services/user/user.service';
import { CadastralXMLService } from '../../services/cadastralXML/cadastral-xml.service';
import notify from 'devextreme/ui/notify';
import { BookmarksService } from '../../services/bookmarks/bookmarks.service';
import { FeatureLayerService } from '../../services/map/feature-layer.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  showCustomLayer: boolean = false;
  subscription: Subscription;
  subscriptionEdit: Subscription;
  showSettings: boolean;
  currentTypeSymbol: string;
  CurrentColor: any;
  currentStyle: any;
  currentStyleLine: any;
  currentWidth: number = 2;
  isDisabled: boolean = true;
  isShowCadastralXML : boolean = false;
  isTitleName: string;
  isExportLegends: boolean = false;
  
  stylePointDataSource: any[] = [{ id: 'circle', name: 'круг' }, { id: 'diamond', name: 'ромб' }, { id: 'triangle', name: 'трикутник' },
  { id: 'square', name: 'квадрат' }, { id: 'x', name: 'X' }, { id: 'cross', name: '+' }];

  styleLineDataSource: any[] = [{ id: 'dash', name: 'тире' }, { id: 'dash-dot', name: 'тире-крапка' }, { id: 'dot', name: 'крапка' },
  { id: 'long-dash', name: 'довге-тире' }, { id: 'long-dash-dot', name: 'довге-тире-крапка' }, { id: 'long-dash-dot-dot', name: 'довге-тире-крапка-крапка' },
  { id: 'short-dash', name: 'коротке-тире' }, { id: 'short-dash-dot', name: 'коротке-тире-крапка' },
  { id: 'short-dash-dot-dot', name: 'коротке-тире-крапка-крапка' },
  { id: 'short-dot', name: 'коротка-крапка' }, { id: 'solid', name: 'суцільна' }];

  saveButtonOptions: Record<string, unknown>;

  constructor(
    private userService: UserService,
    private sharedService: SharedService,
    private sketchViewService: SketchViewService,
    private customLayerService: CustomLayerService,
    private esriService: EsriService,
    private router: Router,
    private cadastralXMLService: CadastralXMLService,
    private featureLayerService: FeatureLayerService,
    private bookmarksService: BookmarksService,
  ) {
    this.saveButtonOptions = {
      text: 'Зберегти',
      stylingMode: 'text',
      type: 'default',
      height: '48',
      onClick: this.saveSymbolSettings.bind(this),
    };
  }

  ngOnInit() {
    this.isDisabled = !(this.userService.User && this.userService.User.isEdit);
    this.subscription = this.sharedService.getRegionID().subscribe(u => {
      this.sharedService.setIsEditCustomLayer(false);
      this.showCustomLayer = false;
      
      this.sketchViewService.cancelDraw();
      this.isShowCadastralXML = this.sharedService.CurrentRegionCode == 'cadastral_xml';
    })

    this.subscriptionEdit= this.sharedService.getIsEditCustomLayer().subscribe(result => {
      this.showCustomLayer = result;
    })

    this.sharedService.isExportLegends$.subscribe(value => {
      this.isExportLegends = value;
    });
   
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscriptionEdit.unsubscribe();
    if (this.sketchViewService.isEdit) {
      this.sketchViewService.setEditStatus(false);
      this.sketchViewService.collectGraphics();
    }
  }

  CustomLayer(value) {
    //this.customLayerService.getCustomLayerData('test');
    this.showCustomLayer = value;
    let isCheck = this.sharedService.getIsCheckedCustomLayerValue();
    this.sketchViewService.setEditStatus(value);
    if (value) {
      if (!isCheck) {
       this.sketchViewService.setGraphics();
      }
    } else {
      this.sketchViewService.collectGraphics(true);
    }
  }
  drawPoint() {
    this.sketchViewService.drawPoint();
  }
  drawPolyline() {
    this.sketchViewService.drawPolyline();
  }
  drawPolygon() {
    this.sketchViewService.drawPolygon();
  }

  symbolSetting(value) {
    this.currentTypeSymbol = value;
    switch (this.currentTypeSymbol) {
      case "point":
        if (this.sketchViewService.pointSymbol) {
          this.currentStyle = this.sketchViewService.pointSymbol.style;
          this.CurrentColor = this.sketchViewService.pointSymbol.color;
          this.currentWidth = this.sketchViewService.pointSymbol.size;
        } else {
          this.currentStyle = "square";
          this.CurrentColor = new this.esriService.Color([20, 146, 220, 1] );
          this.currentWidth = 10;
        }
        this.isTitleName = "маркера"
        break;
      case "line":
        if (this.sketchViewService.polylineSymbol) {
          this.currentStyleLine = this.sketchViewService.polylineSymbol.style;
          this.CurrentColor = this.sketchViewService.polylineSymbol.color;
          this.currentWidth = this.sketchViewService.polylineSymbol.width;
        } else {
          this.currentStyleLine = "solid";
          this.CurrentColor = new this.esriService.Color([20, 146, 220, 1])
          this.currentWidth = 2;
        }
        this.isTitleName = "лінії"
        break;
      case "polygon":
        if (this.sketchViewService.polygonSymbol) {
          this.CurrentColor = this.sketchViewService.polygonSymbol.color;
        } else {
          this.CurrentColor = new this.esriService.Color([20, 146, 220, 1])
        }
        this.isTitleName = "полігону"
        break;
    }    
    this.showSettings = true;
  }

  saveSymbolSettings() {
    if (this.currentTypeSymbol == 'point') {
      switch (this.currentStyle) {
        case "cross":
          this.sketchViewService.pointSymbol = this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor);
          break;
        case "x":
          this.sketchViewService.pointSymbol = this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth, this.CurrentColor);
          break;
        case "circle":
        case "square":
        case "diamond":
        case "triangle":
        default:
          this.sketchViewService.pointSymbol = this.sketchViewService.createPointSymbol(this.currentStyle, this.CurrentColor, this.currentWidth);
          break;
      }
    } else if (this.currentTypeSymbol == 'line') {
      this.sketchViewService.polylineSymbol = this.sketchViewService.createLineSymbol(this.currentStyleLine, this.CurrentColor, this.currentWidth);
    } else if (this.currentTypeSymbol == 'polygon') {
      this.sketchViewService.polygonSymbol = this.sketchViewService.createPolygonSymbol(this.CurrentColor);
    }
    this.showSettings = false;
    
  }

  onOpened(ev) {
    setTimeout(function () {
      ev.component.content().parent().width(245);
    });
  }

  createFilter() {
    this.router.navigate(['./filtersettings']);
  }

  goToExportLegends() {
    if (!this.isExportLegends) {
      this.router.navigate(['./legend']);
      this.sharedService.setIsExportLegends(true);
      notify(`Експорт увімкнено`, "info", 3000);
    }
    else{
      this.sharedService.setIsExportLegends(false);
      notify(`Експорт вимкнено`, "info", 3000);
    }
  }

  XMLfileEvent(e) {
    //notify("Імпорт даних почався", "info", 2500);
    //let layerId = e.target.attributes["layerID"].value;
    //let Id = e.target.attributes["sublayerID"].value;

    let filedata: File = e.target.files.item(0);
    
    this.cadastralXMLService.importGeodata(filedata).subscribe(result => {
      notify(result, "info", 4500);
      
    });
  }

  createBookmark(e) {
    if (this.sharedService.getSelectedBookmarkValue()?.userID) {
      let bookmark = this.featureLayerService.collectBookmark(this.sharedService.getSelectedBookmarkValue());
      //bookmark.
      this.bookmarksService.saveUserBookmark(bookmark).then(result => {
        notify(`Зміни закладки \"${bookmark.name}\" успішно збережені`, "success", 3000);
        result.isSelected = true;
        this.sharedService.setSelectedBookmark(result);
        bookmark.isSelected = true;
        this.sharedService.getUserBookmarkList().subscribe(list => {
          let item = list.find(z => z.id == bookmark.id);
          if (item) {
            item = bookmark;
          }
        })
      }).catch(ex => {
        notify(`Під час збереження закладки сталася помилка.\r\n Помилка: "${ex._body}"`, "error", 3000);
      })
    } else {
      this.router.navigate(['./savebookmark']);
    }
  }
}

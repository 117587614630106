import { BehaviorSubject, Observable } from "rxjs";

export class Legend {
  constructor(
    id?: string,
    name?: string,
    color?: any,
    opacity?: number,
    geometryType?: string,
    image?: any) {

    this.id = id;
    this.name = name;
    this.color = color;
    this.opacity = opacity;
    this.geometryType = geometryType;
    this.image = image;    
  }

  public id: string;
  public layerID: string;
  public name: string;
  public color: any;
  public opacity: number;
  public geometryType: string;
  public image: any;
  public layerGuid: string;
  public layerURL: string;
  public canAddObject: boolean;
}


export class LegengsIcon {
  type: string;
  icon: string;
 
}

export class LegendItem extends Legend {
  icon: string;
  isOpened: boolean;
  isFlash: isFlashType = isFlashType.none;
  visibleCount: number;
  totalCount: number;
  defaultValues: any[];
  filterKOATUU: any;
  filterGroupType: any[];
  SubTypeField: string;
  esriFieldType: string;
  /*"small-integer"|"integer"|"big-integer"|"single"|"double"|"long"|"string"|"date"|"date-only"|"time-only"|"timestamp-offset"|"oid"|"geometry"|"blob"|"raster"|"guid"|"global-id"|"xml" */
  layerFilterExpression: any;
  layerDefinitionExpression: any;
  layerType: 'feature' | 'map-image' | null = 'map-image';
  legendGUID: string;
  visible: boolean;
  isFilter: boolean;
  isRaster: boolean = false;
  filterLayerGUID: string;
  extent: any;
  isChecked?: boolean = false;
}

export class FlashWatch {

  constructor(flashId?: string, handle?: any) {
    this.flashId = flashId;
    this.handle = handle;
  }
  flashId: string;
  handle: any;
}

const isFlashType = {
  none: 'none' as 'none',
  red: 'red' as 'red',
  flash: 'flash' as 'flash'
}
type isFlashType = (typeof isFlashType)[keyof typeof isFlashType];
export { isFlashType };

export class LegendInfo {
  url: string;
  legendInfo: any;
  public loadingPromise?: Promise<any>;

  private loadedSubject = new BehaviorSubject<boolean>(false);

  getLoaded(): Observable<boolean> {
    return this.loadedSubject.asObservable();
  }

  setLoaded(value: boolean) {
    this.loadedSubject.next(value);
  }
}

import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";
import { ConfigService } from "../shared/utils/config.service";

@Injectable()
export class FilterAttributeService {


  public get TypeKOATUU() { return "КОАТУУ"}
  public get TypeSubType() { return "Підтип" }
  public get TypeGroup() { return "Група" }

  constructor(private sharedService: SharedService, private esriService: EsriService
  ) {
    
  }

  public getFilterValue(filterType) {
    if (filterType == this.TypeKOATUU) {
      return this.sharedService.CurrentRegionCode;
    }

    return '';
  }


}

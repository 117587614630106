import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FiltersService } from "../../../services/filters/filters.service";
import { Filter } from '../../../models/filters/filter.model';
import { SharedService } from '../../../services/shared/shared.service';
import { UserService } from '../../../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import {
  DxListModule,
  DxButtonModule,
  DxTagBoxModule,
  DxFilterBuilderModule,

  DxValidationGroupComponent,
  DxSelectBoxComponent
} from 'devextreme-angular';
import { FilterLayer } from '../../../models/filters/filter.layer.model';
import { LayerDataWithAttr } from '../../../models/layers/layer.data.model';
import { ConfigService } from '../../../services/shared/utils/config.service';
import { FilterExtent, FilterExtentType } from '../../../models/filters/filter.extent.model';
import { Subscription } from 'rxjs';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { ExtentFilterService } from '../../../services/filters/extent.filter.service';
import dxFilterBuilder from 'devextreme/ui/filter_builder';
import { EsriService } from '../../../services/esri/js-esri.service';
import { LayerServerService } from '../../../services/layers/layer-server.service';
import { LayerAttributeHeplerService } from '../../../services/layers/layer.attribute-helper.service';
@Component({
  selector: 'app-edit-filter',
  templateUrl: './edit-filter.component.html',
  styleUrls: ['./edit-filter.component.css']
})
export class EditFilterComponent implements OnInit, OnDestroy {
  regionID: any;
  subscription: Subscription;
  filterEdit: Filter = new Filter();
  filterText: any;
  dataSourceText: any;
  isLoadData: boolean = false;

  @ViewChild('validationFilterData', { static: true })
  validationFilterData: DxValidationGroupComponent;

  @ViewChild('listBox', { static: true }) listBox: DxSelectBoxComponent;

  @ViewChild('filterbuilder', { static: false }) filterbuilder: dxFilterBuilder;

  layerDataSource: LayerDataWithAttr[] = [];
  layerOriginalSource: LayerDataWithAttr[] = [];
  //currentLayer: any;

  anyOfOperation = {
  name: "anyof",
    caption: "будь-які з",
  icon: "check",
  editorTemplate: "tagBoxTemplate",
  calculateFilterExpression(filterValue: any, field: any) {
    return filterValue && filterValue.length
      && Array.prototype.concat.apply([], filterValue.map(function (value) {
        return [[field.dataField, "=", value], "or"];
      })).slice(0, -1);
  }
  };

  someOfOperation = {
    name: "someof",
    caption: "будь-яке з",
    icon: "check",
    editorTemplate: "listTemplate",
    calculateFilterExpression(filterValue: any, field: any) {
     
        //&& Array.prototype.concat.apply([], filterValue.map(function (value) {
        //  return [[field.dataField, "=", value], "or"];
        //})).slice(0, -1);
      return filterValue && filterValue.length && [field.dataField, "=", filterValue];;
    }
  };

  anyOfUniqueOperation = {
    name: "anyofunq",
    caption: "будь-які з",
    icon: "check",
    editorTemplate: "uniqueValuesTemplate",
    calculateFilterExpression(filterValue: any, field: any) {
      return filterValue && filterValue.length
        && Array.prototype.concat.apply([], filterValue.map(function (value) {
          return [[field.dataField, "=", value], "or"];
        })).slice(0, -1);
    }
  };
  customOperations: Array<any>;
  //filter: any;
  groupOperations: string[] = ["and", "or"]; // ["and", "or", "notAnd", "notOr"];
  groupOperationDescriptions: any = {
    and: "Всі умови мають бути виконані",
    or: "Будь яка з умов має бути виконана",
    //notAnd: "Всі умови мають бути не виконані",
    //notOr: "Люба з умов має бути не виконана"
  }

  datasourceTst: any = {};
  datasourceDomains: any = {};

  createSubscription: Subscription;
  updateSubscription: Subscription;

  constructor(private filtersService: FiltersService,
    private sharedService: SharedService, private userService: UserService, private configService: ConfigService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private extentFilterService: ExtentFilterService,
    private esriService: EsriService,
    private layerServerService: LayerServerService,
    private layerAttributeHeplerService: LayerAttributeHeplerService
  ) {
    this.customOperations = [this.anyOfOperation, this.anyOfUniqueOperation]; // [, this.someOfOperation];
    //this.extentFilterService.createSketchModel();
    this.createSubscription = this.extentFilterService.getCreatedEvent().subscribe(result => {
      if (result) {
        this.createExtentFilter(result);
      }
      
    })
  }

  async ngOnInit() {
    this.regionID = this.sharedService.getRegionIDValue();
    this.subscription= this.sharedService.getRegionID().subscribe(u => {
      if (u != this.regionID) {
        this.goBack();
      }      
    })

    let layerDataSource: LayerDataWithAttr[] = [];

    if (this.sharedService.getlayerDataValue()) {
      layerDataSource = this.sharedService.getlayerDataValue();
    } else {
      layerDataSource = await this.filtersService.getLayers(this.sharedService.getRegionIDValue()).
        then(result => {
          return result;
        })
    }

    this.layerOriginalSource = layerDataSource.slice();
    this.layerDataSource = layerDataSource.slice();
    this.layerDataSource.sort(this.sortArrayByName);    

    let filterID = this.activatedRoute.snapshot.params['id'];
    if (filterID) {
      if (this.sharedService.getCurrentFilterValue()?.id == filterID) {
        this.sharedService.ShowFilterSettings = true;
      }
      this.filtersService.getFilterByID(filterID).then(result => {
        this.filterEdit = result;
        this.filterEdit.isSelected = this.sharedService.ShowFilterSettings;
        let arrayInit: Promise<any>[] = [];
        result.layers.forEach(f => {
          let initFilterPromise: Promise<any> = this.initFilterLayerFieldsAttr(f);
          arrayInit.push(initFilterPromise);
        });
        Promise.all(arrayInit).then(value => {
          let existsLayers = result.layers.map(l => {
            return l.layerDataID;
          })
          this.layerDataSource = this.layerDataSource.filter(f => !existsLayers.find(el=> el==f.id))                    
          this.isLoadData = true;
        }, reason => {
          console.log(reason)
        });

        
        //return this.filterEdit;
      })
      //  .then(value => {
      //  value.layers.forEach(f => {
      //    this.initFilterLayerFieldsAttr(f);
      //    //  .then(_layer => {

      //    //})
      //  })

      //}); // this.createFakeFilter();
    } else {
      this.isLoadData = true;
      this.filterEdit = new Filter();
      this.filterEdit.regionID = this.sharedService.getRegionIDValue();
      this.filterEdit.userID = this.userService.User.id;
    }
    
  }

  onValueChangedLayer(e) {
    let id = e.value;
    let layerDataIndex = this.layerDataSource.findIndex(x => x.id == e.value);
    
    if (layerDataIndex >= 0) {
      let filterLayer = new FilterLayer();
      
      let layer = this.layerDataSource[layerDataIndex];
      filterLayer.serviceName = layer.serviceName;
      filterLayer.layerName = layer.layerName;
      filterLayer.layerDataID = layer.id;
      filterLayer.name = layer.name;
      filterLayer.layerAttributes = layer.layerAttributes;
      filterLayer.regionID = this.sharedService.getRegionIDValue();

      this.initFilterLayerFieldsAttr(filterLayer).then(result => {
        if (result) {
          filterLayer.fields = result.fields;
        }
        return result;
      }).then(val => {
        this.filterEdit.layers.push(val);
        this.layerDataSource.splice(layerDataIndex, 1);
      })      

    }
  }

  updateTexts(e, id) {
    //this.filterText = e.component.option("value");
    //this.dataSourceText = e.component.getFilterExpression();
  }

  onValueChanged(e, layer) {
    let item = e.component.option('fields');
    let _layer = layer;
  }

  onOptionChanged(e, layer) {
    let item = e;
    let _layer = layer;
  }

  goBack() {
    this.sharedService.ShowFilterSettings = false;
    this.router.navigate(['./filter']);
  }
  save() {

    let result = this.validationFilterData.instance.validate();

    if (!result.isValid) {
      return;
    }

    if (this.filterEdit.id) {      
      this.filtersService.updateFilter(this.filterEdit).subscribe(result => {
        if (this.sharedService.getCurrentFilterValue() && this.sharedService.getCurrentFilterValue().id == this.filterEdit.id) {
          this.filtersService.unselectFilter(this.filterEdit)
        }
        this.goBack();
      })
    } else {
      this.filtersService.addFilter(this.filterEdit).subscribe(result => {
        this.goBack();
      });
    }
    
    
  }
  removeLayerFilter(obj) {
    var confirmation = confirm("Ви впевнені, що хочете видалити шар?", "Видалити шар");
    confirmation.then(dialogResult => {
      if (dialogResult) {
        let layerDataIndex = this.filterEdit.layers.findIndex(f => f.layerDataID == obj.layerDataID);
        if (layerDataIndex >= 0) {
          this.filterEdit.layers.splice(layerDataIndex, 1);
          let originalLayer = this.layerOriginalSource.find(l => l.id == obj.layerDataID);
          this.layerDataSource.push(originalLayer);
          this.layerDataSource.sort((a, b) => {
            if (a.name > b.name) {
              return 1
            } else if (b.name > a.name) {
              return -1
            } else {
              return 0
            }
          })
          this.listBox.value = null;
        }
      }
    }, (error) => {

      notify(`Під час видалення сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
    })
    
  }

  removeExtentFilter(obj) {
    let extentDataIndex = this.filterEdit.extents.findIndex(ef => ef.id);
    if (extentDataIndex >= 0) {
      this.filterEdit.extents.splice(extentDataIndex, 1);
    }
  }


  private createExtentFilter(graphic): FilterExtent {
    let extentFilter = new FilterExtent();
    extentFilter.regionID = this.sharedService.getRegionIDValue();
    //let newID = this.filterEdit.extents.length + 1
    //extentFilter.id = newID.toString();
    extentFilter.filterExtentType = FilterExtentType[graphic.geometry.type];
    extentFilter.extent = graphic.geometry;

    if (this.filterEdit.extents) {
      this.filterEdit.extents.push(extentFilter);
    } else {
      this.filterEdit.extents = [];
      this.filterEdit.extents.push(extentFilter);
    }
    return extentFilter;
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    this.createSubscription.unsubscribe();
    
  }

  private setDomains(valuesDomain: any[], layerDataID: any, fieldName: any) {    
    if (this.datasourceDomains[layerDataID]) {
      let temp = this.datasourceDomains[layerDataID];      
      temp[fieldName] = valuesDomain;
    } else {
      let val = {};      
      val[fieldName] = valuesDomain;
      this.datasourceDomains[layerDataID] = val;
    }
  }

  private async initFilterLayerFieldsAttr(filterLayer: FilterLayer): Promise<FilterLayer> {
    let _url = this.configService._baseUrlRegionServices + `${filterLayer.serviceName}` + `/` + `${filterLayer.layerName}`;
    //_url = _url.replace('MapServer', 'FeatureServer');
    return this.layerServerService.getLayerInfo(_url).then(async result => {
        let layerFields: any[] = result?.fields ?? [];
        let subtypeFieldName: string;

      let fields = filterLayer.layerAttributes.filter(x => x.inMini).map(value => {
        let field = layerFields ? layerFields.find(f => f.name == value.name) : null;
        let fieldName = `${(field ? field.alias : value.name)}`;
        let valuesDomain: { id, name }[] = [];
        let hasDomainValue: boolean = false;
        let subtypeInfo = this.layerServerService.subTypeInfo(result);
        if (subtypeInfo.subtypeFieldName == value.name) {
            hasDomainValue = true;
            subtypeFieldName = subtypeInfo.subtypeFieldName;
            valuesDomain = subtypeInfo.subtypes;
        }
        if (hasDomainValue) {
          this.setDomains(valuesDomain, filterLayer.layerDataID, value.name);
          return {
            dataField: value.name,
            caption: fieldName,
            customizeText: function (fieldInfo) {
              let itemValue = valuesDomain.find(f => f.id == fieldInfo.value);
              return itemValue ? itemValue.name : fieldInfo.value;
            },
            filterOperations: ["anyof"],
            lookup: {
              displayExpr: "name",
              valueExpr: "id",
              dataSource: valuesDomain
            }
          }
        }
        else {
          let field = layerFields ? layerFields.find(f => f.name == value.name) : null;
          let fieldName = `${(field ? field.alias : value.name)}`;
          switch (field.type) {
            case "esriFieldTypeSmallInteger":
            case "esriFieldTypeInteger":
            case "esriFieldTypeSingle":
            case "esriFieldTypeDouble":
              return {
                dataField: value.name,
                caption: fieldName,
                dataType: "number",
                filterOperations: ["anyofunq", "=", "<>", "<", ">", "<=", ">=", "isblank", "isnotblank","between"]
              };
            case "esriFieldTypeString": {
              return {
                dataField: value.name,
                caption: fieldName,
                dataType: "string",
                filterOperations: ["anyofunq","contains", "notcontains", "startswith", "endswith", "=", "<>", "isblank", "isnotblank"],                 
              }
            }
            case "esriFieldTypeDate": {
              return {
                dataField: value.name,
                caption: fieldName,
                dataType: "datetime"
              }
            }
            default:
              return {
                dataField: value.name,
                caption: fieldName,
              };
          }
        }

      })
      filterLayer.fields = fields;
      await this.getUniqueValues(filterLayer);
      return filterLayer;      
    })

  }

  apply() {
    let result = this.validationFilterData.instance.validate();

    if (!result.isValid) {
      return;
    }

    this.filterEdit.isSelected = true;
    if (this.filterEdit.id) {
      this.filtersService.updateFilter(this.filterEdit).subscribe(result => {
        if (this.sharedService.getCurrentFilterValue() && this.sharedService.getCurrentFilterValue().id == this.filterEdit.id) {
          this.filtersService.unselectFilter(this.filterEdit);
          
        }
        this.filterEdit.layers.forEach(fl => {
          if (fl.textFilter) {
            fl.textFilterData = JSON.parse(fl.textFilter);
          }

        })
        this.filtersService.selectFilter(this.filterEdit.id);
        notify(`ФІльтр "${this.filterEdit.name}" застосовано`, "info", 3000);
      })
    } else {
      this.filtersService.addFilter(this.filterEdit).subscribe(result => {
        this.filterEdit.id = result.id;
        if (this.sharedService.getCurrentFilterValue()) {
          this.filtersService.unselectFilter(this.sharedService.getCurrentFilterValue());
        }
        this.filterEdit.layers.forEach(fl => {
          if (fl.textFilter) {
            fl.textFilterData = JSON.parse(fl.textFilter);
          }

        })
        this.filtersService.selectFilter(this.filterEdit.id);
        notify(`ФІльтр "${this.filterEdit.name}" застосовано`, "info", 3000);
      });
    }
  }

  tagBoxChanged(event, condition) {
    condition.setValue(event.value);
  }

  listBoxChanged(event, condition) {
    condition.setValue(event.value);
  }

  onEditorPreparing(e) {
    let event = e;
    let dataField = e.dataField;
    let filterOperation = e.filterOperation;
  }

  onEditorPrepared(e) {
    let event = e;
    let dataField = e.dataField;
    let filterOperation = e.filterOperation;
  }

  async getUniqueValues(filterLayer: FilterLayer) {
    let _url = this.configService._baseUrlRegionServices + `${filterLayer.serviceName}` + `/` + `${filterLayer.layerName}`;
    _url = _url.replace('MapServer', 'FeatureServer');
    let featureLayer: __esri.FeatureLayer = new this.esriService.FeatureLayer();
    featureLayer.url = _url;
    
      for (const field of filterLayer.fields) {
        let params = {
        sqlWhere: await this.layerAttributeHeplerService.filterLayerByAttribute(filterLayer, featureLayer, _url),
        layer: featureLayer,
        field: field.dataField
      }

      await this.esriService.UniqueValues(params).then(result => {

        let resultInfo: [] = result.uniqueValueInfos;
        let arr = resultInfo.map(function (info: any) {
          return {
            id: info.value,
            name: info.value
          }
        })
        

        field.uniqueValues = arr.sort(this.sortArrayByName);
        
        let values = {};
        values[field.dataField] = arr;
        if (field.filterOperations?.find(f => f == 'anyof')) {
          field.lookup.dataSource = field.lookup.dataSource.filter(f => field.uniqueValues.find(u => u.id == f.id));
          this.setDomains(field.lookup.dataSource, filterLayer.layerDataID, field.dataField);
        } else {
          if (this.datasourceTst[filterLayer.layerDataID]) {
            let temp = this.datasourceTst[filterLayer.layerDataID];
            temp[field.dataField] = arr;
          } else {
            this.datasourceTst[filterLayer.layerDataID] = values
          }
        }        
      })
    }  
  }

  sortArrayByName(a, b)  {
    if (a.name > b.name) {
      return 1
    } else if (b.name > a.name) {
      return -1
    } else {
      return 0
    }
  }

  unselectFilter() {
    let filter = this.sharedService.getCurrentFilterValue();
    if (filter) {
      this.filtersService.unselectFilter(filter); 
    }

    this.router.navigate(['./filter']);
    
  }
}

import { Injectable } from "@angular/core";
import { LegendItem } from "../../models/layers/legend.model";

@Injectable()
export class LegendQuerySevice {
  constructor() {

  }

  getSubQuery(legend: LegendItem) {
    let _query = '';
    let filterKOATUU = legend.filterKOATUU ? legend.filterKOATUU : '';
    if (legend.filterGroupType && legend.filterGroupType.length > 0) {
      let allGroupFilters = "";
      legend.filterGroupType.forEach(attr => {
        let groupFilter = "";
        attr.group.forEach(g => {
          if (groupFilter.length > 0) {
            groupFilter += ',';
          }
          if (g.esriFieldType == "esriFieldTypeString") {
            groupFilter += `N'${g.value}'`;
          } else //if (g.esriFieldType == "esriFieldTypeInteger")
          {
            groupFilter += `${g.value}`;
          }

        })

        if (groupFilter.length > 0) {
          if (allGroupFilters.length > 0) {
            allGroupFilters = allGroupFilters + " and "
          }
          allGroupFilters = attr.name + " in ( " + groupFilter + ")";
        }
      })
      if (allGroupFilters.length > 0) {
        if (filterKOATUU.length > 0) {
          filterKOATUU = filterKOATUU + " and ";
        }
        filterKOATUU = filterKOATUU + allGroupFilters;
      }
    }


    if (legend.SubTypeField) {
      let subFields = legend.SubTypeField.split(',');
      let filterSUbType: string = '';
      let defaultValues = legend.defaultValues[0].split(',');
      for (let i = 0; i < subFields.length; i++) {
        if (filterSUbType !== '') {
          filterSUbType += " and "
        }
        if (legend.esriFieldType == "esriFieldTypeString") {
          filterSUbType += subFields[i] + "=" + `N'${defaultValues[i]}'`
        } else //if (legend.esriFieldType == "esriFieldTypeInteger")
        {
          filterSUbType += subFields[i] + "=" + `${defaultValues[i]}`
        }
        if (defaultValues[i] == "") {
          filterSUbType = "(" + filterSUbType + " or " + subFields[i] + " is null )"
        }
      }
      if (subFields.length > 1) {
        filterSUbType = "(" + filterSUbType + ")"
      }
      let subQuery = (filterKOATUU ? filterKOATUU : '') + (filterSUbType ? (filterKOATUU ? ' and ' : '') + filterSUbType : '');
      _query = subQuery;
    } else {
      _query = filterKOATUU;
    }

    if (legend.layerFilterExpression) {
      if (_query?.length > 0) {
        return `((${_query}) and ${legend.layerFilterExpression})`
      } else {
        return legend.layerFilterExpression;
      }

    } else {
      return _query;
    }
  }

}

<dx-popup
  title="Додавання закладки"
  maxWidth="640px"
  width="100%"
  height="100%"
  position="left top"
  [showCloseButton]="false"
  [dragEnabled]="false"  
  [animation]="{ 
    hide: { type: 'slideOut', direction: 'left', duration: 512 }, 
    show: {type: 'slideIn', direction: 'left', duration: 512 }
  }"
  [shading] ="false"
  visible="true">
  <div class="flex-col">

    <dx-validation-group #validationBookmarkData>
      <dx-text-box [(value)]="newName" label="Назва закладки">
        <dx-validator>
          <dxi-validation-rule type="required" message="Назва закладки обов'язкова"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </dx-validation-group>
     
    <dx-drop-down-box *ngIf="showBookmarks" [(value)]="treeBoxValue"
                      [(opened)]="isTreeBoxOpened"
                      valueExpr="id"
                      displayExpr="name"
                      label="Зберегти в закладку"
                      [showClearButton]="true"
                      [items]="bookmarksTree"
                      (onValueChanged)="syncTreeViewSelection($event)"
                      (onOptionChanged)="onTreeBoxOptionChanged($event)">
      <div *dxTemplate="let data of 'content'">
        <dx-tree-view [items]="bookmarksList"
                      itemsExpr="bookmarks"
                      keyExpr="id"
                      selectionMode="single"
                      displayExpr="name"
                      [selectByClick]="true"
                      (onContentReady)="$event.component.selectItem(treeBoxValue)"
                      (onItemSelectionChanged)="treeView_itemSelectionChanged($event)">
        </dx-tree-view>
      </div>
    </dx-drop-down-box>
  </div>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="closeButtonOptions">
  </dxi-toolbar-item>
  <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [options]="saveButtonOptions">
  </dxi-toolbar-item>
</dx-popup>
import { Injectable } from '@angular/core';
import { Constants } from "../shared/data/data.service";
import { SharedService } from "../shared/shared.service";
import { EsriService } from "../esri/js-esri.service";

@Injectable()
export class EditGeodataService {

  constructor(private sharedService: SharedService, private esriService: EsriService) {
    console.log("edit-geodata Service");
  }

  
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'visibleFilter'
})
export class VisibleFilterPipe implements PipeTransform {

  transform(items: any[], visible: boolean): any[] {
    if (!items) {
      return [];
    }
    return items.filter(item => item.visible === visible);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GeometryCalculatorService } from '../../services/geometrycalculator/geometry-calculator.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared/shared.service';
import { Subscription } from 'rxjs';
import { MapService } from '../../services/map/map.service';
import { SquareUnits } from '../../models/draw/square-units.model';

@Component({
  selector: 'app-geometry-calculator',
  //standalone: true,
  //imports: [],
  templateUrl: './geometry-calculator.component.html',
  styleUrl: './geometry-calculator.component.css'
})
export class GeometryCalculatorComponent implements OnInit, OnDestroy {

  totalArea: any = 0;
  totalLength: any = 0;
  amount: any = 0;
  unit: any;
  calculateUnit: SquareUnits = SquareUnits.meter;
  isSelectAreaTool: boolean;
  isSquareMeters: boolean = true;
  private subscriptionCalculate: Subscription;
  private subscriptionIsSelectAreTool: Subscription;
  constructor(
    private geometryCalculatorService: GeometryCalculatorService,
    private router: Router,
    private sharedService: SharedService,
    private mapService: MapService) { }
  
  ngOnInit(): void {
    this.mapService.isEditing = true;
    this.geometryCalculatorService.onInit();
    this.subscriptionCalculate = this.geometryCalculatorService.calculatedArea().subscribe(res => {
      this.totalArea = res.totalArea && res.totalArea > 0 ? res.totalArea?.toFixed(4) : 0;
      this.totalLength = res.totalLength && res.totalLength > 0 ? res.totalLength?.toFixed(3) : 0;
      this.amount = res.amount;
    });
    this.subscriptionIsSelectAreTool = this.geometryCalculatorService.isSelectAreaTool().subscribe(val => {
      this.isSelectAreaTool = val;
    })
  }
  
  onSwitchChange(value: boolean) {
    this.isSquareMeters = value;
    this.calcTotalArea(this.isSquareMeters ? SquareUnits.meter : SquareUnits.hectares);
  }

  selectArea() {
    if (this.isSelectAreaTool) {
      this.geometryCalculatorService.unselectAreaTool();
    } else {
      this.geometryCalculatorService.selectArea();
    }
  }

  selectAll() {
    this.geometryCalculatorService.selectAll();
  }

  unselectAll() {
    this.geometryCalculatorService.unselectAll();
  }
  
  calcTotalArea(unit: SquareUnits) {
    this.calculateUnit = unit;
    this.geometryCalculatorService.calculateUnit = unit;
    this.geometryCalculatorService.calculateTotalArea();
  }

  close() {
    this.sharedService.setIsGeometryCalculator(false);
  }


  ngOnDestroy(): void {
    this.mapService.isEditing = false;
    this.subscriptionCalculate?.unsubscribe();
    this.subscriptionIsSelectAreTool?.unsubscribe();
    this.geometryCalculatorService.onDestroy();
  }
}

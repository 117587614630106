import { Injectable } from '@angular/core';
import { SharedService } from "../shared/shared.service";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ChartInfo, ChartLegendDetail, ChartSettings, ChartType, ChartValueInfo } from '../../models/chart/chart.model';
import { ConfigService } from '../shared/utils/config.service';
import { MapService } from '../map/map.service';
import { ChartEndpointService } from './chart-endpoint.service';
import { EsriService } from '../esri/js-esri.service';
import { info } from 'console';
import { FiltersService } from '../filters/filters.service';


@Injectable()
export class ChartService {

  constructor(private sharedService: SharedService,
    private configService: ConfigService, private mapService: MapService,
    private chartEndpointService: ChartEndpointService, private esriService: EsriService
    ,private filtersService: FiltersService ) {
    

  }

  listChartsByPublicBookmark(id: string): Promise<ChartSettings[]> {
    //let result: ChartSettings[] = []

    return this.chartEndpointService.listChartsByBookmarkID(id).then(result => {
      return result;
    })
    //return new Promise<ChartSettings[]>((resolve) => {

      //let values = [{ name: "test 1", value: 10 }, { name: "test 2", value: 20 }, { name: "test 3", value: 27 }, { name: "test 4", value: 42 }, { name: "test 5", value: 18 }];
      //result.name = "chart";
      //result.values = values;
      //result.type = ChartType.pie;

    //  resolve(result);
    //})


    //return result;
  }

  getChartByID(id): Promise<ChartInfo> {    

    return this.chartEndpointService.getChartLegendsInfo(id).toPromise().then( chartSettings => {

      let arrayInit: Promise<ChartValueInfo>[] = [];
      chartSettings.legendsInfo.forEach(f => {
        f.details.forEach(detail => {

        })
        //let url = this.configService._baseUrlRegionServices + f.url
        //let initPromise: Promise<ChartValueInfo> = this.getRecordCountByUrl(url, f.expression, f.name);
        //arrayInit.push(initPromise);
      });

      return Promise.all(arrayInit).then(val => {
        return val;
      }).then(valueInfo => {
        return new ChartInfo(chartSettings.name, chartSettings.chartType, valueInfo);
      })
      
    })
    
  }

  getChartByLegend(chartSettings: ChartSettings): Promise<ChartInfo> {

    //return this.chartEndpointService.getChartLegendsInfo(id).toPromise().then(chartSettings => {

      let arrayInit: Promise<ChartValueInfo>[] = [];
    chartSettings.legendsInfo.forEach(f => {
      f.details.forEach(detail => {
        let url = this.configService._baseUrlRegionServices + detail.url;
        let filter = detail.textFilter ? JSON.parse(detail.textFilter) : null;

        let initPromise: Promise<ChartValueInfo> = this.getRecordCountByUrl(url, detail, f);
        arrayInit.push(initPromise);
      })
        
        
        
      });

      return Promise.all(arrayInit).then(val => {
        return val;
      }).then(valueInfo => {
        let groupInfo = [];
        valueInfo.reduce(function (res, value) {
          if (!res[value.id]) {
            res[value.id] = { id: value.id, name: value.name, value: 0 };
            groupInfo.push(res[value.id])
          }
          res[value.id].value += value.value;
          return res;
        }, {})

        return new ChartInfo(chartSettings.name, chartSettings.chartType, groupInfo);
      })

    //})

  }

  async getRecordCountByUrl(url: string, chartLegendDetail: ChartLegendDetail, valueInfo?): Promise<ChartValueInfo> {

    let queryTask: __esri.query = this.esriService.QueryTask;
    let _url = url + "?token=" + this.configService.getGisToken();   
    if (chartLegendDetail.textFilter) {
      let _url = chartLegendDetail.serviceName + "/" + chartLegendDetail.layerName;
      let layerUrl = {
        layerID: chartLegendDetail.id,
        url: _url
      }

      let layerFilterInfo = await this.filtersService.getLayerInfo(layerUrl);
      let layerFilter = {
        subtypeFiledNameInfo : layerFilterInfo.subtypeFiledName,
        subtypesInfo : layerFilterInfo.subtypes,
        fields : layerFilterInfo.fields
      };
      

      let _filter = chartLegendDetail.textFilter ? JSON.parse(chartLegendDetail.textFilter) : null;
      if (_filter) {
        let expression = this.parseExpression(_filter, layerFilter);
        chartLegendDetail.expression = expression;
      }
    }
    let queryWhere = (chartLegendDetail.expression ? chartLegendDetail.expression : "1=1");
    var query: __esri.Query = new this.esriService.Query();
    query.where = queryWhere;

    
    return queryTask.executeForCount(_url, query).then(count => {
      return new ChartValueInfo(valueInfo.id, valueInfo.name, count);
    })
  }
  //get user regions
  //getUserRegions(userId?: string) {

  //  return this.locationsEndpoint.getUserRegionsEndpoint(userId)
  //    .pipe(map((response: any) =>
  //      <UserRegions[]>response));    
  //}

  private parseExpression(expr, filterLayer: any) {
    let result = '';

    if (expr && Array.isArray(expr)) {

      if (Array.isArray(expr[0])) {
        for (let i = 0; i < expr.length; i++) {
          result += this.parseExpression(expr[i], filterLayer);
        }
        result = `(${result})`;
      } else if (Array.isArray(expr) && expr.length > 1) {
        let attr = expr[0];
        let condition = expr[1];
        let value = expr[2];
        let field = filterLayer.fields.find(f => f.dataField == attr);
        let fieldType = field && field.dataType ? field.dataType : null;

        result += attr + this.parseCondition(condition, value, filterLayer, fieldType);
        if (!value && fieldType == 'string') {
          let exResupt = attr + this.parseCondition(condition, '', filterLayer, fieldType);
          result = '(' + result + ' or ' + exResupt + ')';
        }

      }
    } else {
      result += ` ${expr} `;
    }
    return result;
  }

  private parseCondition(condition, value, filterLayer: any, fieldType?) {
    let result = "";
    //  "=", "<>", ">", ">=", "<", "<=", "startswith", "endswith", "contains", "notcontains"
    // between 
    switch (condition) {
      case "contains":
        result = ` like N'%${value}%'`;
        break;
      case "startswith":
        result = ` like N'${value}%'`;
        break;
      case "endswith":
        result = ` like N'%${value}'`;
        break;
      case "notcontains":
        result = ` not like N'%${value}%'`;
        break;
      case "between":
        result = ` between ${value[0]} and ${value[1]} `;
        break;
      case "=":
        if (value == null) {
          result = ' is null';
        }
        else if (fieldType && fieldType == 'number') {
          result = ` = ${value}`;
        }
        else {
          result = ` = N'${value}'`;
        }

        break;
      case "<>":
        if (value == null) {
          result = ' is not null';
        }
        else if (fieldType && fieldType == 'number') {
          result = ` <> ${value}`;
        }
        else {
          result = ` <> N'${value}'`;
        }
        break;
      case ">":
        result = ` > ${value}`;
        break;
      case ">=":
        result = ` >= ${value}`;
        break;
      case "<":
        result = ` < ${value}`;
        break;
      case "<=":
        result = ` <= ${value}`;
        break;
      case "anyof":

        let ids = '';
        let subtypes = filterLayer.subtypesInfo;
        let subTypeIDField = filterLayer.subtypeFiledNameInfo;
        value.forEach(x => {
          let _subtype = subtypes.find(s => s.name == x);
          if (_subtype) {

            ids += _subtype.id + ',';
            if (filterLayer.subtypesFilterSelected) {

            } else {
              filterLayer.subtypesFilterSelected = [];
            }
            filterLayer.subtypesFilterSelected.push({ id: _subtype.id, name: _subtype.name })
          }
        })
        ids = ids.slice(0, ids.length - 1);
        result = ` in (${ids})`;
        break;
      case "blank":
        result = " is null";
        break;
      default:
        result = '';
        break;

    }

    return result;
  }

}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FiltersService } from "../../services/filters/filters.service";
import { MapService } from "../../services/map/map.service";
import { Filter } from '../../models/filters/filter.model';
import { SharedService } from '../../services/shared/shared.service';
import { UserService } from '../../services/user/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DxSelectBoxModule, DxListModule } from 'devextreme-angular';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.css']
})
export class FiltersComponent implements OnInit, OnDestroy {

  constructor(private filtersService: FiltersService, private mapService: MapService,
    private sharedService: SharedService, private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }
  filterList: Filter[];
  subscription: Subscription;

  ngOnInit() {
    this.subscription = this.sharedService.getRegionID().subscribe(u => {
      if (this.sharedService.getCurrentFilterValue() && this.sharedService.ShowFilterSettings) { // this.sharedService.getSelectedBookmarkValue() &&
        this.router.navigate(['./filtersettings', this.sharedService.getCurrentFilterValue().id]);
      }
      if (u) {
        this.filtersService.getAllFilters(u, this.userService.User.id).then(result => {
          result.sort(this.sortFilters);
          this.filterList = result;
          let currentFilter = this.sharedService.getCurrentFilterValue();
          if (currentFilter) {
            let selectedFilter = this.filterList.find(f => f.id == currentFilter.id);
            if (selectedFilter) {
              selectedFilter.isSelected = true;
            }
          }
        })
      }      
    })
    
  }

  editSettings(obj) {
    this.router.navigate(['./filtersettings', obj.id]);
  }

  showChart(obj) {

  }

  removeFilter(obj) {
    var confirmation = confirm("Ви впевнені, що хочете видалити фільтр?", "Видалити фільтр");
    confirmation.then(dialogResult => {
      if (dialogResult) {
        this.filtersService.removeFilter(obj.id).subscribe(result => {
          if (this.sharedService.getCurrentFilterValue() && this.sharedService.getCurrentFilterValue().id == obj.id) {
            this.filtersService.unselectFilter(obj);
          }
          this.filtersService.getAllFilters(this.sharedService.getRegionIDValue(), this.userService.User.id).then(result => {
            
            this.filterList = result.sort(this.sortFilters);// result;
          })
        });
      }
    }, (error) => {

      notify(`Під час видалення сталася помилка.\r\n Помилка: "${error._body}"`, "error", 3000);
    })
    
  }

  getCount() {
    //this.totalCount= this.mapService.getRecordCount();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
    
  }

  switchValueChanged(event, obj) {
    let ev = event;
    let filter: Filter = obj;

    if (ev.value) {
      this.filterList.filter(f => f.id != filter.id && f.isSelected).forEach(x => x.isSelected = false);
      filter.isSelected = true;

      this.filtersService.selectFilter(obj.id);
      if (filter.userID) {
        this.router.navigate(['./filtersettings', obj.id]);
      }

    } else {
      filter.isSelected = false;
      this.filtersService.unselectFilter(obj);
    }
    
  }

  sortFilters(a, b)  {
    if (a.userID && !!!b.userID) {
      return 1;
    } else if (!!!a.userID && b.userID) {
      return -1;
    }
    return 0;
  }
}

<div class="document-view">
  <div class="panel-header">
    <span>{{documentName}}</span>
    <a (click)="goBack()" class="btn-icon">
      <i class="icon-close"></i>
    </a>
    <a (click)="download()" class="btn-icon" title="Завантажити">
      <i class="icon-download"></i>
    </a>
  </div>
  <div class="zoom-content" *ngIf="imageUrl">
   
    <img [src]="imageUrl" #imgView />
 
    <!--pinch-zoom style="width: 100%; height: 100%;">

    <img [src]="imageUrl" #imgView/>

  </!--pinch-zoom-->
  </div>
  <div *ngIf="isLoading" class="loader-rotation"></div>
</div>

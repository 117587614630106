<div class="w-profile">
  <div *ngIf="((isLoggedIn | async)===false)" >
    <div class="ava" *ngIf="!isShow" (click)="ShowPopup()" title="Вхід">
       <i class="icon-logout"></i>
    </div>
    <div class="ava" *ngIf="isShow" (click)="ClosePopup()">
      <i class="icon-close"></i>
    </div>
    <form *ngIf="isShow" #f="ngForm" novalidate (ngSubmit)="login(f)">        
      <span class="error" *ngIf="errors">{{errors}}</span>
      <dx-text-box mode="email" [(value)]="credentials.email" [hoverStateEnabled]="false" label="Логін">
        <dx-validator>
          <dxi-validation-rule type="required" message="Електронна пошта обов'язкова"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <dx-text-box mode="password" [(value)]="credentials.password" [hoverStateEnabled]="false" label="Пароль">
        <dx-validator>
          <dxi-validation-rule type="required" message="Пароль обов'язковий"></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <dx-button id="button"
                  text="Вхід"
                  type="default"
                  width="100%"
                  hidden
                  [useSubmitBehavior]="true"
                  [disabled]="f.invalid || isRequesting">
      </dx-button>
      <!--<dx-validation-summary id="summary">  </dx-validation-summary>-->
      <!--app-spinner-- [isRunning]="isRequesting"></!--app-spinner-->
    </form>
  </div>
  <div *ngIf="(isLoggedIn | async)">    
    <div class="ava" *ngIf="!isShow" (click)="ShowPopup()">
      {{userShortName}}
    </div>
    <div class="ava" *ngIf="isShow" (click)="ClosePopup()">
      {{userShortName}}
    </div>
    <ul *ngIf="isShow">
      <li>
        <a href="#" (click)="logout()">
          Вийти
        </a>
      </li>
    </ul>
  </div>
</div>
